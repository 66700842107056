import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../utils/AxiosApi";
import { moduleName, URL } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { checkRolePermissions } from "../../../utils/Helper";

function PermissionsModal(props) {
  const [editMode, setEditMode] = useState(false);
  const [loader, setLoader] = useState(false);

  const [permissionsList, setPermissionsList] = useState([]);

  const [roleId, setRoleId] = useState("");
  const [travelAgencyId, setTravelAgencyId] = useState("");

  const handleCheck = (e, actionIdx, index) => {
    let newPermissionsList = [...permissionsList];

    newPermissionsList[index].action[actionIdx].hasPermission =
      e.target.checked;

    if (
      newPermissionsList[index].moduleName === "Schedule Setting" &&
      newPermissionsList[index].action[actionIdx].hasPermission
    ) {
      newPermissionsList.forEach((pl) => {
        if (pl.moduleName === "Route") {
          pl.action.forEach((a) => {
            if (a.name === "view") {
              a.hasPermission = true;
              a.isDisabled = true;
            }
          });
        }
        if (pl.moduleName === "Bus") {
          pl.action.forEach((a) => {
            if (a.name === "view") {
              a.hasPermission = true;
              a.isDisabled = true;
            }
          });
        }
        if (pl.moduleName === "Bus Route") {
          pl.action.forEach((a) => {
            if (a.name === "view") {
              a.hasPermission = true;
              a.isDisabled = true;
            }
          });
        }
      });
    } else {
      newPermissionsList.forEach((pl) => {
        if (pl.moduleName === "Route") {
          pl.action.forEach((a) => {
            if (a.name === "view") {
              a.isDisabled = false;
            }
          });
        }
        if (pl.moduleName === "Bus") {
          pl.action.forEach((a) => {
            if (a.name === "view") {
              a.isDisabled = false;
            }
          });
        }
        if (pl.moduleName === "Bus Route") {
          pl.action.forEach((a) => {
            if (a.name === "view") {
              a.isDisabled = false;
            }
          });
        }
      });
    }

    setPermissionsList(newPermissionsList);
  };

  const handleEditBtnClick = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  useEffect(() => {
    let newPermissions = props.permissions;

    newPermissions.forEach((permission) => {
      if (
        permission.moduleName === "Schedule Setting" &&
        permission.action[0].hasPermission
      ) {
        newPermissions.forEach((pl) => {
          if (pl.moduleName === "Route") {
            pl.action.forEach((a) => {
              if (a.name === "view") {
                a.hasPermission = true;
                a.isDisabled = true;
              }
            });
          }
          if (pl.moduleName === "Bus") {
            pl.action.forEach((a) => {
              if (a.name === "view") {
                a.hasPermission = true;
                a.isDisabled = true;
              }
            });
          }
          if (pl.moduleName === "Bus Route") {
            pl.action.forEach((a) => {
              if (a.name === "view") {
                a.hasPermission = true;
                a.isDisabled = true;
              }
            });
          }
        });
      }
    });

    setPermissionsList(newPermissions);
    setRoleId(props.roleId);
    setTravelAgencyId(props.travelAgencyId);
  }, [props]);

  const handleUpdate = () => {
    let newPermissionsList = [...permissionsList];
    let actionData = [];

    newPermissionsList.forEach((actionsList) => {
      actionData = actionData.concat(actionsList.action);
    });

    let updatePermissionsData = [];
    actionData.map((item) => {
      if (item.hasPermission === true) {
        updatePermissionsData.push(item.id);
      }
      return updatePermissionsData;
    });

    let params = {};

    if (travelAgencyId !== "") {
      params = {
        id: travelAgencyId,
        permission_id: updatePermissionsData,
      };
    } else {
      params = {
        role_id: roleId,
        permission_id: updatePermissionsData,
      };
    }
    setLoader(true);
    axiosPost(
      travelAgencyId !== ""
        ? URL.travelAgencyPermission
        : URL.updatePermissions,
      params,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
          setLoader(false);
          setEditMode(false);
        }
      },
      (err) => {
        displayErrorAlert(err);
        setLoader(false);
      }
    );
  };

  const handleCheckAll = (e, idx) => {
    let newPermissionsList = [...permissionsList];
    newPermissionsList[idx].action = newPermissionsList[idx].action.map((p) => {
      p.hasPermission = e.target.checked;
      return p;
    });
    setPermissionsList(newPermissionsList);
  };

  return (
    <div className="permissions-modal">
      {loader ? (
        <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
      ) : null}
      <div className="permisiion-edit-btn">
        {editMode ? (
          <div className="permission-edit d-flex justify-content-end">
            <button className="btn btn-success m-2" onClick={handleUpdate}>
              Update
            </button>
            <button className="btn btn-danger m-2" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        ) : checkRolePermissions("update", moduleName.rolePermission) ? (
          <button className="btn btn-primary" onClick={handleEditBtnClick}>
            Edit permissions
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="container-fluid">
        <div className="row permission-box">
          <div className="col">
            {permissionsList && permissionsList.length > 0 ? (
              permissionsList.map((data, index) => (
                <>
                  <div className="permissions-title d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      {data.moduleName}
                    </div>
                    <input
                      value="all"
                      type="checkbox"
                      onChange={(e) => handleCheckAll(e, index)}
                      disabled={!editMode}
                    />
                  </div>
                  <div
                    className="col d-flex justify-content-around m-3"
                    key={index}
                    id={`index${index}`}
                  >
                    {data?.action.map((action, actionIdx) => (
                      <div>
                        <input
                          value={action.name}
                          type="checkbox"
                          checked={action.hasPermission}
                          onChange={(e) =>
                            handleCheck(e, actionIdx, index, action.id)
                          }
                          disabled={
                            !editMode || (editMode && action.isDisabled)
                          }
                        />
                        <span className="m-2">{action.name}</span>
                      </div>
                    ))}
                  </div>
                </>
              ))
            ) : (
              <div className="text-center p-3">
                {props.permissionLoader ? (
                  <>
                    <Spinner></Spinner>
                    <br />
                    Loading Permissions...
                  </>
                ) : (
                  "No permissions found"
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermissionsModal;
