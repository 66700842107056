import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../utils/Constants";

import CustomModal from "../../../components/WebsiteComponents/CustomModal";

import swal from "sweetalert";
import TicketReceipt from "./TicketReceipt";
import Pagination from "react-js-pagination";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";

class MyTickets extends Component {
  state = {
    myTickets: [],
    spinner: false,
    receiptModal: false,
    receiptPdf: null,
    media: null,
  };

  componentDidMount() {
    this.getMyTickets();
  }

  toggleModal = () => {
    this.setState({
      receiptModal: !this.state.receiptModal,
    });
  };

  getMyTickets = (pageNumber = 1) => {
    this.setState({ spinner: true, myTickets: [] }, function () {
      axiosGet(
        URL.getMyTickets + "?" + "page=" + pageNumber,
        (response) => {
          if (response.status === 200) {
            this.setState({
              myTickets: response.data.data.items,
              spinner: false,
            });
          }
        },
        (err) => {
          this.setState({
            spinner: false,
          });
        }
      );
    });
  };

  handleClickTicket = (param) => {
    if (param.length > 0) {
      // var filePath = fileUrl + "/" + param[0].id + "/" + param[0].file_name;
      this.setState({ receiptPdf: param[0], media: param[0] }, () => {
        this.toggleModal();
      });
    } else {
      swal("Error", "Payment receipt is Unavailable", "error");
    }
  };

  handleCancelTicket = (data) => {
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosGet(
          URL.cancelTicket + "/" + data.id,
          (response) => {
            if (response.status === 200) {
              swal("Success", "Ticket cancellation successful");
              this.getMyTickets();
              this.setState({
                spinner: false,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  render() {
    const { current_page, per_page, total } = this.state.myTickets;

    return (
      <>
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="mb-4">
          <div className="my-tickets-title-box text-center mb-5">
            <div className="banner-text">
              <h1>My Tickets</h1>
            </div>
          </div>
          <div className="container">
            <>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Bus Info</th>
                      <th>Boarding Points</th>

                      <th>Booked Seats</th>
                      <th>Total Fare</th>
                      <th>Customer Detail</th>
                      <th>Invoice Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.myTickets &&
                    this.state.myTickets?.length > 0 ? (
                      this.state.myTickets?.map((ticket, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">{idx + 1}</td>
                            <td>
                              <strong>{ticket.bus.name}</strong>
                              <br></br>
                              <strong>No. Plate: </strong>
                              {ticket.bus.number_plate}
                              <br />
                              <strong>Route: </strong>{" "}
                              {ticket.origin[0].country_code} -{" "}
                              {ticket.destination[0].country_code}
                              <br />
                              {ticket.is_manual && (
                                <span className="badge rounded-pill bg-info text-dark text-white">
                                  Manual Booking
                                </span>
                              )}
                            </td>
                            <td>
                              <strong> Pickup point:</strong>
                              <br />
                              {ticket?.pick_up_point?.name != null
                                ? ticket?.pick_up_point?.name
                                : "-"}
                              <br />
                              <strong>Dropping point:</strong>
                              <br />
                              {ticket?.dropping_point?.name != null
                                ? ticket?.dropping_point?.name
                                : "-"}
                            </td>
                            <td>
                              {/* {ticket.bus_seat_booking.map((datum, idx) => {
                                return (
                                  <div key={idx}>
                                    {datum.seatName} ({datum.seat_booked_by})
                                 
                                  </div>
                                );
                              })} */}
                              {ticket.bus_seat_booking.map((datum, idx) => {
                                return (
                                  <div key={idx}>
                                    {datum.seatName}

                                    {(datum.seat_booked_by || datum.remark) &&
                                      (datum.seat_booked_by || datum.remark)}
                                    {/* {ed.bus_seat_booking.length - 1 === idx
                                ? ""
                                : ", "} */}
                                  </div>
                                );
                              })}
                              {ticket.remark && (
                                <div className="mt-2 border p-2">
                                  <b>Remarks</b>
                                  <p>{ticket.remark}</p>
                                </div>
                              )}
                            </td>
                            <td>{ticket.fare}</td>
                            <td>
                              <strong>Name: </strong>
                              {ticket.bus_booking_customer?.full_name}
                              <br />
                              <strong>Email: </strong>
                              {ticket.bus_booking_customer?.email}
                              <br />
                              <strong>Contact: </strong>
                              {ticket.bus_booking_customer?.phone_number}
                              <br />
                            </td>
                            <td>{ticket.invoice_number}</td>
                            <td>
                              <button
                                className="btn btn-info m-2"
                                onClick={() =>
                                  this.handleClickTicket(ticket.media)
                                }
                              >
                                View Receipt
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => this.handleCancelTicket(ticket)}
                                disabled={ticket.cancellation_status}
                              >
                                {ticket.cancellation_status
                                  ? "Canceled"
                                  : "Cancel Ticket"}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={9}>
                          {this.state.spinner ? (
                            <Spinner></Spinner>
                          ) : (
                            "You have not booked any tickets yet"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.myTickets.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) => this.getMyTickets(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </>
          </div>

          <CustomModal
            modal={this.state.receiptModal}
            modalHeader={"Receipt"}
            modalBody={<TicketReceipt receiptPdf={this.state.receiptPdf} />}
            size="lg"
            toggleModal={this.toggleModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default MyTickets;
