import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import { axiosGet } from "../../../utils/AxiosApi";
import { fileUrl, moduleName, URL } from "../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import {
  checkRolePermissions,
  getTravelAgencyData,
} from "../../../utils/Helper";
import CreateBusAgency from "./CreateBusAgency";

class BusAgency extends Component {
  state = {
    travelAgencyData: {},
    busAgencies: [],
    dataSpinner: false,
    deletePermission: false,
    createModal: false,
    edit: false,
    editPermission: false,
    selectedData: {},
  };

  componentDidMount() {
    this.getTravelAgencyDataForInsert();
    this.getBusAgencies();
    this.permissions();
  }

  getBusAgencies = () => {
    this.setState({ dataSpinner: true }, function () {
      axiosGet(
        URL.busAgencies,
        (response) => {
          if (response.status === 200) {
            this.setState({
              busAgencies: response.data.data.items,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  getTravelAgencyDataForInsert = () => {
    let travelAgencyData = getTravelAgencyData();
    this.setState({ travelAgencyData: travelAgencyData });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  handleDeleteBtnClick = (e, id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosGet(URL.deleteBusAgencies + "/" + params.id, (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.setState({
            loader: false,
          });
          this.getBusAgencies();
        }
      });
    });
  };

  handleEdit = (item) => {
    this.setState({ selectedData: item, edit: true, createModal: true });
  };

  permissions = () => {
    this.setState({
      editPermission: checkRolePermissions("update", moduleName.busAgency),
      deletePermission: checkRolePermissions("delete", moduleName.busAgency),
    });
  };

  render() {
    return (
      <>
        <div className="dashboardHeader">
          <strong>BUS AGENCIES</strong>
        </div>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.busAgency) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Bus Agency
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Logo</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Contact Details</th>
                      <th>Email</th>
                      {this.state.editPermission ||
                      this.state.deletePermission ? (
                        <th>Options</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.busAgencies.length > 0 ? (
                      this.state.busAgencies.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              {item.media && item.media.length > 0 ? (
                                <img
                                  src={
                                    fileUrl +
                                    "/" +
                                    item.media[0].id +
                                    "/" +
                                    item.media[0].file_name
                                  }
                                  className="logoDisplay"
                                />
                              ) : null}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.address}</td>
                            <td>{item.contact_details}</td>
                            <td>{item.email}</td>
                            {this.state.editPermission ||
                            this.state.deletePermission ? (
                              <td>
                                {this.state.editPermission ? (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.handleEdit(item)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}
                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger ms-3"
                                    onClick={(e) =>
                                      this.handleDeleteBtnClick(e, item.id)
                                    }
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            this.state.editPermission ||
                            this.state.deletePermission
                              ? 7
                              : 6
                          }
                          className="text-center p-3"
                        >
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Bus Agency found. Create one first"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateBusAgency
                getBusAgencies={this.getBusAgencies}
                handleCreateModal={this.handleCreateModal}
                selectedData={this.state.selectedData}
                edit={this.state.edit}
                travelAgencyId={
                  this.state.travelAgencyData
                    ? this.state.travelAgencyData.id
                    : null
                }
              />
            }
            modalHeader={this.state.edit ? "Edit Bus Agency" : "Add Bus Agency"}
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default BusAgency;
