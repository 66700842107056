import React, { useEffect, useState } from "react";
import "./faq.scss";

import faqImg from "../../../assets/images/faqs.png";
import { axiosGet, axiosGetWeb } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { Spinner } from "reactstrap";

const FAQs = () => {
  const [faqs, setFaqs] = useState([]);
  // const [activeAnswer, setActiveAnswer] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    axiosGetWeb(URL.getGuestFAQs, (response) => {
      if (response.status === 200) {
        setLoader(false);
        setFaqs(response.data.data.items);
      }
    });
  }, []);

  return (
    <>
      <div className="my-tickets-title-box text-center">
        <div className="banner-text">
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>
      <div className="faq-container">
        {/* <div className="faq-head text-center"><h1>FAQ's</h1></div> */}
        <div className="row">
          {/* <div className="col-md-4">
            <div className="faq-img mt-5">
              <img src={faqImg} alt="faq" />
            </div>
          </div> */}
          <div className="col">
            {loader ? (
              <div className="text-center">
                {" "}
                <Spinner />
              </div>
            ) : (
              <div className="faq-contents">
                {faqs && faqs?.length > 0 ? (
                  faqs?.map((data, idx) => (
                    <div className="faqs" key={idx}>
                      <div
                        className="faq-questions"
                        data-bs-toggle="collapse"
                        data-bs-target={`#col${idx}`}
                        aria-expanded="false"
                        aria-controls="collapse"
                        data-bs-dismiss="collapse"
                        // onClick={() => handleQuestionClick(data, idx)}
                      >
                        <h4>
                          {/* Q.) {data.questions} */}
                          {idx + 1}. {data.questions}
                        </h4>
                      </div>
                      {/* <hr /> */}
                      <div
                        className="faq-answers collapse"
                        id={`col${idx}`}
                        data-bs-parent="#collapse"
                      >
                        <p>{data.answer}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <p>No FAQ's available</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
