import React, { Component } from "react";
import Sidebar from "../../components/DashboardComponents/Sidebar";
import SidebarRoutes from "../../Routes/SidebarRoutes";
import {
  faBook,
  faBus,
  faBusAlt,
  faClipboardList,
  faFileArchive,
  faFileContract,
  faFolderOpen,
  faGem,
  faGlobeAsia,
  faIcons,
  faMap,
  faNetworkWired,
  faPaperPlane,
  faPlusCircle,
  faRoute,
  faSignOutAlt,
  faUser,
  faUserAltSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkRolePermissions } from "../../utils/Helper";
import { moduleName } from "../../utils/Constants";

class DashboardTemplate extends Component {
  state = {
    menus: [
      {
        icon: <FontAwesomeIcon icon={faPaperPlane} />,
        menuName: "Go To Website",
        path: "/",
        display: true,
      },
      {
        icon: <FontAwesomeIcon icon={faMap} />,
        menuName: "Amenities",
        path: "/admin/dashboard/amenities",
        display: checkRolePermissions("view", moduleName.amenities),
      },
      {
        icon: <FontAwesomeIcon icon={faPlusCircle} />,
        menuName: "Bus Bookings",
        path: "/admin/dashboard/dashboard-bookings",
        display: checkRolePermissions("create", moduleName.dasboardBusBooking),
      },
      {
        icon: <FontAwesomeIcon icon={faGem} />,
        menuName: "Dashboard",
        path: "/admin/dashboard",
        display: true,
      },
      {
        icon: <FontAwesomeIcon icon={faNetworkWired} />,
        menuName: "Nework Agency",
        path: "/admin/dashboard/networkAgencies",
        display: checkRolePermissions("view", moduleName.networkAgency),
      },
      {
        icon: <FontAwesomeIcon icon={faRoute} />,
        menuName: "Route Configuration",
        path: "",
        display: checkRolePermissions("create", moduleName.route),
        subMenus: [
          {
            icon: null,
            menuName: "Routes",
            path: "/admin/dashboard/routes",
            display: checkRolePermissions("view", moduleName.route),
          },
        ],
      },
      {
        icon: <FontAwesomeIcon icon={faGlobeAsia} />,
        menuName: "Travel Agency Configuration",
        path: "",
        display:
          checkRolePermissions("view", moduleName.travelAgency) ||
          checkRolePermissions("view", moduleName.travelAgencyRoute),
        subMenus: [
          {
            icon: null,
            menuName: "Travel Agencies",
            path: "/admin/dashboard/travelAgency",
            display: checkRolePermissions("view", moduleName.travelAgency),
          },
          {
            icon: null,
            menuName: "Travel Agency Routes",
            path: "/admin/dashboard/travelAgencyRoutes",
            display: checkRolePermissions("view", moduleName.travelAgencyRoute),
            // display: true,
          },
        ],
      },
      {
        icon: <FontAwesomeIcon icon={faGlobeAsia} />,
        menuName: "Operator",
        path: "/admin/dashboard/travelChildAgency",
        display: checkRolePermissions(
          "viewChild",
          moduleName.travelChildAgency
        ),
      },
      {
        icon: <FontAwesomeIcon icon={faUser} />,
        menuName: "Customers",
        path: "/admin/dashboard/customers",
        display: checkRolePermissions("view", moduleName.customers),
      },
      {
        icon: <FontAwesomeIcon icon={faUser} />,
        menuName: "Canceled Tickets",
        path: "/admin/dashboard/canceledTickets",
        display: checkRolePermissions("view", moduleName.ticket),
      },
      {
        icon: <FontAwesomeIcon icon={faBusAlt} />,
        menuName: "Bus Agencies",
        path: "/admin/dashboard/busAgency",
        display: checkRolePermissions("view", moduleName.busAgency),
      },
      {
        icon: <FontAwesomeIcon icon={faBus} />,
        menuName: "Bus Configuration",
        path: "",
        display:
          checkRolePermissions("view", moduleName.busTypeAgency) ||
          checkRolePermissions("view", moduleName.bus) ||
          checkRolePermissions("view", moduleName.busRoute),
        subMenus: [
          {
            menuName: "Bus Types",
            icon: null,
            path: "/admin/dashboard/bus-types",
            display: checkRolePermissions("view", moduleName.busTypeAgency),
          },
          {
            menuName: "Bus",
            icon: null,
            path: "/admin/dashboard/buses",
            display: checkRolePermissions("view", moduleName.bus),
          },
          {
            menuName: "Bus Routes",
            icon: null,
            path: "/admin/dashboard/bus-routes",
            display: checkRolePermissions("view", moduleName.busRoute),
          },
        ],
      },
      {
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        menuName: "Bus Scheduling",
        path: "",
        display: checkRolePermissions("view", moduleName.scheduleSetting),
        subMenus: [
          {
            menuName: "Schedule Settings",
            icon: null,
            path: "/admin/dashboard/bus-schedule-settings",
            display: checkRolePermissions("view", moduleName.scheduleSetting),
          },
          {
            menuName: "Daily Schedule",
            icon: null,
            path: "/admin/dashboard/daily-schedule",
            display: checkRolePermissions("view", moduleName.dailySchedule),
          },
        ],
      },
      {
        icon: <FontAwesomeIcon icon={faFolderOpen} />,
        menuName: "CMS",
        path: "",
        display:
          checkRolePermissions("view", moduleName.faq) ||
          checkRolePermissions("view", moduleName.slider) ||
          checkRolePermissions("view", moduleName.termsAndConditions) ||
          checkRolePermissions("view", moduleName.blog) ||
          checkRolePermissions("view", moduleName.paymentGateway) ||
          checkRolePermissions("update", moduleName.mapsData) ||
          checkRolePermissions("create", moduleName.socialIcons) ||
          checkRolePermissions("view", moduleName.paymentGatewayConfig),
        subMenus: [
          {
            menuName: "Slider",
            icon: null,
            path: "/admin/dashboard/cms-slider",
            display: checkRolePermissions("view", moduleName.slider),
          },
          {
            menuName: "Advertisement",
            icon: null,
            path: "/admin/dashboard/advertisements",
            display: checkRolePermissions("create", moduleName.advertisement),
          },
          {
            menuName: "Maps",
            icon: null,
            path: "/admin/dashboard/map-data",
            display: checkRolePermissions("update", moduleName.mapsData),
          },
          {
            icon: null,
            menuName: "Social Icons",
            path: "/admin/dashboard/contact-data",
            display: checkRolePermissions("create", moduleName.socialIcons),
          },
          {
            menuName: "FAQ",
            icon: null,
            path: "/admin/dashboard/cms-faq",
            display: checkRolePermissions("view", moduleName.faq),
          },
          {
            menuName: "Blog",
            icon: null,
            path: "/admin/dashboard/cms-blog",
            display: checkRolePermissions("view", moduleName.blog),
          },
          {
            menuName: "Payment Gateway",
            icon: null,
            path: "/admin/dashboard/cms-payment-gateway",
            display: checkRolePermissions("view", moduleName.paymentGateway),
          },
          {
            menuName: "Payment Gateway Configuration",
            icon: null,
            path: "/admin/dashboard/cms-payment-gateway-config",
            display: checkRolePermissions(
              "view",
              moduleName.paymentGatewayConfig
            ),
          },
          {
            menuName: "Terms & Conditions",
            icon: null,
            path: "/admin/dashboard/cms-tandc",
            display: checkRolePermissions(
              "view",
              moduleName.termsAndConditions
            ),
          },
        ],
      },
      {
        menuName: "About Us Section",
        icon: <FontAwesomeIcon icon={faFileContract} />,
        path: "",
        display: checkRolePermissions("view", moduleName.aboutUs),
        subMenus: [
          {
            menuName: "About Banner",
            icon: null,
            path: "/admin/dashboard/about-banner",
            display: 1,
          },
          {
            menuName: "About Us Contents",
            icon: null,
            path: "/admin/dashboard/about-company",
            display: 1,
          },
          // {
          //   menuName: "Services",
          //   icon: null,
          //   path: "/admin/dashboard/services",
          //   display: 1,
          // },
        ],
      },

      {
        icon: <FontAwesomeIcon icon={faFileContract} />,
        menuName: "Documents",
        path: "/admin/dashboard/documents",
        display: checkRolePermissions("view", moduleName.documents),
      },
      {
        icon: <FontAwesomeIcon icon={faUserAltSlash} />,
        menuName: "Roles & Permissions",
        path: "/admin/dashboard/rolesandpermissions",
        display: checkRolePermissions("view", moduleName.rolePermission),
      },

      {
        icon: <FontAwesomeIcon icon={faBook} />,
        menuName: "Booking Details",
        path: "/admin/dashboard/booking-details",
        display: checkRolePermissions("export", moduleName.ticket),
      },
      {
        icon: <FontAwesomeIcon icon={faBook} />,
        menuName: "Manual Booking",
        path: "/admin/dashboard/manual-booking",
        display: checkRolePermissions("create", moduleName.manualBooking),
      },
      {
        icon: <FontAwesomeIcon icon={faSignOutAlt} />,
        menuName: "Sign Out",
        path: "/login",
        display: true,
      },
    ],

    newMenu: [],
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <div style={{ display: "flex" }}>
          <Sidebar menus={this.state.menus} />
          <SidebarRoutes></SidebarRoutes>
        </div>
      </>
    );
  }
}

export default DashboardTemplate;
