import React, { Component } from "react";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { URL, moduleName } from "../../../../utils/Constants";
import { displayErrorAlert } from "../../../../utils/DisplayMessage";
import CreateAdds from "./CreateAdds";
import { checkRolePermissions } from "../../../../utils/Helper";

export class Advertisements extends Component {
  state = {
    adData: [],
    modalOpen: false,
    editMode: false,
    updatePermission: false,
    deletePermission: false,
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  handleModal = () => {
    this.setState({
      modalOpen: true,
      editMode: false,
    });
  };

  getAllAdds = () => {
    axiosGet(URL.getAllAdds, (response) => {
      if (response.status === 200) {
        this.setState({
          adData: response.data.data.items,
        });
      }
    });
  };

  componentDidMount() {
    this.permissions();
  }

  editClick = (editData) => {
    this.setState({
      editData: editData,
      modalOpen: true,
      editMode: true,
    });
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let formData = new FormData();

    let data = {
      display: checked,
    };

    formData.append("jsonData", JSON.stringify(data));

    axiosPost(
      URL.updateAdd + item.id,
      formData,
      (response) => {
        if (response.status === 200) {
          this.setState({ loader: false });
          this.getAllAdds();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions(
          "delete",
          moduleName.advertisement
        ),
        updatePermission: checkRolePermissions(
          "update",
          moduleName.advertisement
        ),
      },
      function () {
        this.getAllAdds();
      }
    );
  };

  render() {
    return (
      <div>
        <div className="dashboardHeader">
          <strong>Advertisements</strong>
        </div>
        <div className="container py-2">
          <div className="py-2">
            {this.state.adData.length > 1
              ? ""
              : checkRolePermissions("create", moduleName.advertisement) && (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleModal}
                  >
                    Add Data
                  </button>
                )}
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped mb-0 text-center">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Display Location</th>
                  <th>Display</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.adData?.map((ad, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{ad.name}</td>
                    <td>{ad.description}</td>
                    <td>{ad.type}</td>

                    <td>
                      {" "}
                      <label className="switch mt-2">
                        <input
                          type="checkbox"
                          onChange={(e) => this.handleChangeToggle(e, ad)}
                          checked={ad.display}
                          name="enabled"
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>

                    <td>{ad.link}</td>
                    <td>
                      {this.state.updatePermission && (
                        <button
                          className="btn btn-primary"
                          onClick={() => this.editClick(ad)}
                        >
                          Edit
                        </button>
                      )}
                      {/* <button>Delete</button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <CustomModal
          modal={this.state.modalOpen}
          modalBody={
            <CreateAdds
              getAllAdds={this.getAllAdds}
              editMode={this.state.editMode}
              editData={this.state.editData}
              toggleModal={this.toggleModal}
            />
          }
          modalHeader={
            this.state.editMode ? "Edit Advertisement" : "Add Advertisement"
          }
          size="lg"
          toggleModal={this.toggleModal}
        ></CustomModal>
      </div>
    );
  }
}

export default Advertisements;
