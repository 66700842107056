import React, { Component } from "react";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { axiosPost } from "../../../../utils/AxiosApi";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { URL } from "../../../../utils/Constants";
import moment from "moment";
import swal from "sweetalert";

class CreateDailySchedule extends Component {
  state = {
    scheduleDate: this.props.updateReqData
      ? this.props.updateReqData.date
      : new Date(),

    route: "",
    departureTime: this.props.updateReqData
      ? this.props.updateReqData.departure_time
      : "",
    arrivalTime: this.props.updateReqData
      ? this.props.updateReqData.arrival_time
      : "",
    fare: this.props.updateReqData ? this.props.updateReqData.fare : "",
    duration: this.props.updateReqData ? this.props.updateReqData.duration : "",
    loader: false,
    busId: this.props.updateReqData ? this.props.updateReqData.bus_id : "",
    routeId: this.props.updateReqData ? this.props.updateReqData.route_id : "",
    scheduleSetting: this.props.updateReqData
      ? this.props.updateReqData.auto_schedule
      : "auto",
  };

  componentDidMount = () => {};

  handleChange = (e) => {
    let { name, value } = e.target;
    // this.setState({ [name]: value });

    if (name === "fare") {
      if (value.includes("-") || value.includes("--")) {
        swal("Warning", "Fare cannot be negative value.", "warning");
        // this.setState({ [name]: "" });
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  durationDifference = () => {
    if (this.state.departureTime !== "" && this.state.arrivalTime !== "") {
      var departure = moment(this.state.departureTime, "DD-MM-YYYY");
      var arrival = moment(this.state.arrivalTime, "DD-MM-YYYY");
      let time = arrival.diff(departure, "hours");

      this.setState({
        duration: time,
      });
    }
  };

  handleDateChange = (fieldName, date) => {
    if (fieldName === "scheduleDate") {
      this.setState({
        scheduleDate: date,
      });
    } else if (fieldName === "departureTime") {
      this.setState(
        {
          departureTime: date,
        },
        function () {
          this.durationDifference();
        }
      );
    } else if (fieldName === "arrivalTime") {
      this.setState(
        {
          arrivalTime: date,
        },
        function () {
          this.durationDifference();
        }
      );
    }

    // if (this.state.departureTime !== "" && this.state.arrivalTime !== "") {
    //   this.durationDifference();
    // }
  };

  handleSave = () => {
    if (
      this.state.scheduleDate !== "" &&
      this.state.busId !== "" &&
      this.state.routeId !== "" &&
      this.state.departureTime !== "" &&
      this.state.arrivalTime !== "" &&
      this.state.fare !== "" &&
      this.state.duration !== ""
    ) {
      let data = {
        date: moment(this.state.scheduleDate).format("YYYY-MM-DD HH:mm:ss"),
        bus_id: this.state.busId,
        route_id: this.state.routeId,
        departure_time: moment(this.state.departureTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        arrival_time: moment(this.state.arrivalTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        fare: this.state.fare,
        duration: this.state.duration,
      };

      this.setState({ loader: true }, () => {
        axiosPost(
          URL.insertDailySchedule,
          data,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
              this.props.getDailySchedule();
              this.props.handleCreateModal();
              // this.setState({
              //   dataSpinner: false,
              // });
            }
          },
          (err) => {
            this.setState({ dataSpinner: false });
          }
        );
      });
    } else {
      swal("Warning", "Fill all the fields", "warning");
    }
  };

  handleUpdate = () => {
    if (
      this.state.scheduleDate !== null &&
      this.state.busId !== "" &&
      this.state.routeId !== "" &&
      this.state.departureTime !== null &&
      this.state.arrivalTime !== null &&
      this.state.fare !== "" &&
      this.state.duration !== ""
    ) {
      let data = {
        id: this.props.updateReqData.id,
        date: moment(this.state.scheduleDate).format("YYYY-MM-DD HH:mm:ss"),
        bus_id: this.state.busId,
        route_id: this.state.routeId,
        departure_time: moment(this.state.departureTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        arrival_time: moment(this.state.arrivalTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        fare: this.state.fare,
        duration: this.state.duration,
      };
      this.setState({ loader: true }, () => {
        axiosPost(
          URL.updateDailySchedule,
          data,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getDailySchedule();
              this.props.handleCreateModal();
              // this.setState({
              //   dataSpinner: false,
              // });
            }
          },
          (err) => {
            this.setState({ dataSpinner: false });
          }
        );
      });
    } else {
      swal("Warning", "Fill all the fields", "warning");
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}

        <div>
          <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Schedule Date</strong>
            </div>
            <div className="col-md-9">
              <DatePicker
                // timeInputLabel="Time:"
                // dateFormat="MM/dd/yyyy h:mm aa"
                // showTimeInput
                // todayButton="Today"
                minDate={new Date()}
                className="datepick"
                name="scheduleDate"
                isClearable
                showMonthDropdown
                showYearDropdown
                shouldCloseOnSelect={false}
                placeholderText="Select schedule date"
                selected={this.state.scheduleDate}
                onChange={(date) => this.handleDateChange("scheduleDate", date)}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Select Bus</strong>
            </div>
            <div className="col-md-6">
              <select
                name="busId"
                value={this.state.busId}
                className="form-select"
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Choose Bus
                </option>
                {this.props.buses.length > 0
                  ? this.props.buses.map((bus, idx) => (
                      <option value={bus.id} key={idx}>
                        {bus.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Select Routes</strong>
            </div>
            <div className="col-md-6">
              <select
                name="routeId"
                value={this.state.routeId}
                className="form-select"
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Choose Route
                </option>
                {this.props.routes && this.props.routes.length > 0
                  ? this.props.routes.map((route, idx) => (
                      <option value={route.id} key={idx}>
                        {route.origin_city.name} - {route.destination_city.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Departure Time</strong>
            </div>
            <div className="col-md-9">
              <DatePicker
                minDate={new Date()}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                // todayButton="Today"
                //    minDate={props.getExpectedDate(2)}
                className="datepick"
                name="departureTime"
                isClearable
                showMonthDropdown
                showYearDropdown
                shouldCloseOnSelect={false}
                placeholderText="Select departure date"
                selected={this.state.departureTime}
                onChange={(date) =>
                  this.handleDateChange("departureTime", date)
                }
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Arrival Time</strong>
            </div>
            <div className="col-md-9">
              <DatePicker
                minDate={
                  this.state.departureTime
                    ? this.state.departureTime
                    : new Date()
                }
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                // todayButton="Today"

                className="datepick"
                name="arrivalTime"
                isClearable
                showMonthDropdown
                showYearDropdown
                shouldCloseOnSelect={false}
                placeholderText="Select departure date"
                selected={this.state.arrivalTime}
                onChange={(date) => this.handleDateChange("arrivalTime", date)}
              />
            </div>
          </div>
          {/* <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Duration</strong>
            </div>
            <div className="col-md-9">
              <input
                className="form-control"
                name="duration"
                disabled
                type="text"
                value={this.state.duration}
              />
            </div>
          </div> */}

          <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Bus Fare</strong>
            </div>
            <div className="col-md-6">
              <input
                className="form-control"
                name="fare"
                onChange={this.handleChange}
                type="number"
                min="0"
                value={this.state.fare}
              />
            </div>
          </div>

          {/* <div className="row mt-2">
            <div className="col-md-3 text-end" style={{ marginTop: "6px" }}>
              <strong>Schedule</strong>
            </div>
            <div className="col-md-6">
              <select
                className="form-control"
                onChange={(e) => this.handleChange(e)}
                name="scheduleSetting"
                value={this.state.scheduleSetting}
              >
                <option value="manual">Manual</option>
                <option value="auto">Automatic</option>
              </select>
            </div>
          </div> */}

          <div className="row mt-3">
            <div className="col text-end">
              {this.props.editMode ? (
                <button
                  className="btn btn-primary me-3"
                  onClick={this.handleUpdate}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-primary me-3"
                  onClick={this.handleSave}
                >
                  Save
                </button>
              )}
              <button
                className="btn btn-danger"
                onClick={this.props.handleCreateModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateDailySchedule;
