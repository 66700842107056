import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import {
  checkRolePermissions,
  getRolesFromStorage,
} from "../../../../utils/Helper";
import CreateDailySchedule from "./CreateDailySchedule";
import DailyScheduleDetail from "./DailyScheduleDetail";
import DailyScheduleFilter from "./DailyScheduleFilter";
import Pagination from "react-js-pagination";

class DailySchedule extends Component {
  state = {
    dailySchedule: [],
    scheduleDetails: [],
    busRoutes: [],
    routes: [],
    editMode: false,
    loader: false,
    busTypes: [],
    busTypeId: "",
    origin: "",
    destination: "",
    arrivalTime: "",
    departureTime: "",
    order: "",
    detailModal: false,
    scheduleSetting: "1",
    activeModule: "main",
    deletePermission: false,
    updatePermission: false,
  };

  permissions = () => {
    this.setState({
      editPermission: checkRolePermissions("update", moduleName.dailySchedule),
      deletePermission: checkRolePermissions(
        "delete",
        moduleName.dailySchedule
      ),
    });
  };

  getBuses = () => {
    axiosGet(
      URL.buses,
      (response) => {
        if (response.status === 200) {
          this.setState({
            buses: response.data.data.data,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getBusRoutes = () => {
    this.setState({ spinner: true, busRoute: [] }, function () {
      axiosGet(
        URL.busRoutePoints,
        (response) => {
          if (response.status === 200) {
            this.setState({
              busRoutes: response.data.data.items,
              spinner: false,
            });
          }
        },
        (err) => {
          this.setState({ spinner: false });
        }
      );
    });
  };

  getTravelAgencyRoutes = () => {
    axiosGet(
      URL.travelAgencyRoutes,
      (response) => {
        if (response.status === 200) {
          this.setState({
            routes: response.data.data.data,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getDailySchedule = (pageNumber = 1) => {
    this.setState({ dataSpinner: true, dailySchedule: [] }, function () {
      axiosGet(
        URL.getDailySchedule + "?" + "page=" + pageNumber,
        (response) => {
          if (response.status === 200) {
            this.setState({
              dailySchedule: response.data.data,
              dataSpinner: false,
              activeModule: "main",
            });
          }
        },
        (err) => {
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      editMode: false,
      updateReqData: "",
    });
  };

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosGet(
        URL.deleteDailySchedule + `/${params.id}`,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
            this.setState({
              loader: false,
            });
            this.getDailySchedule();
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ loader: false });
        }
      );
    });
  };

  handleDeleteBtnClick = (e, id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleEditBtnClick = (item) => {
    let newDate = new Date(item.date);
    let newArrivalTime = new Date(item.arrival_time);
    let newDepartureTime = new Date(item.departure_time);

    let upData = {
      ...item,
      date: newDate,
      arrival_time: newArrivalTime,
      departure_time: newDepartureTime,
    };

    this.setState({
      editMode: true,
      createModal: !this.state.createModal,
      updateReqData: upData,
    });
  };

  SplitTime = (numberOfHours) => {
    var Days = Math.floor(numberOfHours / 24);
    var Remainder = numberOfHours % 24;
    var Hours = Math.floor(Remainder);
    // var Minutes = Math.floor(60 * (Remainder - Hours));

    if (Days > 0) {
      var duration = Days + " days " + Hours + " hours";
    } else if (Days == 0) {
      duration = Hours + " hours";
    }

    return duration;
  };

  getBusTypes = () => {
    axiosGet(
      URL.busTypes,
      (response) => {
        if (response.status === 200) {
          this.setState({
            busTypes: response.data.data.data,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getCities = () => {
    axiosPost(URL.getCities, { name: "" }, (response) => {
      if (response.status === 200) {
        let items = response.data.data.items;
        items.sort(function (a, b) {
          return a.name.localeCompare(b.name); //using String.prototype.localCompare()
        });
        this.setState({ cities: items });
      }
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "scheduleSetting") {
        this.getDailySchedule();
      }
    });
  };

  handleDateChange = (fieldName, date) => {
    if (fieldName === "departureTime") {
      this.setState({
        departureTime: date,
      });
    } else if (fieldName === "arrivalTime") {
      this.setState({
        arrivalTime: date,
      });
    }
  };

  handleSearch = () => {
    let data = {
      bus_type_id: this.state.busTypeId,
      origin: this.state.origin,
      destination: this.state.destination,
      departure_time: this.state.departureTime,
      arrival_time: this.state.arrivalTime,
      order: this.state.order,
    };
    this.setState({ dataSpinner: true }, () => {
      axiosPost(
        URL.filterDailySchedule,
        data,
        (response) => {
          if (response.status === 200) {
            this.setState({
              dailySchedule: response.data.data,
              dataSpinner: false,
              activeModule: "search",
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({
            dataSpinner: false,
          });
        }
      );
    });
  };

  resetState = () => {
    this.setState({
      busTypeId: "",
      origin: "",
      destination: "",
      arrivalTime: "",
      departureTime: "",
      order: "",
      loader: false,
    });
    this.getDailySchedule();
  };

  handleDetailBtnClick = (item) => {
    this.setState({
      spinner: true,
    });
    axiosGet(URL.getDailyScheduleDetails + "/" + item.id, (response) => {
      if (response.status === 200) {
        this.setState({
          spinner: false,
          scheduleDetails: response.data.data.item,
        });
      }
    });

    this.setState({
      detailModal: true,
    });
  };

  toggleDetailModal = () => {
    this.setState({
      detailModal: !this.state.detailModal,
    });
  };

  componentDidMount() {
    this.permissions();
    this.getDailySchedule();
    this.getBusRoutes();
    this.getBuses();
    this.getTravelAgencyRoutes();
    this.getBusTypes();
    this.getCities();
  }

  handlePageChange = (pageNumber) => {
    if (this.state.activeModule === "main") {
      this.getDailySchedule(pageNumber);
    } else {
      this.handleSearch(pageNumber);
    }
  };

  render() {
    const { current_page, per_page, total } = this.state.dailySchedule;
    const roles = getRolesFromStorage();

    return (
      <>
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="dashboardHeader">
          <strong>Daily Schedule</strong>
        </div>

        <div className="container-fluid">
          {/* <div className="row mt-4">
            <div className="col">
              {checkRolePermissions("create", moduleName.busAgency) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Daily Schedule{" "}
                </button>
              ) : null}
            </div>
          </div> */}

          <DailyScheduleFilter
            busTypes={this.state.busTypes}
            busTypeId={this.state.busTypeId}
            origin={this.state.origin}
            destination={this.state.destination}
            arrivalTime={this.state.arrivalTime}
            departureTime={this.state.departureTime}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            cities={this.state.cities}
            handleSearch={this.handleSearch}
            order={this.state.order}
            resetState={this.resetState}
          />

          {/* <div className="row py-2">
            <div className="col-md-6">
              <div className="d-flex justify-content-center align-items-center">
                <span className="me-3">
                  {" "}
                  <h5>Schedule:</h5>
                </span>
                <select
                  className="form-control"
                  onChange={(e) => this.handleChange(e)}
                  name="scheduleSetting"
                  value={this.state.scheduleSetting}
                >
                  <option value="0">Manual</option>
                  <option value="1">Automatic</option>
                </select>
              </div>
            </div>
          </div> */}
          <div className="row mt-2">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Schedule Date</th>
                      <th>Bus Name</th>
                      <th>Route</th>
                      <th>Departure Date</th>
                      <th>Departure Time</th>
                      <th>Arrival Date</th>
                      <th>Arrival Time</th>
                      <th>Fare</th>
                      <th>Duration</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dailySchedule &&
                      this.state.dailySchedule?.data?.length > 0 ? (
                      this.state.dailySchedule?.data?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>

                            <td>{item.date}</td>
                            <td>
                              {item.bus_name}
                              <br />
                              {roles[0] === "Network Admin" && (
                                <span className="badge rounded-pill bg-info text-dark text-white">
                                  {item.travel_agency.name}
                                </span>
                              )}
                            </td>
                            <td>
                              {item.origin} - {item.destination}
                            </td>
                            <td>
                              {moment(item.departure_time).format(
                                "MMMM Do YYYY"
                              )}
                            </td>
                            <td>
                              {" "}
                              {moment(item.departure_time).format("h:mm:ss a")}
                            </td>
                            <td>
                              {moment(item.arrival_time).format("MMMM Do YYYY")}
                            </td>
                            <td>
                              {" "}
                              {moment(item.arrival_time).format("h:mm:ss a")}
                            </td>

                            <td>{item.fare}</td>
                            <td>{this.SplitTime(item.duration)}</td>

                            <td className="d-flex flex-column justify-content-center">
                              {this.state.updatePermission && (
                                <button
                                  className="btn btn-primary m-1"
                                  onClick={() => this.handleEditBtnClick(item)}
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                              )}

                              {this.state.deletePermission && (
                                <button
                                  className="btn btn-danger m-1"
                                  onClick={(e) =>
                                    this.handleDeleteBtnClick(e, item.id)
                                  }
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              )}
                              <button
                                className="btn btn-primary m-1"
                                onClick={() => this.handleDetailBtnClick(item)}
                              >
                                <i className="fa-solid fa-eye"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={11} className="text-center p-3">
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "Daily schedule data not found. Create one first"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.dailySchedule.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>
          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateDailySchedule
                editMode={this.state.editMode}
                getDailySchedule={this.getDailySchedule}
                buses={this.state.buses}
                busRoutes={this.state.busRoutes}
                routes={this.state.routes}
                handleCreateModal={this.handleCreateModal}
                updateReqData={this.state.updateReqData}
              />
            }
            modalHeader={
              this.state.editMode ? "Edit Daily Schedule" : "Add Daily Schedule"
            }
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>

          <CustomModal
            modal={this.state.detailModal}
            size="xl"
            modalHeader="Daily Schedule Detail"
            spinner={this.state.spinner}
            modalBody={
              <DailyScheduleDetail
                scheduleDetails={this.state.scheduleDetails}
              />
            }
            toggleModal={this.toggleDetailModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default DailySchedule;
