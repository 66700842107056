import React, { Component, Fragment } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";

// import { ENV } from "../constants/variables";
// import { getToken } from "../services/auth";

class CKeditorComponent extends Component {
  render() {
    return (
      <CKEditor
        editor={ClassicEditor}
        data={this.props.value}
        onChange={this.props.onChange}
        config={{
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "indent",
              "outdent",
              "|",
              "imageUpload",
              "blockQuote",
              "mediaEmbed",
              "undo",
              "redo",
            ],
          },
          image: {
            toolbar: [
              "imageTextAlternative",
              "imageStyle:full",
              "imageStyle:side",
            ],
          },
        }}
      />
    );
  }
}

export default CKeditorComponent;
