import React, { Component } from "react";
import swal from "sweetalert";

import { fileUrl, URL as URLs } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import "../../../assets/scss/dashboard.scss";
import { emailValidate, phoneValidate } from "../../../utils/validation";
import { getRolesFromStorage, validateImage } from "../../../utils/Helper";
class CreateTravelAgency extends Component {
  state = {
    address: "",
    contactDetails: "",
    email: "",
    id: "",
    name: "",
    imagePreview: "",
    loader: false,
    logo: "",
    logoFile: "",
    urlAddress: "",
    userRole: [],
    travelAgencies: [],
    travelAgencyId: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }

    let roles = getRolesFromStorage();

    if (roles[0] === "Network Admin") {
      this.getAllTravelAgency();
    }

    this.setState({
      userRole: roles,
    });
  }

  editConfig = () => {
    let selectedData = this.props.selectedData;
    this.setState({
      address: selectedData.address,
      travelAgencyId: selectedData.parent_travel_agency,
      contactDetails: selectedData.contact_details,
      email: selectedData.email,
      id: selectedData.id,
      name: selectedData.name,
      urlAddress: selectedData.front_end_url,
      imagePreview:
        selectedData.media.length > 0
          ? fileUrl +
            "/" +
            selectedData.media[0].id +
            "/" +
            selectedData.media[0].file_name
          : null,
    });
  };

  getAllTravelAgency = () => {
    axiosGet(
      URLs.getAllTravelAgencies,
      (response) => {
        if (response.status === 200) {
          this.setState({
            travelAgencies: response.data.data.items,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    if (validateImage(e.target.files[0])) {
      this.setState(
        {
          logo: e.target.value,
          logoFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.logoFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleSave = () => {
    let param = {};
    if (
      this.state.userRole[0] === "Network Admin" &&
      this.props.isChildTravelAgency
    ) {
      param = {
        name: this.state.name,
        address: this.state.address,
        contactDetails: this.state.contactDetails,
        email: this.state.email,
        front_end_url: this.state.urlAddress,
        parentTravelAgency: this.state.travelAgencyId,
      };
    } else {
      param = {
        name: this.state.name,
        address: this.state.address,
        contactDetails: this.state.contactDetails,
        email: this.state.email,
        front_end_url: this.state.urlAddress,
      };
    }

    let formData = new FormData();
    formData.append("travel_agency", JSON.stringify(param));
    formData.append("file", this.state.logoFile);

    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.contactDetails === "" ||
      this.state.email === "" ||
      this.state.logo === "" ||
      (this.props.isChildTravelAgency ? "" : this.state.urlAddress === "") ||
      (this.state.userRole[0] === "Network Admin" &&
        this.props.isChildTravelAgency &&
        this.state.travelAgencyId === "")
    ) {
      swal("Warning", "Fill all the fields.", "warning");
    } else {
      if (emailValidate(this.state.email)) {
        if (phoneValidate(this.state.contactDetails)) {
          this.setState({ loader: true }, function () {
            const url = this.props.isChildTravelAgency
              ? URLs.storeTravelChildAgency
              : URLs.travelAgency;

            axiosPost(
              url,
              formData,
              (response) => {
                if (response.status === 200) {
                  this.setState({ loader: false });
                  swal("Success", DISPLAYMESSAGE.createSuccess, "success");
                  this.props.getTravelAgencies();
                  this.props.handleCreateModal();
                }
              },
              (err) => {
                displayErrorAlert(err);
                this.setState({ loader: false });
              }
            );
          });
        }
      }
    }
  };

  handleUpdate = () => {
    let param = {};
    if (
      this.state.userRole[0] === "Network Admin" &&
      this.props.isChildTravelAgency
    ) {
      param = {
        id: this.state.id,
        name: this.state.name,
        address: this.state.address,
        contactDetails: this.state.contactDetails,
        email: this.state.email,
        front_end_url: this.state.urlAddress,
        parentTravelAgency: this.state.travelAgencyId,
      };
    } else {
      param = {
        id: this.state.id,
        name: this.state.name,
        address: this.state.address,
        contactDetails: this.state.contactDetails,
        email: this.state.email,
        front_end_url: this.state.urlAddress,
      };
    }

    let formData = new FormData();
    formData.append("travel_agency", JSON.stringify(param));
    formData.append("file", this.state.logoFile);

    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.contactDetails === "" ||
      this.state.email === "" ||
      this.state.imagePreview === "" ||
      (this.props.isChildTravelAgency ? "" : this.state.urlAddress === "")
    ) {
      swal("Warning", "Filll all the fields.", "warning");
    } else {
      if (emailValidate(this.state.email)) {
        if (phoneValidate(this.state.contactDetails)) {
          this.setState({ loader: true }, function () {
            // updateChildTravelAgency
            const url = this.props.isChildTravelAgency
              ? URLs.updateChildTravelAgency
              : URLs.updateTravelAgency;

            axiosPost(
              url,
              formData,
              (response) => {
                if (response.status === 200) {
                  this.setState({ loader: false });

                  swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
                  this.props.getTravelAgencies();
                  this.props.handleCreateModal();
                }
              },
              (err) => {
                displayErrorAlert(err);
                this.setState({ loader: false });
              }
            );
          });
        }
      }
    }
  };

  removeImage = () => {
    this.setState({ logo: "", imagePreview: "", logoFile: "" });
  };

  travelAgencyChange = (e) => {
    this.setState({
      travelAgencyId: e.target.value,
    });
  };

  render() {
    console.log(this.props.isChildTravelAgency);
    return (
      <div className="container">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        {this.state.imagePreview !== "" ? (
          <div className="row mt-2 mb-2">
            <div className="col text-center">
              <div className="imageHolder">
                <img src={this.state.imagePreview} />
              </div>
            </div>
          </div>
        ) : null}

        {this.state.userRole[0] === "Network Admin" &&
          this.props.isChildTravelAgency && (
            <div className="row mt-2">
              <div className="col-md-3 mt-1">
                <strong>Travel Agency</strong>
              </div>

              <div className="col-md-9">
                <select
                  name="travelAgencyId"
                  className="form-select"
                  value={this.state.travelAgencyId}
                  onChange={(e) => this.travelAgencyChange(e)}
                  disabled={this.props.edit}
                >
                  <option value="" selected disabled>
                    Select travel agency
                  </option>
                  {this.state.travelAgencies.map((ta) => (
                    <option value={ta.id}>{ta.name}</option>
                  ))}
                </select>
              </div>
            </div>
          )}

        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Logo</strong>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col">
                {this.props.edit &&
                this.state.imagePreview &&
                this.state.logo === "" ? (
                  <input
                    className="form-control"
                    type="text"
                    value={this.props.selectedData.media[0].name}
                    disabled
                  />
                ) : (
                  <input
                    accept="image/png, image/jpeg"
                    className="form-control"
                    name="logo"
                    onChange={this.handleImageChange}
                    type="file"
                    value={this.state.logo}
                  />
                )}
              </div>
              {this.state.imagePreview !== "" ? (
                <div className="col-md-auto">
                  <button
                    className="btn btn-danger ml-3"
                    onClick={this.removeImage}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Name</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="name"
              onChange={this.handleChange}
              type="text"
              value={this.state.name}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Address</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="address"
              onChange={this.handleChange}
              type="text"
              value={this.state.address}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Phone / Mobile</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="contactDetails"
              onChange={this.handleChange}
              type="text"
              value={this.state.contactDetails}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Email</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="email"
              onChange={this.handleChange}
              type="email"
              value={this.state.email}
            />
          </div>
        </div>
        {this.props.isChildTravelAgency ? null : (
          <div className="row mt-2">
            <div className="col-md-3 mt-1">
              <strong>Website URL</strong>
            </div>
            <div className="col-md-9">
              <input
                className="form-control"
                name="urlAddress"
                onChange={this.handleChange}
                type="text"
                value={this.state.urlAddress}
              />
            </div>
          </div>
        )}
        <div className="row mt-2">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Save
              </button>
            )}

            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.props.handleCreateModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateTravelAgency;
