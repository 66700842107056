import React, { Component } from "react";
import { Button } from "reactstrap";
import "../../../../assets/scss/searchfilter.scss";
import DatePicker from "react-datepicker";

class DailyScheduleFilter extends Component {
  render() {
    return (
      <>
        <div className="filter-wrapper">
          <div className="filter-container">
            <div className="search-input-div">
              <div className="row">
                <div className="col-md-3">
                  <div className="input-boxes">
                    {" "}
                    <select
                      className="form-select"
                      name="busTypeId"
                      value={this.props.busTypeId}
                      onChange={this.props.handleChange}
                    >
                      <option value="" disabled selected>
                        Select Bus Type
                      </option>
                      {this.props.busTypes.length > 0
                        ? this.props.busTypes.map((busType, idx) => (
                            <option value={busType.id} key={idx}>
                              {busType.type}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-boxes">
                    <select
                      name="origin"
                      className="form-select"
                      value={this.props.origin}
                      onChange={this.props.handleChange}
                    >
                      <option value="" disabled selected>
                        Choose Origin
                      </option>
                      {this.props.cities && this.props.cities.length > 0
                        ? this.props.cities.map((city, idx) => {
                            return (
                              <option value={city.id} key={idx}>
                                {city.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-boxes">
                    <select
                      name="destination"
                      className="form-select"
                      value={this.props.destination}
                      onChange={this.props.handleChange}
                    >
                      <option value="" disabled selected>
                        Choose Destination
                      </option>
                      {this.props.cities && this.props.cities.length > 0
                        ? this.props.cities.map((city, idx) => {
                            return (
                              <option value={city.id} key={idx}>
                                {city.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-boxes">
                    <select
                      name="order"
                      className="form-select"
                      onChange={this.props.handleChange}
                      value={this.props.order}
                    >
                      <option value="" disabled selected>
                        Choose Price Range
                      </option>
                      <option value="low">Low To High</option>
                      <option value="high">High To Low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="input-boxes">
                    <DatePicker
                      minDate={new Date()}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      // todayButton="Today"
                      //    minDate={props.getExpectedDate(2)}
                      className="datepick form-control"
                      name="departureTime"
                      isClearable
                      showMonthDropdown
                      showYearDropdown
                      shouldCloseOnSelect={false}
                      placeholderText="Select departure date"
                      selected={this.props.departureTime}
                      onChange={(date) =>
                        this.props.handleDateChange("departureTime", date)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-boxes">
                    <DatePicker
                      minDate={new Date()}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      // todayButton="Today"

                      className="datepick form-control"
                      name="arrivalTime"
                      isClearable
                      showMonthDropdown
                      showYearDropdown
                      shouldCloseOnSelect={false}
                      placeholderText="Select arrival date"
                      selected={this.props.arrivalTime}
                      onChange={(date) =>
                        this.props.handleDateChange("arrivalTime", date)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="search-btn-div">
              <Button
                className="busTicketForm-btn m-2"
                onClick={this.props.resetState}
              >
                Reset
              </Button>
              <Button
                className="busTicketForm-btn"
                onClick={this.props.handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DailyScheduleFilter;
