import {
  faAngleDown,
  faAngleUp,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner } from "reactstrap";
import CustomCollapse from "./CustomCollapse";

function BusConfigurationUI(props) {
  return (
    <div
      className="busConfigurationUIHolder"
      style={props.busConfig ? { height: "66.2vh" } : { marginTop: "-5px" }}
    >
      <div className="row">
        <div className="col-md-auto" style={{ marginTop: "8px" }}>
          Maximum Total Seats in a single Row
        </div>
        <div className="col">
          <input
            type="number"
            min={0}
            name="totalSeatsInRow"
            className="form-control"
            value={props.totalSeatsInRow}
            onChange={props.handleChange}
          />
        </div>
        <div className="col text-end">
          <button
            className="btn btn-primary"
            onClick={props.addBusRow}
            disabled={props.totalSeatsInRow == 0}
          >
            Add Bus Row
          </button>
        </div>
      </div>
      {props.seats.length > 0 ? (
        <div className="row mt-2">
          <div className="col">
            <div
              style={{
                border: "1px solid #ccc",
                padding: "8px 0 3px 0",
                maxHeight: props.busConfig ? "57.5vh" : "66.2vh",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {props.seats.map((row, idx) => {
                return (
                  <div className="container-fluid mb-1" key={idx}>
                    <div className="row rowHeader">
                      <div className="col-md-2 rowTexts">
                        <strong>Row {idx+1}</strong>
                      </div>
                      {/* <div className="col-md-2">
                        <input
                          type="text"
                          name="row"
                          value={row.row}
                          onChange={(e) =>
                            props.handleConfigureRowChange(e, idx)
                          }
                          style={{ width: "100%", textAlign: "center" }}
                        ></input>
                      </div> */}
                      <div className="col-md-8"></div>
                      <div className="col-md-1 text-center rowTexts">
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={() => props.deleteBusRow(idx)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className="col-md-1 rowTexts"
                        onClick={() => props.toggleCollpasible(idx)}
                      >
                        <FontAwesomeIcon
                          icon={row.isOpen ? faAngleUp : faAngleDown}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <CustomCollapse
                      isOpen={row.isOpen}
                      collapseClass="seatColumns"
                      collapsibleBody={
                        <React.Fragment>
                          <table className="table table-bordered table-striped mb-0 text-center">
                            <thead>
                              <tr>
                                <th>Column Name</th>
                                <th>Driver Seat</th>
                                <th>Open for Booking</th>
                                <th>Is Empty</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.columns.map((col, cIdx) => {
                                return (
                                  <tr key={cIdx}>
                                    <td>
                                      <input
                                        type="text"
                                        name="columnName"
                                        value={col.columnName}
                                        onChange={(e) =>
                                          props.handleColumnDataChange(
                                            e,
                                            idx,
                                            cIdx
                                          )
                                        }
                                        disabled={false}
                                        style={{
                                          width: "50px",
                                          textAlign: "center",
                                        }}
                                      ></input>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="is_driver"
                                        checked={col.is_driver}
                                        onChange={(e) =>
                                          props.handleColumnDataChange(
                                            e,
                                            idx,
                                            cIdx
                                          )
                                        }
                                        disabled={col.is_empty}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="is_open_for_booking"
                                        checked={col.is_open_for_booking}
                                        onChange={(e) =>
                                          props.handleColumnDataChange(
                                            e,
                                            idx,
                                            cIdx
                                          )
                                        }
                                        disabled={col.is_driver || col.is_empty}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="is_empty"
                                        checked={col.is_empty}
                                        onChange={(e) =>
                                          props.handleColumnDataChange(
                                            e,
                                            idx,
                                            cIdx
                                          )
                                        }
                                        disabled={col.is_driver}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </React.Fragment>
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : props.configLoader ? (
        <div className="text-center p-4">
          <Spinner color="primary"></Spinner>
          <br></br>Loading Config...
        </div>
      ) : null}
    </div>
  );
}

export default BusConfigurationUI;
