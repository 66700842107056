import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../../utils/AxiosApi";
import { fileUrl, URL as URLs } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";

class AddPaymentGateway extends Component {
  state = {
    paymentGateway: "",

    customFields: [],
    spinner: false,
  };

  resetState = () => {
    this.setState({
      paymentGateway: "",
      customFields: [],
    });
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setUpdateConfig();
    }
  }

  handleCancel = () => {
    this.props.handleCreateModal();
    this.setState({
      paymentGateway: "",
      customFields: [],
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCustomFieldChange = (e, idx) => {
    let { name, value } = e.target;
    let customFields = [...this.state.customFields];

    customFields[idx][name] = value;

    this.setState({ customFields });
  };

  handleSave = () => {
    let newData = [];
    this.state.customFields.map(
      (cf) =>
        (newData = [
          ...newData,
          {
            id: cf.id,
            value: cf.value,
          },
        ])
    );

    this.setState({ spinner: true }, function () {
      let errChk = 0;

      this.state.customFields.forEach((dt) => {
        if (dt.value === undefined || dt.value === "") {
          errChk++;
        }
      });

      let data = {
        paymentGateway: this.state.paymentGateway,
        is_active: this.state.status,
        customFields: newData,
      };

      if (this.state.paymentGateway !== "") {
        if (
          this.state.paymentGateway === "" ||
          this.state.gatewayImageFile === "" ||
          errChk > 0
        ) {
          swal("Warning", "Fill all the fields.", "warning");
          this.setState({ spinner: false });

          return;
        } else {
          axiosPost(
            URLs.paymentGateway,
            data,
            (response) => {
              this.setState({ spinner: false });
              if (response.status === 200) {
                swal("Success", DISPLAYMESSAGE.createSuccess, "success");
                this.props.getPaymentGateways();
                this.props.handleCreateModal();
                this.resetState();
              }
            },
            (err) => {
              this.setState({ spinner: false });
              displayErrorAlert(err);
            }
          );
        }
      } else {
        swal("Error", "Select the payment method first", "error");
        this.setState({ spinner: false });
        return;
      }
    });
  };

  handleUpdate = () => {
    let errChk = 0;

    this.state.customFields.forEach((dt) => {
      if (dt.value === undefined || dt.value === "") {
        errChk++;
      }
    });
    this.setState({ spinner: true }, function () {
      let cfData = [...this.state.customFields];
      let newData = [];
      if (this.state.status == 0) {
        newData = cfData.map((nw) => {
          return {
            id: nw.id,
            value: nw.value,
            is_active: 0,
          };
        });
      } else {
        newData = cfData.map((nw) => {
          return {
            id: nw.id,
            value: nw.value,
            is_active: 1,
          };
        });
      }

      if (errChk > 0) {
        swal("Warning", "Payment key value cannot be empty.", "warning");
        this.setState({ spinner: false });

        return;
      } else {
        axiosPost(
          URLs.updatePaymentGateway,
          newData,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getPaymentGateways();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      }
    });
  };

  setUpdateConfig = () => {
    let paymentData = this.props.selectedData;

    if (paymentData) {
      this.setState({
        selectedGateway: paymentData,
        paymentGateway: paymentData.payment_gateway,
        status: paymentData.paymentKeys[0].is_active,
        imagePreview:
          paymentData.media.length > 0
            ? fileUrl +
              "/" +
              paymentData.media[0].id +
              "/" +
              paymentData.media[0].file_name
            : null,
      });
      if (paymentData.paymentKeys.length > 0) {
        this.setState({
          customFields: paymentData.paymentKeys,
        });
      }
    }
  };

  handleSelectedGateway = (data) => {
    let chosenMethod = this.props.globalPaymentGateway.filter(
      (gp) => gp.payment_gateway === data
    );

    this.setState({
      paymentGateway: chosenMethod[0].payment_gateway,
      selectedGateway: chosenMethod[0],
      customFields: chosenMethod[0].customFields,
      status: chosenMethod[0].is_active,
    });
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}

        <div className="row mb-3">
          <div className="col-md-4 mt-1">
            <strong>Payment Gateway</strong>
          </div>
          <div className="col-md-8">
            {this.props.edit ? (
              <select className="form-control" disabled>
                <option selected>{this.state.paymentGateway}</option>
              </select>
            ) : (
              <select
                className="form-control"
                onChange={(e) => this.handleSelectedGateway(e.target.value)}
              >
                <option value="" disabled selected>
                  Choose payment method
                </option>
                {this.props.globalPaymentGateway.map((gp) => (
                  <option value={gp.payment_gateway} name="chosenGateway">
                    {gp.payment_gateway}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        {this.state.selectedGateway && (
          <>
            <div className="row mb-3">
              <div className="col-md-4 mt-1">
                <strong>Status</strong>
              </div>
              <div className="col-md-8">
                <input
                  type="radio"
                  className="m-2"
                  name="status"
                  value="1"
                  onChange={this.handleChange}
                  // checked={this.state.status && this.state.status === "1"}
                  defaultChecked={this.state.status == "1"}
                />
                Active
                <input
                  type="radio"
                  className="m-2"
                  name="status"
                  value="0"
                  onChange={this.handleChange}
                  defaultChecked={this.state.status == "0"}
                />
                Inactive
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4 mt-1">
                <strong>Gateway Image</strong>
              </div>
              <div className="row mt-2 mb-2">
                <div className="col text-center">
                  <div
                    className="imageHolder"
                    style={{ border: "1px solid #ccc" }}
                  >
                    <img
                      src={
                        fileUrl +
                        "/" +
                        this.state.selectedGateway.media[0].id +
                        "/" +
                        this.state.selectedGateway.media[0].file_name
                      }
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ border: "1px solid #ccc", padding: "10px" }}>
              <div className="paymentHeader">
                <strong>Payment Keys</strong>
              </div>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5 text-center">
                  <strong>Label Name</strong>
                </div>
                <div className="col-md-5 text-center">
                  <strong>Value</strong>
                </div>
              </div>
              {this.state.customFields.map((field, idx) => {
                return (
                  <div className="row mt-2" key={idx}>
                    <div className="col-md-1"></div>
                    <div className="col-md-5 text-center">
                      <strong>{field.display_name}</strong>
                    </div>
                    <div className="col-md-5">
                      <input
                        type="text"
                        className="form-control"
                        name="value"
                        value={field.value}
                        onChange={(e) => this.handleCustomFieldChange(e, idx)}
                        placeholder={field.place_holder_info}
                      />
                      {/* <small>*{field.placeHolderInfo}</small> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Create
              </button>
            )}
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPaymentGateway;
