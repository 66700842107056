import React, { Component } from "react";
import { Chart } from "chart.js/auto";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import moment, { weekdays } from "moment";

export default class TicketBookingChart extends Component {
  chartRef = React.createRef();

  state = {
    dailyChartFetch: null,

    fromDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    toDate: new Date(),
    activeReport: "monthly",
    chartData1: "",
    chartData: {},
  };

  getDailyReport = () => {
    axiosPost(
      URL.getAllTicketSales,
      {
        salesFilter: "Daily",
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      },
      (response) => {
        let resData = response.data.data.items;

        let labelData = resData.map((rd) => {
          return rd.Day + " " + rd.Month + " " + rd.Year;
        });

        let datasetData = resData.map((rd) => {
          return rd.DailyTotal;
        });

        this.setState(
          {
            chartData: {
              labels: labelData,

              datasets: [
                {
                  label: "Tickets Sales",
                  data: datasetData,
                  backgroundColor: "green",
                  borderColor: "green",
                  borderWidth: 1,
                },
              ],
            },
          },
          () => {
            this.getChartData();
          }
        );
      }
    );
  };

  changeChartData = (data) => {
    this.setState(
      {
        activeReport: data || "monthly",
      },
      () => {
        if (this.state.activeReport === "daily") {
          this.setState(
            {
              toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
              fromDate: this.state.fromDate
                ? this.state.fromDate
                : moment(this.state.toDate)
                    .subtract(7, "days")
                    .format("YYYY-MM-DD"),
            },
            () => {
              axiosPost(
                URL.getAllTicketSales,
                {
                  salesFilter: "Daily",
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate,
                },
                (response) => {
                  let resData = response.data.data.items;

                  let labelData = resData.map((rd) => {
                    return rd.Day + " " + rd.Month + " " + rd.Year;
                  });

                  let datasetData = resData.map((rd) => {
                    return rd.DailyTotal;
                  });

                  this.setState(
                    {
                      chartData: {
                        labels: labelData,

                        datasets: [
                          {
                            label: "Tickets Sales",
                            data: datasetData,
                            backgroundColor: "green",
                            borderColor: "green",
                            borderWidth: 1,
                          },
                        ],
                      },
                    },
                    () => {
                      this.getChartData();
                    }
                  );
                }
              );
            }
          );
        } else if (this.state.activeReport === "monthly") {
          axiosPost(
            URL.getAllTicketSales,
            { salesFilter: "Monthly" },
            (response) => {
              let months = [
                "January",
                "Febraury",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];

              let responseData = response.data.data.items;

              // let salesDataArr = [];
              // months.forEach((m) => {
              //   responseData.forEach((rd) => {
              //     if (rd.Month === m) {
              //       salesDataArr.push(rd.MonthlyTotal);
              //     } else {
              //       salesDataArr.push(0);
              //     }
              //   });
              // });
              const salesDataArr = months.map((m) => {
                const monthlyTotal =
                  responseData.find((rd) => rd.Month === m)?.MonthlyTotal || 0;
                return monthlyTotal;
              });

              this.setState(
                {
                  chartData: {
                    labels: months,
                    datasets: [
                      {
                        label: "Tickets Sales",
                        data: salesDataArr,
                        backgroundColor: "green",
                        borderColor: "green",
                        borderWidth: 1,
                      },
                    ],
                  },
                },
                () => {
                  this.getChartData();
                }
              );
            }
          );
        } else if (this.state.activeReport === "yearly") {
          axiosPost(
            URL.getAllTicketSales,
            { salesFilter: "Yearly" },
            (response) => {
              let resData = response.data.data.items;

              let labelData = resData.map((rd) => {
                return rd.year;
              });

              let datasetData = resData.map((rd) => {
                return rd.YearlyTotal;
              });
              this.setState(
                {
                  chartData: {
                    labels: labelData,
                    datasets: [
                      {
                        label: "Tickets Sales",
                        data: datasetData,
                        backgroundColor: "green",
                        borderColor: "green",
                        borderWidth: 1,
                      },
                    ],
                  },
                },
                () => {
                  this.getChartData();
                }
              );
            }
          );
        } else if (this.state.activeReport === "weekly") {
          let startDate = moment().startOf("week");
          let endDate = moment().endOf("week");
          let nStartD = startDate.format("YYYY-MM-DD");
          let nEndD = endDate.format("YYYY-MM-DD");
          this.setState(
            {
              toDate: nEndD,
              fromDate: nStartD,
            },
            () => {
              axiosPost(
                URL.getAllTicketSales,
                {
                  salesFilter: "Daily",
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate,
                },
                (response) => {
                  let resData = response.data.data.items;

                  let weeksData = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ];

                  var dates = [];

                  weeksData.forEach((W) => {
                    dates.push(startDate.toDate());
                    startDate.add(1, "days");
                  });
                  let salesDataArr = [];
                  // dates.forEach((m) => {
                  //   let day = moment(m).format("DD");
                  //   resData.forEach((rd) => {
                  //     if (rd.Day === parseInt(day)) {
                  //       salesDataArr.push(rd.DailyTotal);
                  //     } else {
                  //       salesDataArr.push(0);
                  //     }
                  //   });
                  // });
                  let salesDataMap = {};
                  resData.forEach((rd) => {
                    salesDataMap[rd.Day] = rd.DailyTotal;
                  });

                  // iterate over dates and get sales data for each day
                  dates.forEach((m) => {
                    let day = moment(m).format("DD");
                    let salesData = salesDataMap[parseInt(day)] || 0;
                    salesDataArr.push(salesData);
                  });

                  // let datasetData = resData.map((rd) => {
                  //   return rd.DailyTotal;
                  // });

                  this.setState(
                    {
                      chartData: {
                        labels: weeksData,

                        datasets: [
                          {
                            label: "Tickets Sales",
                            data: salesDataArr,
                            backgroundColor: "green",
                            borderColor: "green",
                            borderWidth: 1,
                          },
                        ],
                      },
                    },
                    () => {
                      this.getChartData();
                    }
                  );
                }
              );
            }
          );
        }
      }
    );
  };

  getChartData = () => {
    if (this.state.chartData1) {
      this.state.chartData1.destroy();
    }
    const myChartRef = this.chartRef?.current?.getContext("2d");
    this.setState({
      chartData1: new Chart(myChartRef, {
        type: "line",
        data: this.state.chartData,
        options: {
          //Customize chart options
        },
      }),
    });
    //
  };

  componentDidMount() {
    this.changeChartData();
  }

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="container mt-3 mb-3">
        <div className="chartDataToggler mb-2">
          <button
            className={`chartToggler ${
              this.state.activeReport === "daily" ? "activeChartToggler" : ""
            }`}
            onClick={() => this.changeChartData("daily")}
          >
            Daily
          </button>
          <button
            className={`chartToggler ${
              this.state.activeReport === "weekly" ? "activeChartToggler" : ""
            }`}
            onClick={() => this.changeChartData("weekly")}
          >
            Weekly
          </button>
          <button
            className={`chartToggler ${
              this.state.activeReport === "monthly" ? "activeChartToggler" : ""
            }`}
            onClick={() => this.changeChartData("monthly")}
          >
            Monthly
          </button>
          <button
            className={`chartToggler ${
              this.state.activeReport === "yearly" ? "activeChartToggler" : ""
            }`}
            onClick={() => this.changeChartData("yearly")}
          >
            Yearly
          </button>
        </div>
        {this.state.activeReport === "daily" ? (
          <div className="row">
            <div className="col-md-5">
              <label htmlFor="fromDate">From Date</label>
              <input
                type="date"
                className="form-control"
                name="fromDate"
                value={
                  // this.state.fromDate
                  // ||
                  moment(this.state.fromDate).format("YYYY-MM-DD")
                }
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className="col-md-5">
              <label htmlFor="fromDate">To Date</label>

              <input
                type="date"
                className="form-control"
                name="toDate"
                value={moment(this.state.toDate).format("YYYY-MM-DD")}
                // value={this.state.toDate}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className="col-md-2 mt-4">
              <button className="btn btn-primary" onClick={this.getDailyReport}>
                Load
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* {this.state.dailyChartFetch ? (
          ""
        ) : ( */}
        <canvas
          id={`myChart${
            this.state.activeReport === "daily"
              ? 0
              : this.state.activeReport === "monthly"
              ? 1
              : 2
          }`}
          ref={this.chartRef}
        />
        {/* )} */}
      </div>
    );
  }
}
