import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../utils/AxiosApi";
import { emailValidate, phoneValidate } from "../../../utils/validation";
import { fileUrl, URL as URLs } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { validateImage } from "../../../utils/Helper";

class CreateBusAgency extends Component {
  state = {
    address: "",
    contactDetails: "",
    email: "",
    id: "",
    name: "",
    imagePreview: "",
    loader: false,
    logo: "",
    logoFile: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }
  }

  editConfig = () => {
    let selectedData = this.props.selectedData;
    this.setState({
      address: selectedData.address,
      contactDetails: selectedData.contact_details,
      email: selectedData.email,
      id: selectedData.id,
      name: selectedData.name,
      imagePreview:
        selectedData.media && selectedData.media.length > 0
          ? fileUrl +
            "/" +
            selectedData.media[0].id +
            "/" +
            selectedData.media[0].file_name
          : null,
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    if (validateImage(e.target.files[0])) {
      this.setState(
        {
          logo: e.target.value,
          logoFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.logoFile);
          this.setState({ imagePreview: image });
        }
      );
    } else {
      e.target.value = null;
    }
  };

  handleSave = () => {
    let param = {
      name: this.state.name,
      address: this.state.address,
      contactDetails: this.state.contactDetails,
      email: this.state.email,
      travel_agency_id: this.props.travelAgencyId,
    };
    let formData = new FormData();
    formData.append("bus_agency", JSON.stringify(param));
    formData.append("file", this.state.logoFile);

    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.email === "" ||
      this.state.contactDetails === "" ||
      this.state.logo === ""
    ) {
      swal("Error", "Fill all the fields", "error");
    } else {
      if (emailValidate(this.state.email)) {
        this.setState({ loader: true }, function () {
          axiosPost(
            URLs.busAgencies,
            formData,
            (response) => {
              if (response.status === 200) {
                this.setState({ loader: false });
                swal("Success", DISPLAYMESSAGE.createSuccess, "success");
                this.props.getBusAgencies();
                this.props.handleCreateModal();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({ loader: false });
            }
          );
        });
      }
    }
  };

  handleUpdate = () => {
    let param = {
      id: this.state.id,
      name: this.state.name,
      address: this.state.address,
      contactDetails: this.state.contactDetails,
      email: this.state.email,
    };
    let formData = new FormData();
    formData.append("bus_agency", JSON.stringify(param));
    formData.append("file", this.state.logoFile);

    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.email === "" ||
      this.state.contactDetails === "" ||
      this.state.imagePreview === ""
    ) {
      swal("Error", "Fill all the fields", "error");
    } else {
      if (emailValidate(this.state.email)) {
        if (phoneValidate(this.state.contactDetails)) {
          this.setState({ loader: true }, function () {
            axiosPost(
              URLs.updateBusAgency,
              formData,
              (response) => {
                if (response.status === 200) {
                  this.setState({ loader: false });

                  swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
                  this.props.getBusAgencies();
                  this.props.handleCreateModal();
                }
              },
              (err) => {
                displayErrorAlert(err);
                this.setState({ loader: false });
              }
            );
          });
        }
      }
    }
  };

  removeImage = () => {
    this.setState({ logo: "", imagePreview: "", logoFile: "" });
  };

  render() {
    return (
      <div className="container">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        {this.state.imagePreview && this.state.imagePreview !== "" ? (
          <div className="row mt-2 mb-2">
            <div className="col text-center">
              <div className="imageHolder">
                <img src={this.state.imagePreview} />
              </div>
            </div>
          </div>
        ) : null}
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Logo</strong>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col">
                {this.props.edit &&
                this.state.imagePreview &&
                this.state.logo === "" ? (
                  <input
                    className="form-control"
                    type="text"
                    value={this.props.selectedData.media[0].name}
                    disabled
                  />
                ) : (
                  <input
                    accept="image/png, image/jpeg"
                    className="form-control"
                    name="logo"
                    onChange={this.handleImageChange}
                    type="file"
                    value={this.state.logo}
                  />
                )}
              </div>
              {this.state.imagePreview && this.state.imagePreview !== "" ? (
                <div className="col-md-auto">
                  <button
                    className="btn btn-danger ml-3"
                    onClick={this.removeImage}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Name</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="name"
              onChange={this.handleChange}
              type="text"
              value={this.state.name}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Address</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="address"
              onChange={this.handleChange}
              type="text"
              value={this.state.address}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Phone / Mobile</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="contactDetails"
              onChange={this.handleChange}
              type="text"
              value={this.state.contactDetails}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Email</strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="email"
              onChange={this.handleChange}
              type="email"
              value={this.state.email}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Save
              </button>
            )}

            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.props.handleCreateModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateBusAgency;
