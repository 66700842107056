import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet } from "../../../../utils/AxiosApi";
import { URL, moduleName } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { checkRolePermissions } from "../../../../utils/Helper";
import CreateBusTypes from "./CreateBusTypes";
import BusTypeSeatConfiguration from "./BusTypeSeatConfiguration";
import BusSeatUI from "../../../../components/DashboardComponents/BusSeatUI";
import Pagination from "react-js-pagination";

class BusTypes extends Component {
  state = {
    busTypes: [],
    createModal: false,
    dataSpinner: false,
    // deletePermission: false,
    // edit: false,
    // editPermission: false,
    seatConfigData: [],
    seatConfigurePermission: false,
    seatModal: false,
    selectedData: "",
    viewConfigPermission: false,
    viewModal: false,
    editMode: false,
    updateReqData: "",
  };

  componentDidMount() {
    this.getBusTypes();
    this.permissions();
  }

  configureSeat = (item) => {
    this.setState({
      selectedData: item,
      seatModal: !this.state.seatModal,
      seatConfigData: [],
      editMode: false,
    });
  };

  getBusTypes = (pageNumber = 1) => {
    this.setState({ dataSpinner: true, busTypes: [] }, function () {
      axiosGet(
        URL.busTypes + "?" + "page=" + pageNumber,
        (response) => {
          if (response.status === 200) {
            this.setState({
              busTypes: response.data.data,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      editMode: false,
      selectedData: "",
      seatConfigData: [],
    });
  };

  handleDeleteBtnClick = (e, id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleDelete = (params) => {
    axiosGet(
      URL.deleteBusTypes + "/" + params.id,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getBusTypes();
        }
      },
      (err) => {
        displayErrorAlert(err);
        this.setState({ spinner: false, loader: false });
      }
    );
  };

  handleEdit = (item) => {
    this.setState({ selectedData: item, editMode: true, createModal: true });
  };

  handleViewSeat = (item) => {
    let id = item.id;
    if (!this.state.viewModal) {
      axiosGet(URL.getSingleBusTypeSeatConfiguration + "/" + id, (response) => {
        if (response.status === 200) {
          let datum = response.data.data.items[0].seat_configuration;
          let newData = [];

          datum.row.forEach((el) => {
            let columns = [];
            let count = 0;
            el.columns.forEach((cc) => {
              let columnName = "";
              if (cc.is_passage == 0) {
                // columnName = count + 1;
                columnName = cc.name;
                // count++;
              }
              columns.push({
                id: cc.id,
                columnName: columnName,
                is_driver: cc.is_driver == 1 ? true : false,
                is_open_for_booking: cc.is_open_for_booking == 1 ? true : false,
                is_empty: cc.is_passage == 1 ? true : false,
              });
            });
            newData.push({
              row: el.row,
              id: el.id,
              columns: columns,
            });
          });
          this.setState({
            seatConfigData: newData,
            viewModal: !this.state.viewModal,
          });
        }
      });
    } else {
      this.setState({ viewModal: !this.state.viewModal, seatConfigData: [] });
    }
  };

  permissions = () => {
    this.setState({
      seatConfigurePermission: checkRolePermissions(
        "create",
        moduleName.busTypeSeatConfiguration
      ),
      viewConfigPermission: checkRolePermissions(
        "view",
        moduleName.busTypeSeatConfiguration
      ),
    });
  };

  handleEditBtnClick = (item) => {
    axiosGet(
      URL.getSingleBusTypeSeatConfiguration + "/" + item.id,
      (response) => {
        if (response.status === 200) {
          let datum = response.data.data.items[0].seat_configuration;
          let newData = [];

          datum.row.forEach((el) => {
            let columns = [];
            let count = 0;
            el.columns.forEach((cc) => {
              let columnName = "";
              if (cc.is_passage == 0) {
                // columnName = count + 1;
                columnName = cc.name;
                // count++;
              }
              columns.push({
                id: cc.id,
                columnName: columnName,
                is_driver: cc.is_driver == 1 ? true : false,
                is_open_for_booking: cc.is_open_for_booking == 1 ? true : false,
                is_empty: cc.is_passage == 1 ? true : false,
              });
            });
            newData.push({
              row: el.row,
              id: el.id,
              columns: columns,
            });
          });
          this.setState({
            seatConfigData: newData,
            editMode: true,
            selectedData: item,
            seatModal: !this.state.seatModal,
            // viewModal: !this.state.viewModal,
          });
        }
      }
    );
  };

  render() {
    const { current_page, per_page, total } = this.state.busTypes;

    return (
      <>
        <div className="dashboardHeader">
          <strong>BUS TYPES</strong>
        </div>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.busTypeAgency) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Bus Types
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped text-center">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Type</th>
                      <th>Features</th>
                      {/* {this.state.viewConfigPermission ? ( */}
                      <th>Bus Seats</th>
                      {/* ) : null} */}
                      {/* {this.state.seatConfigurePermission ? ( */}
                      <th>Options</th>
                      {/* ) : null} */}
                      {/* {this.state.editPermission ||
                    this.state.deletePermission ? (
                      <th>Options</th>
                    ) : null} */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.busTypes?.data?.length > 0 ? (
                      this.state.busTypes?.data?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.type}</td>
                            <td>{item.feature}</td>
                            {/* {this.state.viewConfigPermission ? ( */}
                            <td>
                              {item.has_seat_configuration ? (
                                <>
                                  <button
                                    className="btn btn-secondary m-2"
                                    onClick={() => this.handleViewSeat(item)}
                                  >
                                    <i class="fa-solid fa-eye"></i>
                                  </button>
                                  <button
                                    className="btn btn-primary m-2"
                                    onClick={() =>
                                      this.handleEditBtnClick(item)
                                    }
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <p>No configuration</p>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.configureSeat(item)}
                                  >
                                    Configure
                                  </button>
                                </>
                              )}
                            </td>
                            {/* ) : null} */}
                            {/* {this.state.seatConfigurePermission ? ( */}
                            {/* <td>
                            {item.has_seat_configuration ? null : (
                              <button
                                className="btn btn-primary"
                                onClick={() => this.configureSeat(item)}
                              >
                                Configure
                              </button>
                            )}
                          </td> */}
                            {/* ) : null} */}

                            {/* {this.state.editPermission ||
                          this.state.deletePermission ? ( */}
                            <td>
                              {/* {this.state.editPermission ? ( */}
                              <button
                                className="btn btn-primary"
                                onClick={() => this.handleEdit(item)}
                              >
                                <i class="fa-solid fa-pen-to-square"></i>
                              </button>
                              {/* ) : null} */}
                              {/* {this.state.deletePermission ? ( */}
                              <button
                                className="btn btn-danger ms-3"
                                onClick={(e) =>
                                  this.handleDeleteBtnClick(e, item.id)
                                }
                              >
                                <i class="fa-solid fa-trash"></i>
                              </button>
                              {/* ) : null} */}
                            </td>
                            {/* ) : null} */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          // colSpan={
                          //   this.state.editPermission ||
                          //   this.state.deletePermission
                          //     ? 7
                          //     : 6
                          // }
                          colSpan={
                            this.state.seatConfigurePermission &&
                            this.state.viewConfigPermission
                              ? 5
                              : (this.state.seatConfigurePermission &&
                                  !this.state.viewConfigPermission) ||
                                (!this.state.seatConfigurePermission &&
                                  this.state.viewConfigPermission)
                              ? 5
                              : 5
                          }
                          className="text-center p-3"
                        >
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Bus Types found. Create one first"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.busTypes.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) => this.getBusTypes(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateBusTypes
                getBusTypes={this.getBusTypes}
                handleCreateModal={this.handleCreateModal}
                selectedData={this.state.selectedData}
                editMode={this.state.editMode}
              />
            }
            modalHeader={
              this.state.editMode ? "Edit Bus Types" : "Add Bus Types"
            }
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>

          <CustomModal
            modal={this.state.seatModal}
            modalClass="busConfigure"
            modalBody={
              <BusTypeSeatConfiguration
                editMode={this.state.editMode}
                // updateReqData={this.state.updateReqData}
                getBusTypes={this.getBusTypes}
                selectedData={this.state.selectedData}
                configureSeat={this.configureSeat}
                seatConfigData={this.state.seatConfigData}
              />
            }
            modalHeader={`Seat Configuration for 
            ${this.state.selectedData.type}
            `}
            size="xl"
            toggleModal={() => this.configureSeat("")}
          ></CustomModal>

          <CustomModal
            modal={this.state.viewModal}
            modalBody={
              <div style={{ zoom: "70%" }}>
                <BusSeatUI
                  seats={this.state.seatConfigData}
                  config={true}
                  view={true}
                />
              </div>
            }
            modalHeader="Bus Seat Configuration"
            toggleModal={this.handleViewSeat}
          />
        </div>
      </>
    );
  }
}

export default BusTypes;
