import React from "react";
import RoutesList from "../../Pages/Website/RoutesList/RoutesList";

export const DashboardBookings = () => {
  return (
    <div>
      <div className="dashboardHeader">
        <strong>Bus Bookings</strong>
      </div>

      <RoutesList />
    </div>
  );
};
