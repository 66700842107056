import swal from "sweetalert";

export function emailValidate(value) {
  let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailReg.test(value)) {
    return true;
  } else {
    swal("Please enter valid email", "Email not valid", "error");
    return;
  }
}

export function linkValidate(value) {
  var pattern =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  if (pattern.test(value)) {
    return true;
  } else {
    swal("Please enter valid link", "Link URL not valid", "error");
    return;
  }
}

export function phoneValidate(value) {
  var phoneno = /^[0-9\)\(\+\- ]*$/;
  if (value.match(phoneno) && value.length > 6) {
    return true;
  } else {
    swal("Please enter valid phone number", "Phone number not valid", "error");
    return;
  }
}
