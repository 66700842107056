import React, { Component } from "react";
import RoutesList from "../../Pages/Website/RoutesList/RoutesList";

export class ManualBooking extends Component {
  render() {
    return (
      <div>
        <div className="dashboardHeader">
          <strong>Manual Bookings</strong>
        </div>

        <RoutesList manualBooking={true} />
      </div>
    );
  }
}

export default ManualBooking;
