import { faArrowLeft, faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Spinner } from "reactstrap";
import { axiosGet, axiosGetWeb, axiosPost } from "../../utils/AxiosApi";
import { fileUrl, headers, keyHeaders, URL } from "../../utils/Constants";
import KhaltiCheckout from "khalti-checkout-web";
import swal from "sweetalert";
import PaymentReciept from "./PaymentReciept";
import { useNavigate } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import { v4 as uuidv4 } from "uuid";
import CustomFullSpinner from "../../components/WebsiteComponents/CustomFullSpinner";
import crypto from "crypto-js";

let form = null;
const PaymentPatners = ({
  flag,
  handlePaymentSuccess,
  disableClickAction,
  handleBusBooking,
  bookingDetails,
  setBookingDetails,
  handlePayment,
}) => {
  const navigate = useNavigate();

  const payPatnersImages = [
    {
      img: "http://www.iginepal.com/wp-content/uploads/2021/06/esewa-final.png",
      name: "E-Sewa",
    },
    {
      img: "https://rollingnexus.com/image/cache/catalog/Arun/Khalti.png-400x400.png",
      name: "Khalti",
    },
    {
      img: "https://www.askmeconsult.com/uploads/2020/01/imepay.png",
      name: "Ime Pay",
    },
    {
      img: "https://www.yetiairlines.com/file-manager/photos/2/Baneer/connect_ips.png",
      name: "Connect Ips",
    },
  ];

  const [payPatners, setPayPatners] = useState(payPatnersImages);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentModule, setPaymentModule] = useState(false);
  const [paymentTransactionModule, setPaymentTransactionModule] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [transactionPin, setTransactionPin] = useState("");
  const [optCode, setOptCode] = useState("");
  const [khaltiKeys, setKhaltiKeys] = useState("");
  const [response, setResponse] = useState("");
  const [EsewaPId, setEsewaPId] = useState("");
  const [khaltiToken, setKhaltiToken] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getPaymentGateways();
  }, []);

  // const getPaymentGateways = () => {
  //   setLoader(true);
  //   axiosGet(URL.getPaymentGateway, (response) => {
  //     if (response.status === 200) {
  //       let paymentGatewayData = response.data?.data?.items;

  //       let filteredGateways = paymentGatewayData.filter(
  //         (pg) =>
  //           pg.paymentKeys &&
  //           pg.paymentKeys.length > 0 &&
  //           pg.paymentKeys[0].is_active  == 1
  //       );

  //       setPaymentMethod(filteredGateways);
  //       setLoader(false);
  //     }
  //   });

  //   setLoader(false);
  // };
  const getPaymentGateways = () => {
    setLoader(true);

    axiosGetWeb(URL.getPaymentGateway, (response) => {
      if (response.status === 200) {
        let paymentGatewayData = response.data?.data?.items;

        let filteredGateways = paymentGatewayData.filter(
          (pg) =>
            pg.paymentKeys &&
            pg.paymentKeys.length > 0 &&
            pg.paymentKeys[0].is_active == 1
        );

        setPaymentMethod(filteredGateways);
        setLoader(false);
      }
    });

    setLoader(false);
  };

  // const getResponse = (method, token) => {
  //   let value = localStorage.getItem("token");

  //   const myInterval = setInterval(() => {
  //     const data = {
  //       pid: token,
  //       paymentMethod: method,
  //       token: token,
  //     };

  //     axiosPost(
  //       value ? URL.getTransactionDetail : URL.getGuestTransactionDetail,
  //       data,
  //       (response) => {
  //         if (response && response.status === 200) {
  //           if (response.data.data.item.alt_response === "Success") {
  //             setResponse(response.data?.data?.item?.alt_response);
  //             setLoader(false);
  //             handlePaymentSuccess();
  //             clearInterval(myInterval);
  //           } else if (
  //             response.data.data.item.alt_response === "Error" ||
  //             response.data.data.item.alt_response === "failure" ||
  //             response.data.data.item.alt_response === "Cancelled"
  //           ) {
  //             setLoader(false);
  //             clearInterval(myInterval);
  //             swal("Error", "Payment failure.");
  //             return;
  //           }
  //         }
  //       },
  //       (err) => {
  //         // setLoader(false);
  //         // clearInterval(myInterval);
  //       }
  //     );
  //   }, 5000);
  // };

  // const handlePayment = (param) => {
  //   let params = JSON.parse(localStorage.getItem("bookingDetails"));
  //   let token = localStorage.getItem("token");
  //   axiosPost(
  //     token ? URL.busBooking : URL.guestBusBooking,
  //     params,
  //     (response) => {
  //       if (response.status === 200) {
  //         // swal("Success", "Your seats are successfully booked.", "success");

  //         // const ShallowCopyOfMultiPageForm = [
  //         //   ...this.state.multiPageFormHeader,
  //         // ];
  //         // ShallowCopyOfMultiPageForm.forEach((h, index) => {
  //         //   index === 4 ? (h.isActive = true) : (h.isActive = false);
  //         //   // return h;
  //         // });
  //         setBookingDetails(response.data.data);

  //         // this.handleStepChangeFromCD();
  //         // localStorage.removeItem("selectedSeat");
  //       } else {
  //         // this.setState({
  //         //   loader: false,
  //         // });
  //         swal("Error", "Booking failed", "error");
  //       }
  //     }
  //   );

  //   if (bookingDetails) {
  //     setLoader(true);

  //     if (param.payment_gateway === "Khalti") {
  //       let khaltiKey = param.paymentKeys.find(
  //         (data) => data.field_name === "publickey"
  //       );

  //       let config = {
  //         publicKey: khaltiKey.value,
  //         productIdentity: "TajTicket",
  //         productName: "BusTicket",
  //         productUrl: "http://gameofthrones.wikia.com/wiki/Dragons",
  //         eventHandler: {
  //           onSuccess(payload) {
  //             setKhaltiToken(payload.token);
  //             axiosPost(URL.verifyKhaltiPayment, payload, (response) => {
  //               if (response.status === 200) {
  //                 getResponse("Khalti", payload.token);
  //               } else if (response.status === 400) {
  //                 swal("Error", "Payment failed", "error");
  //                 setLoader(false);
  //               }
  //             });
  //           },
  //           onError(error) {
  //             swal("Error", error, "error");
  //             setLoader(false);
  //           },
  //           onClose() {
  //
  //             setLoader(false);
  //           },
  //         },
  //         paymentPreference: [
  //           "KHALTI",
  //           // , "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"
  //         ],
  //       };
  //       let checkout = new KhaltiCheckout(config);
  //       // let totalFare = JSON.parse(localStorage.getItem("bookingDetails"));
  //       // let totalFareInPaisa = totalFare.fare * 100;
  //       // checkout.show({ amount: totalFareInPaisa });
  //       // minimum transaction amount must be 10, i.e 1000 in paisa.
  //       checkout.show({ amount: 1000 });
  //     }
  //     if (param.payment_gateway === "E-Sewa") {
  //       let eSewaData = param.paymentKeys.find(
  //         (data) => data.field_name === "scd"
  //       );

  //       let totalFare = JSON.parse(localStorage.getItem("bookingDetails"));

  //       let prodId =
  //         "ee2c3ca1-696b-" +
  //         Math.random().toString(36).substring(6, 10) +
  //         "-" +
  //         Math.random().toString(36).substring(3, 7) +
  //         "-" +
  //         Math.random().toString(36).substring(3, 17);

  //       let busBookingId = bookingDetails.bus_booking_id;

  //       let secretKey = base64_encode(headers["secret-key"]);
  //       let clientKey = base64_encode(headers["client-key"]);

  //       let sendData = {
  //         amt: totalFare.fare,
  //         psc: 0,
  //         pdc: 0,
  //         txAmt: 0,
  //         tAmt: totalFare.fare,
  //         pid: prodId,
  //         scd: eSewaData.value,
  //         su:
  //           URL.esewaVerification +
  //           `${busBookingId}/` +
  //           secretKey +
  //           `/` +
  //           clientKey,
  //         fu:
  //           URL.cancelledEsewaVerification +
  //           `${prodId}/` +
  //           `${totalFare.fare}/` +
  //           `${busBookingId}`,
  //       };
  //       post(sendData);
  //     } else {
  //       setPaymentModule(true);
  //     }
  //     setSelectedPaymentMethod(param);
  //   } else {
  //     swal("Error", "Booking failed. Cannot proceed to payment", "error");
  //   }
  // };

  // const getPaymentGateways = () => {
  //   axiosGet(URL.getPaymentGateway, (response) => {
  //     if (response.status === 200) {
  //       let paymentGatewayData = response.data?.data?.items;

  //       let filteredGateways = paymentGatewayData.filter(
  //         (pg) => pg.paymentKeys.length > 0 && pg.paymentKeys[0].is_active  == 1
  //       );
  //       setPaymentMethod(filteredGateways);
  //       // setPaymentMethod(paymentGatewayData);
  //     }
  //   });
  // };

  // const resetValue = () => {
  //   if (paymentTransactionModule) {
  //     setPaymentModule(true);
  //     setPaymentTransactionModule(false);
  //   } else {
  //     setPaymentModule(false);
  //     setSelectedPaymentMethod("");
  //     setContactNumber("");
  //     setTransactionPin("");
  //   }
  // };

  // const handleChange = (e) => {
  //   let { name } = e.target;
  //   switch (name) {
  //     case "contactNumber":
  //       setContactNumber(e.target.value);
  //       break;
  //     case "transactionPin":
  //       setTransactionPin(e.target.value);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const handleSubmit = () => {
  //   setPaymentTransactionModule(true);
  // };

  // const handleEsewaButton = () => {};

  // const post = (data) => {
  //   form = document.createElement("form");
  //   form.setAttribute("method", "POST");
  //   form.setAttribute("action", "https://uat.esewa.com.np/epay/main");
  //   form.setAttribute("target", "_blank");

  //   for (var key in data) {
  //     var hiddenField = document.createElement("input");
  //     hiddenField.setAttribute("type", "hidden");
  //     hiddenField.setAttribute("name", key);
  //     hiddenField.setAttribute("value", data[key]);
  //     form.appendChild(hiddenField);
  //   }

  //   document.body.appendChild(form);

  //   form.submit();

  //   getResponse("E-Sewa", data.pid);
  // };

  return (
    <>
      <section className="partners-section">
        <Container className="">
          <Row className="g-3">
            <>
              <Col className="" sm="12" md="12">
                <h1 className={flag ? "lead" : ""}>Our Payment Partners</h1>
                {flag ? (
                  <small
                    className="lead text-center justify-content-center"
                    style={{ marginLeft: "170px" }}
                  >
                    Choose a payment method ...
                  </small>
                ) : null}
              </Col>

              {loader ? (
                <div className="text-center">
                  <Spinner></Spinner>
                </div>
              ) : null}

              {paymentMethod && paymentMethod.length > 0 ? (
                paymentMethod
                  .filter((pm) => pm.is_active)
                  .map((pm, idx) => {
                    return (
                      <>
                        <Col
                          className="card-col"
                          xs="6"
                          md="3"
                          lg="3"
                          key={idx}
                        >
                          {/* <h1>{pm.payment_gateway}</h1>{" "} */}
                          <div className="img-container ratio ratio-1x1">
                            <img
                              src={
                                fileUrl +
                                "/" +
                                pm?.media[0]?.id +
                                "/" +
                                pm?.media[0]?.file_name
                              }
                              className=""
                              alt={idx}
                              // onClick={() => handlePayment(pm.name)}
                              onClick={
                                disableClickAction
                                  ? false
                                  : () => handlePayment(pm)
                              }
                            />
                          </div>
                        </Col>
                      </>
                    );
                  })
              ) : (
                <div className="text-center">No Payment keys available</div>
              )}
            </>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PaymentPatners;
