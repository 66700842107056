import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { fileUrl } from "../../../../utils/Constants";
import TicketReceipt from "../../../Website/MyTickets/TicketReceipt";
import "./schedule-detail.scss";

class DailyScheduleDetail extends Component {
  state = {
    receiptModal: false,
    receiptPdf: null,
    media: null,
  };

  toggleModal = () => {
    this.setState({
      receiptModal: !this.state.receiptModal,
    });
  };

  handleClickTicket = (param) => {
    if (param.length > 0) {
      // var filePath = fileUrl + "/" + param[0].id + "/" + param[0].file_name;

      this.setState({ receiptPdf: param[0] }, () => {
        this.toggleModal();
      });
    } else {
      swal("Error", "Payment receipt is Unavailable", "error");
    }
  };

  availableSeats = () => {
    let totalRemain = 0;
    this.props.scheduleDetails.bookedInfo.map((data) => {
      totalRemain = totalRemain + data.bus_seat_booking.length;
    });

    let availableSeat = this.props.scheduleDetails.totalSeat - totalRemain;
    return availableSeat;
  };

  bookedSeats = () => {
    let bookedSeat = 0;
    this.props.scheduleDetails.bookedInfo.map((data) => {
      bookedSeat = bookedSeat + data.bus_seat_booking.length;
    });

    return bookedSeat;
  };

  render() {
    let scheduleDetail = this.props.scheduleDetails;

    return (
      <div>
        <div className="schedule-detail-container">
          {scheduleDetail &&
          scheduleDetail.bookedInfo &&
          scheduleDetail.bookedInfo.length > 0 ? (
            <>
              <fieldset className="border p-2 mb-3">
                <legend className="float-none w-auto">
                  <strong> Bus & Route Details</strong>
                </legend>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Bus Name</strong>
                  </div>
                  <div className="col-md-2">{scheduleDetail.name}</div>
                  <div className="col-md-8"></div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Bus No. Plate</strong>
                  </div>
                  <div className="col-md-2">{scheduleDetail.number_plate}</div>
                  <div className="col-md-8"></div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Bus Route</strong>
                  </div>
                  <div className="col-md-4">
                    {scheduleDetail.origin[0].name}(
                    {scheduleDetail.origin[0].country_code}) -{" "}
                    {scheduleDetail.destination[0].name}(
                    {scheduleDetail.destination[0].country_code})
                  </div>
                  <div className="col-md-6"></div>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <strong>Total Seats</strong>
                  </div>
                  <div className="col-md-4">{scheduleDetail.totalSeat}</div>
                  <div className="col-md-6"></div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Booked Seats</strong>
                  </div>
                  <div className="col-md-4">{this.bookedSeats()}</div>
                  <div className="col-md-6"></div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Available Seats</strong>
                  </div>
                  <div className="col-md-4">{this.availableSeats()}</div>
                  <div className="col-md-6"></div>
                </div>
              </fieldset>

              <fieldset className="border p-2">
                <legend className="float-none w-auto">
                  <strong>Booking Details</strong>
                </legend>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Boarding Points</th>
                        <th>Booked Seats</th>
                        <th>Total Fare</th>
                        <th>Customer Detail</th>
                        <th>Invoice Number</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scheduleDetail.bookedInfo.map((ticket, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">{idx + 1}</td>
                            <td>
                              <strong> Pickup point:</strong>
                              <br />
                              {ticket?.pick_up_point?.name != null
                                ? ticket?.pick_up_point?.name
                                : "-"}
                              <br />
                              <strong>Dropping point:</strong>
                              <br />
                              {ticket?.dropping_point?.name != null
                                ? ticket?.dropping_point?.name
                                : "-"}
                            </td>
                            <td>
                              {ticket.bus_seat_booking.map((datum, idx) => {
                                return (
                                  <div key={idx}>
                                    {datum.seatName}
                                    {(datum.seat_booked_by || datum.remark) &&
                                      (datum.seat_booked_by || datum.remark)}
                                  </div>
                                );
                              })}
                              {ticket.remark && (
                                <div className="mt-2 border p-2">
                                  <b>Remarks</b>
                                  <p>{ticket.remark}</p>
                                </div>
                              )}
                            </td>
                            <td>{ticket.fare}</td>
                            <td>
                              <strong>Name: </strong>
                              {ticket.bus_booking_customer?.full_name}
                              <br />
                              <strong>Email: </strong>
                              {ticket.bus_booking_customer?.email}
                              <br />
                              <strong>Contact: </strong>
                              {ticket.bus_booking_customer?.phone_number}
                              <br />
                            </td>
                            <td>{ticket.invoice_number}</td>
                            <td>
                              <button
                                className="btn btn-info text-white btn-sm"
                                onClick={() =>
                                  this.handleClickTicket(ticket.media)
                                }
                              >
                                View Receipt
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </fieldset>
            </>
          ) : (
            <div className="text-center">
              {this.props.spinner ? (
                <Spinner></Spinner>
              ) : (
                "No any tickets booked yet."
              )}
            </div>
          )}
        </div>

        <CustomModal
          modal={this.state.receiptModal}
          modalHeader={"Receipt"}
          modalBody={<TicketReceipt receiptPdf={this.state.receiptPdf} />}
          size="lg"
          toggleModal={this.toggleModal}
        ></CustomModal>
      </div>
    );
  }
}

export default DailyScheduleDetail;
