import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import { axiosGet } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import { getRolesFromStorage } from "../../../utils/Helper";
import TicketReceipt from "../../Website/MyTickets/TicketReceipt";

const TicketContainer = (props) => {
  const [activeTickets, setActiveTickets] = useState([]);

  const [activeTab, setActiveTab] = useState("issued");
  const [spinner, setSpinner] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [receiptPdf, setReceiptPdf] = useState(false);
  const [roles, setRoles] = useState(null);

  const handleClickTicket = (param) => {
    if (param.length > 0) {
      // var filePath = fileUrl + "/" + param[0].id + "/" + param[0].file_name;

      setReceiptPdf(param[0]);
      toggleModal();
    } else {
      swal("Error", "Payment receipt is Unavailable", "error");
    }
  };

  const handleCancelTicket = (data) => {
    setSpinner(true);
    axiosGet(
      URL.cancelTicket + "/" + data.id,
      (response) => {
        if (response.status === 200) {
          setSpinner(false);
          swal("Success", "Ticket cancellation successful");
          props.handleViewDetails();
        }
      },
      (err) => {
        displayErrorAlert(err);
        setSpinner(false);
      }
    );
  };
  const getRoles = () => {
    let roles = getRolesFromStorage();
    setRoles(roles);
  };

  const toggleModal = () => {
    setReceiptModal(!receiptModal);
  };

  useEffect(() => {
    getRoles();
    let data = props.ticketsListData;

    let issuedTickets = [];
    let canceledTickets = [];

    data.map((d) =>
      d.cancellation_status ? canceledTickets.push(d) : issuedTickets.push(d)
    );

    if (activeTab === "issued") {
      setActiveTickets(issuedTickets);
    } else if (activeTab === "canceled") {
      setActiveTickets(canceledTickets);
    }
  }, [props.ticketsListData, activeTab]);

  //   useEffect(() => {}, [activeTab]);

  return (
    <div>
      {spinner ? (
        <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
      ) : null}
      <div className="tabs-div ">
        <div
          className={`tabs-div-box  ${
            activeTab === "issued" ? "activeTicketTab" : ""
          }`}
          onClick={() => setActiveTab("issued")}
        >
          Issued Tickets
        </div>
        <div
          className={`tabs-div-box  ${
            activeTab === "canceled" ? "activeTicketTab" : ""
          }`}
          onClick={() => setActiveTab("canceled")}
        >
          Canceled Tickets
        </div>
      </div>
      <div className="tickets-container">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Bus Info</th>
                <th>Boarding Points</th>

                <th>Booked Seats</th>
                <th>Total Fare</th>
                <th>Customer Detail</th>
                <th>Invoice Number</th>
                <th>Action</th>
              </tr>
            </thead>
            {activeTickets && activeTickets?.length > 0 ? (
              activeTickets?.map((ticket, idx) => {
                return (
                  <tr key={idx}>
                    <td className="text-center">{idx + 1}</td>
                    <td>
                      <strong>{ticket.bus.name}</strong>
                      <br></br>
                      <strong>No. Plate: </strong>
                      {ticket.bus.number_plate}
                      <br />
                      <strong>Route: </strong> {ticket.origin[0].country_code} -{" "}
                      {ticket.destination[0].country_code}
                    </td>

                    <td>
                      <strong> Pickup point:</strong>
                      <br />
                      {ticket?.pick_up_point?.name != null
                        ? ticket?.pick_up_point?.name
                        : "-"}
                      <br />
                      <strong>Dropping point:</strong>
                      <br />
                      {ticket?.dropping_point?.name != null
                        ? ticket?.dropping_point?.name
                        : "-"}
                    </td>
                    <td>
                      {ticket.bus_seat_booking.map((datum, idx) => {
                        return (
                          <div key={idx}>
                            {datum.seatName}
                            {(datum.seat_booked_by || datum.remark) &&
                              (datum.seat_booked_by || datum.remark)}
                          </div>
                        );
                      })}
                      {ticket.remark && (
                        <div className="mt-2 border p-2">
                          <b>Remarks</b>
                          <p>{ticket.remark}</p>
                        </div>
                      )}
                    </td>
                    <td>{ticket.fare}</td>
                    <td>
                      <strong>Name: </strong>
                      {ticket.bus_booking_customer.full_name}
                      <br />
                      <strong>Email: </strong>
                      {ticket.bus_booking_customer.email}
                      <br />
                      <strong>Contact: </strong>
                      {ticket.bus_booking_customer.phone_number}
                      <br />
                    </td>
                    <td>{ticket.invoice_number}</td>
                    <td>
                      <div
                        className="btn btn-view-receipt"
                        onClick={() => handleClickTicket(ticket.media)}
                      >
                        View Receipt
                      </div>
                      {roles[0] === "Company Admin" &&
                      activeTab === "issued" ? (
                        <div
                          className="btn btn-cancel-ticket m-2"
                          onClick={() => handleCancelTicket(ticket)}
                        >
                          Cancel Ticket
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan={9}>
                  {spinner ? (
                    <Spinner></Spinner>
                  ) : (
                    "You have not booked any tickets yet"
                  )}
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>

      <CustomModal
        modal={receiptModal}
        modalHeader={"Receipt"}
        modalBody={<TicketReceipt receiptPdf={receiptPdf} />}
        size="lg"
        toggleModal={toggleModal}
      ></CustomModal>
    </div>
  );
};

export default TicketContainer;
