import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";
import { DISPLAYMESSAGE } from "../../../../utils/DisplayMessage";

class CreateSliderImages extends Component {
  state = {
    sliderImages: [],
    spinner: false,
    tempImages: [],
  };

  displayRemoveButton = (id) => {
    let holder = document.getElementById("holder" + id);
    let overlayDiv = document.getElementById("overlay" + id);
    let btn = document.getElementById(id);
    holder.classList.add("blurFilter");
    overlayDiv.classList.add("boxOverlay");
    btn.classList.add("showRemoveBtn");
  };

  handleCancel = () => {
    this.setState({
      sliderImages: [],
      tempImages: [],
    });
  };

  handleDelete = (idx) => {
    let sliderImages = [...this.state.sliderImages];
    let tempImages = [...this.state.tempImages];
    sliderImages.splice(idx, 1);
    tempImages.splice(idx, 1);
    this.setState({ sliderImages, tempImages });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let data = e.target.files;
    let images = [...this.state.sliderImages];
    let tempImages = [...this.state.tempImages];
    for (let i = 0; i < data.length; i++) {
      images.push({
        src: window.URL.createObjectURL(data[i]),
        image: data[i],
      });
      tempImages.push(data[i]);
    }
    this.setState({ sliderImages: images });
  };

  handleSave = () => {
    if (this.state.sliderImages.length > 0) {
      let formData = new FormData();
      let sliderImages = [...this.state.sliderImages];
      sliderImages.forEach((el) => {
        formData.append("files[]", el.image);
      });
      this.setState({ spinner: true }, function () {
        axiosPost(URL.insertSliderImages, formData, (response) => {
          if (response.status === 200) {
            this.setState({ spinner: false });
            swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
            this.props.getSliderImages();
            this.props.handleCreateModal();
          }
        });
      });
    } else {
      swal("Error", "No images Selected", "error");
    }
  };

  hideRemoveBtn = (id) => {
    let holder = document.getElementById("holder" + id);
    let overlayDiv = document.getElementById("overlay" + id);
    let btn = document.getElementById(id);
    holder.classList.remove("blurFilter");
    overlayDiv.classList.remove("boxOverlay");
    btn.classList.remove("showRemoveBtn");
  };

  render() {
    return (
      <div
        className="container-fluid"
        style={{ maxHeight: "65vh", overflowX: "hidden", overflowY: "auto" }}
      >
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <input
          id="image"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
          onChange={(e) => this.handleImageChange(e)}
          multiple
          required
          value={this.state.tempImages}
        />
        {this.state.sliderImages.length > 0 ? (
          <div className="row">
            <div className="col text-end">
              <button
                className="btn btn-primary mt-2"
                onClick={() => {
                  this.upload.click();
                }}
              >
                Add Images
              </button>
            </div>
          </div>
        ) : null}
        <div className="row mt-2">
          <div className="col">
            <div className="cmsSliderImageDiv">
              {this.state.sliderImages.length > 0 ? (
                this.state.sliderImages.map((image, idx) => {
                  return (
                    <div
                      key={idx}
                      className="cmsSliderImageHolder"
                      onMouseEnter={() => this.displayRemoveButton(idx)}
                      onMouseLeave={() => this.hideRemoveBtn(idx)}
                    >
                      <div className="imageOverlay" id={"overlay" + idx}></div>
                      <div
                        className="insideImageHolder cmsSliderImageHolderThumb"
                        id={"holder" + idx}
                      >
                        <img src={image.src} />
                      </div>
                      <button
                        className="btn btn-danger sliderRemoveBtn"
                        id={idx}
                        onClick={() => this.handleDelete(idx)}
                      >
                        Remove
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className="p-3">
                  No Images Added
                  <br />
                  <button
                    className="btn btn-primary mt-2"
                    onClick={() => {
                      this.upload.click();
                    }}
                  >
                    Add Images
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <button className="btn btn-primary" onClick={this.handleSave}>
              Upload
            </button>
            <button className="btn btn-danger ms-2" onClick={this.handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateSliderImages;
