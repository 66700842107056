import React, { Component } from "react";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import { axiosGet } from "../../../utils/AxiosApi";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import PermissionsModal from "./PermissionsModal";
import { URL } from "../../../utils/Constants";
import { Spinner } from "reactstrap";
import { getRolesFromStorage } from "../../../utils/Helper";

class RolesAndPermissions extends Component {
  state = {
    dataSpinner: false,
    permissionsModal: false,
    permissionLoader: false,
    roles: [],
    permissions: [],
    travelAgencies: [],
    travelAgencyId: "",
  };

  componentDidMount() {
    // this.getPermissions();
    this.getRoles();
  }

  getAllTravelAgency = () => {
    axiosGet(
      URL.getAllTravelAgencies,
      (response) => {
        if (response.status === 200) {
          this.setState({
            travelAgencies: response.data.data.items,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getRoles = () => {
    const userRole = getRolesFromStorage();

    if (userRole[0] === "Network Admin") {
      this.getAllTravelAgency();
    }

    this.setState({ dataSpinner: true }, function () {
      axiosGet(
        URL.getRoles,
        (response) => {
          if (response.status === 200) {
            let rolesData = response.data
            // .filter(
            //   (rs) =>
            //     rs.name !== "System Admin" &&
            //     rs.name !== "Operator" &&
            //     rs.name !== "Registered Customer"
            // );

            this.setState({
              roles: rolesData,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  getPermissions = (id) => {
    this.setState({ permissionLoader: true }, function () {
      axiosGet(
        `${URL.getPermissions}/${id}`,
        (response) => {
          if (response.status === 200) {
            this.setState({
              permissions: response.data,
              permissionLoader: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ permissionLoader: false });
        }
      );
    });
  };

  getTravelAgencyWisePermission = (id) => {
    this.setState({ permissionLoader: true }, function () {
      axiosGet(
        `${URL.getTravelAgencyWisePermission}/${id}`,
        (response) => {
          if (response.status === 200) {
            this.setState({
              permissions: response.data,
              permissionLoader: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ permissionLoader: false });
        }
      );
    });
  };

  handleClick = (id) => {
    this.setState(
      {
        permissionsModal: !this.state.permissionsModal,
        roleId: id,
      },
      () => {
        if (this.state.permissionsModal === true) {
          this.getPermissions(id);
        }
      }
    );
  };

  travelAgencyChange = (e) => {
    this.setState(
      {
        travelAgencyId: e.target.value,
      },
      () => {
        this.setState(
          {
            permissionsModal: !this.state.permissionsModal,
          },
          () => {
            if (this.state.permissionsModal === true) {
              this.getTravelAgencyWisePermission(e.target.value);
            }
          }
        );
      }
    );
  };

  render() {
    const roles = getRolesFromStorage();

    return (
      <>
        <div className="dashboardHeader">
          <strong>ROLES AND PERMISSIONS</strong>
        </div>

        {roles[0] === "Network Admin" ? (
          <div className="container mt-4">
            Note: Choose travel agency to configure permissions
            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="travelAgencyId">Travel Agency</label>

                <select
                  name="travelAgencyId"
                  className="form-select"
                  value={this.state.travelAgencyId}
                  onChange={(e) => this.travelAgencyChange(e)}
                >
                  <option value="" selected disabled>
                    Select travel agency
                  </option>
                  {this.state.travelAgencies.map((ta) => (
                    <option value={ta.id}>{ta.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid m-2">
            {/* <div className="row">
            <div className="col">
              <button className="btn btn-success" onClick={this.handleAddRoles}>
                Add Roles
              </button>
            </div>
          </div> */}
            <div className="row">
              <div className="col">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Options</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.roles.length > 0 ? (
                        this.state.roles.map((role, index) => (
                          <tr key={index}>
                            <td>{role.name}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => this.handleClick(role.id)}
                              >
                                Permissions
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} className="text-center p-3">
                            {this.state.dataSpinner ? (
                              <>
                                <Spinner color="primary"></Spinner>
                                <br />
                                Loading Data...
                              </>
                            ) : (
                              "No Roles and Permissions found."
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        <CustomModal
          backdrop={true}
          modal={this.state.permissionsModal}
          modalBody={
            <PermissionsModal
              permissions={this.state.permissions}
              roleId={this.state.roleId}
              permissionLoader={this.state.permissionLoader}
              travelAgencyId={this.state.travelAgencyId}
            />
          }
          modalHeader="Permissions"
          size="lg"
          toggleModal={this.handleClick}
        ></CustomModal>
      </>
    );
  }
}

export default RolesAndPermissions;
