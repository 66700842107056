import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import BusImagesModal from "../../../../components/DashboardComponents/BusImagesModal";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet } from "../../../../utils/AxiosApi";
import { URL, moduleName } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import {
  checkRolePermissions,
  getRolesFromStorage,
} from "../../../../utils/Helper";
import CreateBusSeatConfiguration from "./CreateBusSeatConfiguration";
import CreateBus from "./CreateBus";
import BusSeatUI from "../../../../components/DashboardComponents/BusSeatUI";
import Pagination from "react-js-pagination";

class Bus extends Component {
  state = {
    buses: [],
    busId: "",
    busTypes: [],
    createModal: false,
    createSeatModal: false,
    createSeatPermission: false,
    dataSpinner: false,
    deletePermission: false,
    edit: false,
    editPermission: false,
    imageModal: false,
    mediaData: [],
    seatConfigData: [],
    selectedData: "",
    viewModal: false,
    viewSeatPermission: false,
  };

  componentDidMount() {
    this.getBuses();
    this.getBusTypes();
    this.permissions();
  }

  getBuses = (pageNumber = 1) => {
    this.setState({ dataSpinner: true, buses: [] }, function () {
      axiosGet(
        URL.buses + "?" + "page=" + pageNumber,
        (response) => {
          if (response.status === 200) {
            this.setState({
              buses: response.data.data,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  getBusTypes = () => {
    axiosGet(
      URL.busTypes,
      (response) => {
        if (response.status === 200) {
          this.setState({
            busTypes: response.data.data.data,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  handleCreateSeat = (item) => {
    if (item) {
      this.setState({ busId: item.id });
    }
    this.setState({
      createSeatModal: !this.state.createSeatModal,
      edit: false,
      seatConfigData: [],
    });
  };

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosGet(
        URL.deleteBus + "/" + params.id,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
            this.setState({
              loader: false,
            });
            this.getBuses();
          } else {
            swal("Error", response.message, "error");
          }
        },
        (error) => {
          displayErrorAlert(error);
        }
      );
    });
  };

  handleDeleteBtnClick = (id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleEdit = (item) => {
    this.setState({ selectedData: item, edit: true, createModal: true });
  };

  handleImageModal = (media) => {
    this.setState({ mediaData: media, imageModal: !this.state.imageModal });
  };

  handleViewSeat = (item) => {
    let id = item.id;
    if (!this.state.viewModal) {
      axiosGet(URL.getBusSeatConfiguration + "/" + id, async (response) => {
        if (response.status === 200) {
          let datum = await response.data.data.items[0].bus_rows;
          let newData = [];

          datum.forEach((el) => {
            let columns = [];
            let count = 0;
            el.bus_columns.forEach((cc) => {
              let columnName = "";
              if (cc.is_passage == 0) {
                // columnName = count + 1;
                // count++;
                columnName = cc.name;
              }
              columns.push({
                id: cc.id,
                columnName: columnName,
                is_driver: cc.is_driver == 1 ? true : false,
                is_open_for_booking: cc.is_open_for_booking == 1 ? true : false,
                is_empty: cc.is_passage == 1 ? true : false,
              });
            });
            newData.push({
              row: el.name,
              id: el.id,
              columns: columns,
            });
          });
          this.setState({
            seatConfigData: newData,
            viewModal: !this.state.viewModal,
          });
        }
      });
    } else {
      this.setState({ viewModal: !this.state.viewModal, seatConfigData: [] });
    }
  };

  permissions = () => {
    this.setState({
      editPermission: checkRolePermissions("update", moduleName.bus),
      viewSeatPermission: checkRolePermissions(
        "view",
        moduleName.busSeatConfiguration
      ),
      createSeatPermission: checkRolePermissions(
        "create",
        moduleName.busSeatConfiguration
      ),
      deletePermission: checkRolePermissions("delete", moduleName.bus),
    });
  };

  handleEditSeats = (item) => {
    axiosGet(URL.getBusSeatConfiguration + "/" + item.id, async (response) => {
      if (response.status === 200) {
        let datum = await response.data.data.items[0].bus_rows;
        let newData = [];

        datum.forEach((el) => {
          let columns = [];
          let count = 0;
          el.bus_columns.forEach((cc) => {
            let columnName = "";
            if (cc.is_passage == 0) {
              // columnName = count + 1;
              // count++;
              columnName = cc.name;
            }
            columns.push({
              id: cc.id,
              columnName: columnName,
              is_driver: cc.is_driver == 1 ? true : false,
              is_open_for_booking: cc.is_open_for_booking == 1 ? true : false,
              is_empty: cc.is_passage == 1 ? true : false,
            });
          });
          newData.push({
            row: el.name,
            id: el.id,
            columns: columns,
          });
        });
        this.setState({
          seatConfigData: newData,
          selectedData: item,
          // seatModal: !this.state.seatModal,
          edit: true,
          // viewModal: !this.state.viewModal,
          busId: item.id,
          createSeatModal: !this.state.createSeatModal,
        });
      }
    });
  };

  render() {
    const { current_page, per_page, total } = this.state.buses;

    const roles = getRolesFromStorage();
    return (
      <>
        <div className="dashboardHeader">
          <strong>BUSES</strong>
        </div>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.bus) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Bus
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped text-center">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Name</th>
                      <th width="130px">Bus Type</th>
                      <th>Number Plate</th>
                      <th>Features</th>
                      <th>Images</th>
                      {this.state.viewSeatPermission ? (
                        <th>Bus Seats</th>
                      ) : null}
                      {this.state.editPermission ||
                      this.state.createSeatPermission ? (
                        <th width="180px">Options</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.buses?.data?.length > 0 ? (
                      this.state.buses?.data?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              {item.name}
                              <br />
                              {roles[0] === "Network Admin" && (
                                <span className="badge rounded-pill bg-info text-dark text-white">
                                  {item.travel_agency.name}
                                </span>
                              )}
                            </td>
                            <td>{item.bus_type.type}</td>
                            <td>{item.number_plate}</td>
                            <td>{item.feature}</td>
                            <td>
                              {item.media.length > 0 ? (
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    this.handleImageModal(item.media)
                                  }
                                >
                                  <i class="fa-solid fa-eye"></i>
                                </button>
                              ) : (
                                "No Images"
                              )}
                            </td>
                            {this.state.viewSeatPermission ? (
                              <td>
                                {item.has_seat_configuration ? (
                                  <>
                                    <button
                                      className="btn btn-secondary m-1"
                                      onClick={() => this.handleViewSeat(item)}
                                    >
                                      <i class="fa-solid fa-eye"></i>
                                    </button>{" "}
                                    <button
                                      className="btn btn-info m-1"
                                      onClick={() => this.handleEditSeats(item)}
                                    >
                                      <i class="fa-solid fa-pen-to-square"></i>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <p>Not Configured</p>
                                    {this.state.createSeatPermission &&
                                    !item.has_seat_configuration ? (
                                      <button
                                        className="btn btn-primary me-3 mb-2"
                                        onClick={() =>
                                          this.handleCreateSeat(item)
                                        }
                                      >
                                        Configure
                                      </button>
                                    ) : null}
                                  </>
                                )}
                              </td>
                            ) : null}
                            {this.state.editPermission ||
                            this.state.createSeatPermission ? (
                              <td>
                                {this.state.editPermission ? (
                                  <button
                                    className="btn btn-primary me-2"
                                    onClick={() => this.handleEdit(item)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}

                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger ms-3"
                                    onClick={() =>
                                      this.handleDeleteBtnClick(item.id)
                                    }
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            (this.state.editPermission ||
                              this.state.createSeatPermission) &&
                            this.state.viewSeatPermission
                              ? 8
                              : ((this.state.editPermission ||
                                  this.state.createSeatPermission) &&
                                  !this.state.viewSeatPermission) ||
                                ((!this.state.editPermission ||
                                  !this.state.createSeatPermission) &&
                                  this.state.viewSeatPermission)
                              ? 8
                              : 7
                          }
                          className="text-center p-3"
                        >
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Bus found. Create one first"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {this.state.buses.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) => this.getBuses(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>
          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateBus
                getBuses={this.getBuses}
                handleCreateModal={this.handleCreateModal}
                selectedData={this.state.selectedData}
                edit={this.state.edit}
              />
            }
            modalHeader={this.state.edit ? "Edit Bus" : "Add Bus"}
            size="xl"
            toggleModal={this.handleCreateModal}
          ></CustomModal>

          <CustomModal
            modal={this.state.imageModal}
            modalBody={
              <BusImagesModal mediaData={this.state.mediaData}></BusImagesModal>
            }
            size="md"
            modalHeader="Bus Images"
            toggleModal={() => this.handleImageModal([])}
          />

          <CustomModal
            modal={this.state.createSeatModal}
            modalClass="busConfigure"
            modalBody={
              <CreateBusSeatConfiguration
                edit={this.state.edit}
                busId={this.state.busId}
                getBuses={this.getBuses}
                handleCreateSeat={this.handleCreateSeat}
                busTypes={this.state.busTypes}
                selectedData={this.state.selectedData}
                seatConfigData={this.state.seatConfigData}
              />
            }
            modalHeader={
              this.state.edit
                ? "Edit Bus Seat Configuration"
                : "Add Bus Seat Configuration"
            }
            size="xl"
            toggleModal={this.handleCreateSeat}
          ></CustomModal>

          <CustomModal
            modal={this.state.viewModal}
            modalBody={
              <div style={{ zoom: "70%" }}>
                <BusSeatUI
                  seats={this.state.seatConfigData}
                  config={true}
                  view={true}
                />
              </div>
            }
            modalHeader="Bus Seat Configuration"
            toggleModal={this.handleViewSeat}
          />
        </div>
      </>
    );
  }
}

export default Bus;
