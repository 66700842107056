import React from "react";
import PaymentPatners from "../PaymentPatners";

const PaymentMethod = ({
  handlePaymentSuccess,
  handleBusBooking,
  bookingDetails,
  setBookingDetails,
  handlePayment,
  paymentMethod,
}) => {
  return (
    <>
      <PaymentPatners
        flag={true}
        handlePaymentSuccess={handlePaymentSuccess}
        handleBusBooking={handleBusBooking}
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
        handlePayment={handlePayment}
        paymentMethod={paymentMethod}
      />
    </>
  );
};

export default PaymentMethod;
