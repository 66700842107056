import "../../../assets/scss/passengerDetails.scss";
import ReusableComponent from "./ReusableComponent";

const PassengerDetails = ({
  header,
  flag,
  handleStepChangeByOne,
  step,
  formData,
  selectedRoute,
  handleChangeManualDetails,
}) => {
  //This can be a reusable component -lets make it ...
  return (
    <>
      <ReusableComponent
        headerName="Passenger Details"
        flag={false}
        handleStepChangeByOne={handleStepChangeByOne}
        formData1={formData}
        selectedRoute={selectedRoute}
        handleChangeManualDetails={(e) => handleChangeManualDetails(e)}
      />
    </>
  );
};

export default PassengerDetails;
