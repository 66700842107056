import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import swal from "sweetalert";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";

class CreateTermsAndConditions extends Component {
  state = {
    act: "",
    spinner: false,
    termsAndConditions: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setUpdateConfig();
    }
  }

  handleCancel = () => {
    this.setState({
      act: "",
      termsAndConditions: "",
    });
  };

  handleChange = (event, editor) => {
    this.setState({
      termsAndConditions: editor.getData(),
    });
  };

  handleSave = () => {
    if (this.state.act !== "" && this.state.termsAndConditions !== "") {
      let data = {
        act: this.state.act,
        term_and_conditions: this.state.termsAndConditions,
      };
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.insertTermsAndCondition,
          data,
          (response) => {
            if (response.status === 200) {
              this.setState({ spinner: false });
              swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
              this.props.getTermsAndConditions();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    } else {
      swal("Error", "Act or Terms and Condition missing", "error");
    }
  };

  handleUpdate = () => {
    if (this.state.act !== "" && this.state.termsAndConditions !== "") {
      let data = {
        id: this.props.selectedData.id,
        act: this.state.act,
        term_and_conditions: this.state.termsAndConditions,
      };
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.updateTermsAndConditions,
          data,
          (response) => {
            if (response.status === 200) {
              this.setState({ spinner: false });
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getTermsAndConditions();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    } else {
      swal("Error", "Act or Terms and Condition missing", "error");
    }
  };

  setUpdateConfig = () => {
    let selectedData = this.props.selectedData;
    if (selectedData) {
      this.setState({
        act: selectedData.act,
      });
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col-md-1 mt-2 text-center">
            <strong>Act: </strong>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              name="act"
              value={this.state.act}
              onChange={(e) => this.setState({ act: e.target.value })}
            />
          </div>
        </div>
        <div className="cmsCk mt-3">
          <CKEditor
            editor={ClassicEditor}
            data={
              this.props.edit
                ? this.props.selectedData.term_and_conditions
                : this.state.termsAndConditions
            }
            onChange={(event, editor) => this.handleChange(event, editor)}
          />
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button
                className="btn btn-primary me-2"
                onClick={this.handleUpdate}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-primary me-2"
                onClick={this.handleSave}
              >
                Add
              </button>
            )}

            <button className="btn btn-danger" onClick={this.handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateTermsAndConditions;
