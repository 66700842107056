import { faBusAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Container } from "reactstrap";
import swal from "sweetalert";
import thankyouImg from "../../assets/images/thankyouImg.svg";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import receiptLogo from "../../assets/images/logo.jpg";

const PaymentReciept = (props) => {
  const [receipt, setReceipt] = useState("");
  const location = useLocation();

  useEffect(() => {
    let bookingDetails = location?.state?.bookingDetails;

    if (bookingDetails) {
      setReceipt(bookingDetails);
    }
  }, []);

  const handlePrint = (rootElementId) => {
    // var printContents = document.getElementById("payment-receipt").innerHTML;
    // var originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // document.body.innerHTML = originalContents;

    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("1", "mm", [300, 210]);

      pdf.setPage(1);
      pdf.setFont("helvetica");
      pdf.setFontSize(30); //Font is Points not page units = approx. 10 mm total but 2 mm below baseline so set 8 mm down !

      pdf.addImage(imgData, "JPG", 8.5, 10, 280, 190);

      pdf.save("download.pdf");
    });
  };

  return (
    <div>
      <div className="reciept">
        <div className="gradient-block"></div>
        <Container fluid="lg" className="busTicketForm-vertical">
          <div className="busTicketForm-div p-4">
            <div className="text-end p-2">
              <div className="invoice-btn">
                {/* <button
                  className="btn btn-success m-1"
                  onClick={() => handlePrint()}
                >
                  Print
                </button> */}
                <button
                  onClick={() => handlePrint("payment-receipt")}
                  className="btn btn-info m-1"
                >
                  Download
                </button>
              </div>
            </div>

            <div id="payment-receipt" className="p-4">
              <div className="receipt-watermark"></div>
              <div className="receipt-logo-head d-flex justify-content-between align-items-center">
                <div className="receipt-logo">
                  <img src={receiptLogo} alt="logo" />
                </div>
                <div className="receipt-title">
                  <h3>TajMahal Travel & Tours Pvt. ltd</h3>
                  <h1 className="text-end">E-ticket</h1>
                </div>
              </div>
              <div className="receipt-head-title d-flex justify-content-between mt-4 p-4">
                <div>
                  <div className="ro">
                    {/* <strong>Passenger Name</strong> */}

                    <span>{receipt.receiver}</span>
                  </div>
                  <div className="ro">
                    {/* <strong>Email</strong> */}

                    <span>{receipt.email}</span>
                  </div>
                  <div className="ro">
                    {/* <strong>Phone No.</strong> */}

                    <span>{receipt.phone_number}</span>
                  </div>
                </div>
                <div>
                  <div className="ro">
                    {/* <strong>Invoice No.</strong> */}

                    <span>#{receipt.invoice_number}</span>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="receipt-table table">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Bus Details</th>
                      <th scope="col">Seat Details</th>
                      <th scope="col">Travel Details</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <span>
                            <strong>Bus Name: </strong>
                          </span>
                          <span>{receipt.bus_name}</span>
                        </div>
                        <div>
                          <span>
                            {" "}
                            <strong>Bus Type: </strong>
                          </span>
                          {receipt.bus_type}
                        </div>
                        <div>
                          <span>
                            {" "}
                            <strong>Bus No. </strong>
                          </span>
                          {receipt.number_plate}
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>
                            <strong>No. of Seats :</strong>
                          </span>{" "}
                          <span>{receipt.no_of_seats}</span>
                        </div>
                        <div>
                          <strong>Seats : </strong>
                          {/* {receipt && receipt.seats.length > 1
                    ? receipt.seats.map((item) => <span>{item}, </span>)
                    : receipt.seats.map((item) => <span>{item}</span>)} */}
                          {receipt && receipt.seats.length > 0
                            ? receipt.seats.map((item, idx) => {
                                return (
                                  <span key={idx}>
                                    {item}
                                    {receipt.seats.length > 1 ? ", " : ""}
                                  </span>
                                );
                              })
                            : null}
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <FontAwesomeIcon icon={faBusAlt} />
                            <span
                              className="m-3"
                              style={{ fontSize: 20, fontWeight: 600 }}
                            >
                              {receipt.origin}
                            </span>
                          </div>
                          <div
                            // className="border-start border-dark border-2 border-stripped"
                            style={{
                              borderStyle: "dotted",
                              borderRight: 0,
                              borderTop: 0,
                              borderBottom: 0,
                              padding: 10,
                              margin: 5,
                            }}
                          >
                            <h5>
                              {/* {" "}
                      {moment(receipt.departure_date).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}{" "} */}
                              {receipt.departure_date}
                            </h5>
                          </div>

                          <div>
                            <FontAwesomeIcon icon={faBusAlt} />

                            <span
                              className="m-3"
                              style={{ fontSize: 20, fontWeight: 600 }}
                            >
                              {receipt.destination}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="border-start"
                          style={{
                            padding: 20,
                          }}
                        >
                          <div className="row">
                            <div className="col-md-8">Price per seat</div>
                            <div className="col-md-1">:</div>
                            <div className="col-md-2">{receipt.fare}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-8">Total seats</div>
                            <div className="col-md-1">:</div>
                            <div className="col-md-2">
                              {receipt.no_of_seats}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-8">Total</div>
                            <div className="col-md-1">:</div>
                            <div className="col-md-2">{receipt.total_fare}</div>
                          </div>

                          <hr />
                          <div className="row">
                            <div className="col-md-8">
                              <strong>Grand total</strong>
                            </div>
                            <div className="col-md-1">:</div>
                            <div className="col-md-2">{receipt.total_fare}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Container>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default PaymentReciept;
