import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { moduleName, URL } from "../../../utils/Constants";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import CreateTravelAgencyRoutes from "./CreateTravelAgencyRoutes";
import swal from "sweetalert";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import CreateRoutePoints from "./CreateRoutePoints";
import {
  checkRolePermissions,
  getRolesFromStorage,
} from "../../../utils/Helper";
import Pagination from "react-js-pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
class TravelAgencyRoutes extends Component {
  state = {
    cities: [],
    createModal: false,
    dataSpinner: false,
    routes: [],
    routePointModal: false,
    selectedRouteId: "",
    editMode: false,
    updateReqData: "",
  };

  closeRoutePointModal = () => {
    this.setState({
      routePointModal: !this.state.routePointModal,
      updateReqData: "",
      editMode: false,
    });
  };

  componentDidMount() {
    this.getRoutes();
    this.getCities();
  }

  getCities = () => {
    axiosPost(URL.getCities, { name: "" }, (response) => {
      if (response.status === 200) {
        let items = response.data.data.items;
        items.sort(function (a, b) {
          return a.name.localeCompare(b.name); //using String.prototype.localCompare()
        });
        this.setState({ cities: items });
      }
    });
  };

  getRoutes = (pageNumber = 1) => {
    this.setState({ dataSpinner: true, routes: [] }, function () {
      axiosGet(
        URL.travelAgencyRoutes + "?" + "page=" + pageNumber,
        (response) => {
          if (response.status === 200) {
            this.setState({
              routes: response.data.data,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, editMode: false });
  };

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosGet(
        URL.deleteRoutes + params.id,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
            this.setState({
              loader: false,
            });
            this.getRoutes();
          }
        },
        (err) => {
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleDeleteBtnClick = (e, id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleRoutePoints = (id) => {
    this.setState({
      selectedRouteId: id,
      routePointModal: !this.state.routePointModal,
    });
  };

  handleUpdateBtnClick = (route) => {
    this.setState({
      editMode: true,
      routePointModal: true,
      updateReqData: route,
      selectedRouteId: route.id,
    });
  };

  render() {
    const { current_page, per_page, total } = this.state.routes;

    const roles = getRolesFromStorage();

    return (
      <>
        <div className="dashboardHeader">
          <strong>TRAVEL AGENCY ROUTES</strong>
        </div>

        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.travelAgencyRoute) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Travel Agency Routes
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped  text-center">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      {roles[0] === "Network Admin" && <th>Travel Agency</th>}
                      <th>Origin City</th>
                      <th>Destination City</th>
                      <th>Documents Required</th>

                      <th>Route Points</th>
                      {roles[0] === "Network Admin" &&
                        (checkRolePermissions("create", moduleName.routePoint) ||
                          checkRolePermissions(
                            "delete",
                            moduleName.travelAgencyRoute
                          )) ? (
                        <th width="300px">Options</th>
                      ) : null}
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.routes?.data?.length > 0 ? (
                      this.state.routes?.data?.map((route, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            {roles[0] === "Network Admin" && (
                              <td>
                                {route.travel_agencies?.map((ta, idx) => (
                                  <>
                                    <span
                                      key={idx}
                                      className="badge rounded-pill bg-info text-dark text-white m-1"
                                    >
                                      {ta.name}
                                    </span>
                                    <br />
                                  </>
                                ))}
                              </td>
                            )}

                            <td>
                              {route.origin_city.country_code} -{" "}
                              {route.origin_city.name}
                            </td>
                            <td>
                              {route.destination_city.country_code} -{" "}
                              {route.destination_city.name}
                            </td>
                            <td>
                              {route.is_legal_document_required == 0
                                ? "No"
                                : "Yes"}
                            </td>
                            <td>
                              {route.route_points.map((rp, idx) => {
                                return (
                                  <span>
                                    {rp.name}
                                    <br></br>
                                  </span>
                                );
                              })}
                            </td>
                            {
                              (checkRolePermissions(
                                "create",
                                moduleName.routePoint
                              ) ||
                                checkRolePermissions(
                                  "delete",
                                  moduleName.travelAgencyRoute
                                )) ? (
                              <td>
                                {checkRolePermissions(
                                  "create",
                                  moduleName.routePoint
                                ) ? (
                                  route?.route_points?.length > 0 ? (
                                    <button
                                      className="btn btn-primary text-white"
                                      onClick={() =>
                                        this.handleUpdateBtnClick(route)
                                      }
                                    >
                                      Configure Route Points
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-info ms-3 text-white"
                                      onClick={() =>
                                        this.handleRoutePoints(route.id)
                                      }
                                    >
                                      Add Route Points
                                    </button>
                                  )
                                ) : null}
                                {checkRolePermissions(
                                  "delete",
                                  moduleName.travelAgencyRoute
                                ) ? (
                                  <button
                                    className="btn btn-danger ms-3"
                                    onClick={(e) =>
                                      this.handleDeleteBtnClick(e, route.id)
                                    }
                                  >
                                    Delete
                                  </button>
                                ) : null}

                                {/* {checkRolePermissions(
                                "update",
                                moduleName.travelAgencyRoute
                              ) ? (
                                <button
                                  className="btn btn-info ms-3"
                                  onClick={() =>
                                    this.handleUpdateBtnClick(route)
                                  }
                                >
                                  Update Route Points
                                </button>
                              ) : null} */}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          
                          colSpan={
                            checkRolePermissions(
                              "create",
                              moduleName.routePoint
                            ) ||
                              checkRolePermissions(
                                "delete",
                                moduleName.travelAgencyRoute
                              )
                              ? 6
                              : 5
                          }
                          className="text-center p-3"
                        >
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Routes found"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.routes.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) => this.getRoutes(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateTravelAgencyRoutes
                getRoutes={this.getRoutes}
                handleCreateModal={this.handleCreateModal}
                cities={this.state.cities}
                roles={roles[0]}
              />
            }
            modalHeader={
              this.state.editMode
                ? "Edit Travel Agency Routes"
                : "Add Travel Agency Routes"
            }
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>

          <CustomModal
            modal={this.state.routePointModal}
            modalBody={
              <CreateRoutePoints
                editMode={this.state.editMode}
                routeId={this.state.selectedRouteId}
                getRoutes={this.getRoutes}
                closeRoutePointModal={this.closeRoutePointModal}
                updateReqData={this.state.updateReqData}
              ></CreateRoutePoints>
            }
            modalHeader={
              this.state.editMode ? "Edit Route Points" : "Add Route Points"
            }
            size="lg"
            toggleModal={this.closeRoutePointModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default TravelAgencyRoutes;
