import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { fileUrl, moduleName, URL } from "../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import CreateTravelAgency from "./CreateTravelAgency";
import { checkRolePermissions } from "../../../utils/Helper";
import Pagination from "react-js-pagination";
import ViewTravelAgencyDetails from "./ViewTravelAgencyDetails";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";

class TravelAgency extends Component {
  state = {
    createModal: false,
    dataSpinner: false,
    deletePermission: false,
    edit: false,
    editPermission: false,
    selectedData: "",
    travelAgencies: [],
    viewDetailsModal: false,
    customersList: [],
    operatorList: [],
  };

  componentDidMount() {
    this.getTravelAgencies();
    this.permissions();
  }

  getTravelAgencies = (pageNumber = 1) => {
    this.setState({ dataSpinner: true, travelAgencies: [] }, function () {
      axiosGet(
        URL.travelAgency + "?" + "page=" + pageNumber,
        (response) => {
          if (response.status === 200) {
            this.setState({
              travelAgencies: response.data.data,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  handleDeleteBtnClick = (id) => {
    displayConfirmDeleteAlert(id, this.handleDelete);
  };

  handleDelete = (id) => {
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosGet(
          URL.deleteTravelAgency + "/" + id,
          (response) => {
            if (response.status === 200) {
              this.setState({
                spinner: false,
              });
              swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
              this.getTravelAgencies();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleEdit = (item) => {
    this.setState({ selectedData: item, edit: true, createModal: true });
  };

  permissions = () => {
    this.setState({
      editPermission: checkRolePermissions("update", moduleName.travelAgency),
      deletePermission: checkRolePermissions("delete", moduleName.travelAgency),
    });
  };

  getAllCustomers = (item, pageNumber) => {
    this.setState(
      {
        loader: true,
        customersList: [],
      },
      () => {
        axiosPost(
          URL.getAllCustomers + "?" + "page=" + pageNumber,
          { travel_agency_id: item?.id || this.state.activeDataId },
          (response) => {
            if (response.status === 200) {
              this.setState({
                loader: false,

                customersList: response.data.data,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
          }
        );
      }
    );
  };

  getAllOperators = (item, pageNumber = 1) => {
    this.setState(
      {
        loader: true,
        operatorList: [],
      },
      () => {
        axiosPost(
          URL.travelChildAgency + "?" + "page=" + pageNumber,
          { travel_agency_id: item?.id || this.state.activeDataId },
          (response) => {
            if (response.status === 200) {
              this.setState({
                loader: false,
                operatorList: response.data.data,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
          }
        );
      }
    );
  };

  handleViewDetails = (item, pageNumber) => {
    this.getAllCustomers(item, pageNumber);
    this.getAllOperators(item, pageNumber);

    this.setState({
      viewDetailsModal: true,
      activeDataId: item.id,
    });
  };

  toggleViewModal = () => {
    this.setState({
      viewDetailsModal: !this.state.viewDetailsModal,
    });
  };

  render() {
    const { current_page, per_page, total } = this.state.travelAgencies;

    return (
      <>
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="dashboardHeader">
          <strong>TRAVEL AGENCIES</strong>
        </div>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.travelAgency) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Travel Agency
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Logo</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Contact Details</th>
                      <th>Email</th>
                      <th>URL</th>
                      {this.state.editPermission ||
                      this.state.deletePermission ? (
                        <th>Options</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.travelAgencies?.data?.length > 0 ? (
                      this.state.travelAgencies?.data?.map((item, idx) => {
                        return (
                          item.is_operator === null && (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>
                                {item.media.length > 0 ? (
                                  <img
                                    src={
                                      fileUrl +
                                      "/" +
                                      item.media[0].id +
                                      "/" +
                                      item.media[0].file_name
                                    }
                                    className="logoDisplay"
                                  />
                                ) : null}
                              </td>
                              <td>{item.name}</td>
                              <td>{item.address}</td>
                              <td>{item.contact_details}</td>
                              <td>{item.email}</td>
                              <td>{item.front_end_url}</td>
                              {this.state.editPermission ||
                              this.state.deletePermission ? (
                                <td>
                                  <button
                                    className="btn btn-primary m-1"
                                    onClick={() => this.handleViewDetails(item)}
                                  >
                                    <i class="fa-solid fa-eye"></i>
                                  </button>

                                  {this.state.editPermission ? (
                                    <button
                                      className="btn btn-primary m-1"
                                      onClick={() => this.handleEdit(item)}
                                    >
                                      <i class="fa-solid fa-pen-to-square"></i>
                                    </button>
                                  ) : null}
                                  {this.state.deletePermission ? (
                                    <button
                                      className="btn btn-danger m-1"
                                      onClick={() =>
                                        this.handleDeleteBtnClick(item.id)
                                      }
                                    >
                                      <i class="fa-solid fa-trash"></i>
                                    </button>
                                  ) : null}
                                </td>
                              ) : null}
                            </tr>
                          )
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            this.state.editPermission ||
                            this.state.deletePermission
                              ? 8
                              : 7
                          }
                          className="text-center p-3"
                        >
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Travel Agency found. Create one first"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.travelAgencies.total <= 10 ? (
                <></>
              ) : (
                <div className="m-4 d-flex justify-content-center">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) =>
                      this.getTravelAgencies(pageNumber)
                    }
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateTravelAgency
                getTravelAgencies={this.getTravelAgencies}
                handleCreateModal={this.handleCreateModal}
                selectedData={this.state.selectedData}
                edit={this.state.edit}
              />
            }
            modalHeader={
              this.state.edit ? "Edit Travel Agency" : "Add Travel Agency"
            }
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>

          <CustomModal
            fullWidth={true}
            modal={this.state.viewDetailsModal}
            modalBody={
              <ViewTravelAgencyDetails
                customersList={this.state.customersList}
                operatorList={this.state.operatorList}
                getAllCustomers={this.getAllCustomers}
                getAllOperators={this.getAllOperators}
                loader={this.state.loader}
              />
            }
            modalHeader="Details"
            size="lg"
            toggleModal={this.toggleViewModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default TravelAgency;
