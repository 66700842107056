import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { cryptoSecretKey, loginUrl } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import { useNavigate, useLocation } from "react-router-dom";
import LoginPageNewUI from "./LoginPageNewUI";

var CryptoJS = require("crypto-js");

function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();

  // const { location } = this.props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [toggleSwitch, setToggleSwitch] = useState(false);

  const handleCancel = () => {
    setEmail("");
    setPassword("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    if (email !== "" && password !== "") {
      let param = {
        email: email,
        password: password,
      };
      setLoader(true);

      axiosPost(
        loginUrl,
        param,
        (response) => {
          if (response.status === 200) {
            let datum = response.data.data;
            localStorage.setItem("token", datum.token);
            let permissionEncrypt = null;

            if (datum.userWisePermission.length > 0) {
              permissionEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(datum.userWisePermission),
                cryptoSecretKey
              ).toString();
            } else {
              permissionEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(datum.permissions),
                cryptoSecretKey
              ).toString();
            }

            localStorage.setItem("permissions", permissionEncrypt);

            var rolesEncrypt = CryptoJS.AES.encrypt(
              JSON.stringify(datum.roles),
              cryptoSecretKey
            ).toString();
            console.log("roles", datum.roles);
            localStorage.setItem("roles", rolesEncrypt);

            if (datum.travel_agency) {
              var ciphertext = CryptoJS.AES.encrypt(
                JSON.stringify(datum.travel_agency),
                cryptoSecretKey
              ).toString();
              localStorage.setItem("travelAgencyData", ciphertext);
            }

            if (datum.roles[0] === "Operator") {
              if (datum.travel_agency.hasOwnProperty("media")) {
                let imgEncrypt = CryptoJS.AES.encrypt(
                  JSON.stringify(datum.travel_agency.media),
                  cryptoSecretKey
                ).toString();
                localStorage.setItem("profileImg", imgEncrypt);
              }

              let usernameEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(datum.travel_agency.name),
                cryptoSecretKey
              ).toString();

              localStorage.setItem("loggedInUser", usernameEncrypt);

              navigate(`${process.env.PUBLIC_URL}/`);
            } else if (datum.roles[0] === "Registered Customer") {
              let emailEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(datum.email),
                cryptoSecretKey
              ).toString();
              localStorage.setItem("loggedInUser", emailEncrypt);
              navigate(`${process.env.PUBLIC_URL}/`);
            } else {
              navigate(`${process.env.PUBLIC_URL}/admin/dashboard`);

              let emailEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(datum.email),
                cryptoSecretKey
              ).toString();
              localStorage.setItem("loggedInUser", emailEncrypt);
            }

            // console.log(`${process.env.PUBLIC_URL}/admin/dashboard`);
            // console.log(<Redirect to="/" />);

            // console.log(props.history);
            // history.push("/admin/dashboard");
          }
        },
        (err) => {
          displayErrorAlert(err);
          setLoader(false);
        }
      );
    } else {
      swal("Error", "Please enter Email and Password both", "error");
    }
  };

  const goToHomePage = () => {
    navigate(`${process.env.PUBLIC_URL}/`);
  };

  const toggleSwitchChange = (e) => {
    let value = e.target.checked;
    setToggleSwitch(value);
  };

  useEffect(() => {
    if (location.state?.register) {
      setToggleSwitch(true);
    }
  }, []);

  const goToLogin = () => {
    setToggleSwitch(false);
  };

  const registerProcessStart = () => {
    setLoader(true);
  };
  const registerProcessComplete = () => {
    setLoader(false);
  };

  return (
    <LoginPageNewUI
      registerProcessStart={registerProcessStart}
      registerProcessComplete={registerProcessComplete}
      email={email}
      goToHomePage={goToHomePage}
      handleCancel={handleCancel}
      handleEmailChange={handleEmailChange}
      handleLogin={handleLogin}
      handlePasswordChange={handlePasswordChange}
      loader={loader}
      password={password}
      toggleSwitch={toggleSwitch}
      toggleSwitchChange={toggleSwitchChange}
      goToLogin={goToLogin}
    />
  );
}

export default LoginPage;
