import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import swal from "sweetalert";
import { axiosPost } from "../../../../utils/AxiosApi";
import { fileUrl, URL as URLs } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { linkValidate } from "../../../../utils/validation";
import { validateImage } from "../../../../utils/Helper";

class CreateCmsBlog extends Component {
  state = {
    description: "",
    featuredImage: "",
    featuredImageFile: "",
    hasLink: false,
    imagePreview: "",
    link: "",
    spinner: false,
    subtitle: "",
    title: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setUpdateConfig();
    }
  }

  handleCancel = () => {
    this.setState({
      description: "",
      featuredImage: "",
      featuredImageFile: "",
      hasLink: false,
      imagePreview: "",
      link: "",
      subtitle: "",
      title: "",
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "hasLink") {
        if (this.state.hasLink) {
          this.setState({ description: "" });
        } else {
          this.setState({ link: "" });
        }
      }
    });
  };

  handleDescriptionChange = (event, editor) => {
    this.setState({
      description: editor.getData(),
    });
  };

  handleImageChange = (e) => {
    if (validateImage(e.target.files[0])) {
      this.setState(
        {
          featuredImage: e.target.value,
          featuredImageFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.featuredImageFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleSave = () => {
    let param = {
      title: this.state.title,
      subtitle: this.state.subtitle,
      link: this.state.link,
      description: this.state.description,
    };
    let formData = new FormData();
    formData.append("blog", JSON.stringify(param));
    formData.append("file", this.state.featuredImageFile);

    if (this.state.title === "" || this.state.featuredImageFile === "") {
      swal("Warning", "Title and image cannot be empty", "warning");
    } else {
      let error = false;

      if (this.state.hasLink && !linkValidate(this.state.link)) {
        error = true;
      }

      if (!error) {
        this.setState({ spinner: true }, function () {
          axiosPost(
            URLs.blogs,
            formData,
            (response) => {
              this.setState({ spinner: false });
              if (response.status === 200) {
                swal("Success", DISPLAYMESSAGE.createSuccess, "success");
                this.props.getBlogs();
                this.props.handleCreateModal();
              }
            },
            (err) => {
              this.setState({ spinner: false });
              displayErrorAlert(err);
            }
          );
        });
      }
    }
  };

  handleUpdate = () => {
    let param = {
      id: this.props.selectedData.id,
      title: this.state.title,
      subtitle: this.state.subtitle,
      link: this.state.link,
      description: this.state.description,
    };
    let formData = new FormData();
    formData.append("blog", JSON.stringify(param));
    if (this.state.featuredImageFile !== "") {
      formData.append("file", this.state.featuredImageFile);
    }
    if (this.state.title === "" || this.state.imagePreview === "") {
      swal("Warning", "Title and image cannot be empty", "warning");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URLs.updateBlog,
          formData,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.props.getBlogs();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  removeImage = () => {
    this.setState({
      featuredImage: "",
      imagePreview: "",
      featuredImageFile: "",
    });
  };

  setUpdateConfig = () => {
    let blog = this.props.selectedData;
    if (blog) {
      if (blog.link !== "") {
        this.setState({ hasLink: true });
      }
      this.setState({
        title: blog.title,
        subtitle: blog.subtitle,
        link: blog.link,
        description: blog.description,
        imagePreview:
          blog.media.length > 0
            ? fileUrl + "/" + blog.media[0].id + "/" + blog.media[0].file_name
            : null,
      });
    }
  };

  render() {
    return (
      <div className="container cms-blog">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <strong>Title</strong>
        <input
          className="form-control"
          name="title"
          onChange={this.handleChange}
          placeholder="Enter your title here"
          type="text"
          value={this.state.title}
        />
        <div className="mt-2">
          <strong>Subtitle</strong>
          <input
            className="form-control"
            name="subtitle"
            onChange={this.handleChange}
            placeholder="Enter your subtitle here"
            type="text"
            value={this.state.subtitle}
          />
        </div>
        <div className="mt-2">
          <FormControlLabel
            control={
              <Switch
                checked={this.state.hasLink}
                onChange={this.handleChange}
                color="primary"
                name="hasLink"
              />
            }
            label="Do you have link?"
          />
        </div>
        {this.state.hasLink ? (
          <input
            className="form-control"
            name="link"
            onChange={this.handleChange}
            placeholder="Enter your link here"
            type="text"
            value={this.state.link}
          />
        ) : (
          <CKEditor
            editor={ClassicEditor}
            data={
              this.props.edit
                ? this.props.selectedData.description
                : this.state.description
            }
            onChange={(event, editor) =>
              this.handleDescriptionChange(event, editor)
            }
            config={{
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "indent",
                  "outdent",
                  "|",

                  "blockQuote",
                  "undo",
                  "redo",
                ],
              },
            }}
          />
        )}
        <div className="mt-2">
          <strong>Featured Image</strong>
          <div className="row">
            <div className="col">
              {this.props.edit &&
              this.state.imagePreview &&
              this.state.featuredImage === "" ? (
                <input
                  className="form-control"
                  type="text"
                  value={this.props.selectedData.media[0].name}
                  disabled
                />
              ) : (
                <input
                  accept="image/*"
                  className="form-control"
                  name="feaeturedImage"
                  onChange={this.handleImageChange}
                  type="file"
                  value={this.state.featuredImage}
                />
              )}
            </div>
            {this.state.imagePreview !== "" ? (
              <div className="col-md-auto">
                <button
                  className="btn btn-danger ml-3"
                  onClick={this.removeImage}
                >
                  Remove
                </button>
              </div>
            ) : null}
          </div>
        </div>
        {this.state.imagePreview !== "" ? (
          <div className="row mt-2 mb-2">
            <div className="col text-center">
              <div className="imageHolder" style={{ border: "1px solid #ccc" }}>
                <img
                  src={this.state.imagePreview}
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Create
              </button>
            )}

            <button className="btn btn-danger ms-2" onClick={this.handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCmsBlog;
