import React from "react";
import { Outlet, Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const auth = useAuth();

  return auth ? children : <Navigate to="/login/" replace />;
  // return <>{auth ? <Outlet /> : <Navigate to="/login" />}</>;
}

function useAuth() {
  let token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
}

export default PrivateRoute;
