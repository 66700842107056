import axios from "axios";
import React, { Component } from "react";
import { axiosGet, axiosGetWeb } from "../../../utils/AxiosApi";
import { keyHeaders, URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import FooterUI from "./FooterUI";

class Footer extends Component {
  state = {
    topRoutes: [],
  };

  getTopRoutes = () => {
    axiosGetWeb(
      URL.busTopRoutes,
      (response) => {
        if (response.status === 200) {
          this.setState({
            topRoutes: response.data.data.items[0],
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  componentDidMount() {
    this.getTopRoutes();
  }

  render() {
    return <FooterUI topRoutes={this.state.topRoutes}></FooterUI>;
  }
}

export default Footer;
