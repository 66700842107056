import React, { Component } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import AboutUs from "../Pages/Website/AboutUs/AboutUs";
import HomePage from "../Pages/Website/HomePage/HomePage";
import RoutesList from "../Pages/Website/RoutesList/RoutesList";
import Main from "../Pages/Website/Main";
import NotFound from "../components/WebsiteComponents/NotFound";
import DashboardRoutes from "./DashboardRoutes";
import LoginPage from "../Pages/Dashboard/LoginPage/LoginPage";
import { axiosGet } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";
import { displayErrorAlert } from "../utils/DisplayMessage";
import Signup from "../Pages/Website/Register/Signup";
import BookingDetails from "../Pages/Website/BookingDetails";
import PaymentReciept from "../Pages/Website/PaymentReciept";
import MyTickets from "../Pages/Website/MyTickets/MyTickets";
import FAQs from "../Pages/Website/FAQs/FAQs";
import PrivacyPolicy from "../Pages/Website/PrivacyPolicy/PrivacyPolicy";
import Blog from "../Pages/Website/Blog/Blog";
import TermsAndConditions from "../Pages/Website/TermsAndConditions/TermsAndConditions";
import SingleBlog from "../Pages/Website/Blog/SingleBlog";
import Contact from "../Pages/Website/ContactUs/Contact";
import PrivateRoute from "./PrivateRoute";
import ViewTravelAgencyDetails from "../Pages/Dashboard/TravelAgency/ViewTravelAgencyDetails";
import { getRolesFromStorage } from "../utils/Helper";
import ManualBooking from "../components/DashboardComponents/ManualBooking";

class Routes extends Component {
  state = {
    topRoutes: [],
  };

  dashbaordRoutes = () => {
    let roles = getRolesFromStorage();
    let token = localStorage.getItem("token");

    if (
      token &&
      roles[0] !== "Registered Customer" &&
      roles[0] !== "Operator"
    ) {
      return <DashboardRoutes />;
    } else {
      return <Main Component={<NotFound />} />;
    }
  };

  render() {
    return (
      //  Define Route and path here (Website, Dashboard route is in DashboardRoutes.js)
      <>
        {/* <WhatsAppWidget phoneNumber="9779801087344" /> */}
        <Switch>
          <Route
            path="/my-tickets"
            element={
              <PrivateRoute>
                <Main Component={<MyTickets />} />
              </PrivateRoute>
            }
          />

          <Route path="/view" element={<ViewTravelAgencyDetails />} />

          <Route path="admin/*" element={this.dashbaordRoutes()}></Route>
          <Route path="/" element={<Main Component={<HomePage />} />} />
          <Route path="about" element={<Main Component={<AboutUs />} />} />

          <Route
            path="privacy-policy"
            element={<Main Component={<PrivacyPolicy />} />}
          />
          <Route path="blogs" element={<Main Component={<Blog />} />} />

          <Route
            path="terms-and-conditions"
            element={<Main Component={<TermsAndConditions />} />}
          />

          <Route
            path="RoutesList"
            element={<Main Component={<RoutesList />} />}
          />

          <Route
            path="/bookingDetails"
            element={<Main Component={<BookingDetails />} />}
          />

          <Route
            path="/payment-receipt"
            element={<Main Component={<PaymentReciept />} />}
          />

          <Route path="/login" element={<LoginPage />} />
          {/* For admin Dashboard */}
          {/* For random routes */}
          <Route path="*" element={<Main Component={<NotFound />} />} />

          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/faq" element={<Main Component={<FAQs />} />}></Route>

          <Route
            path="/single-blog"
            element={<Main Component={<SingleBlog />} />}
          ></Route>

          <Route
            path="/contact-us"
            element={<Main Component={<Contact />} />}
          ></Route>
          {/* <Route path="/manual-booking" element={<ManualBooking />} /> */}
        </Switch>
      </>
    );
  }
}

export default Routes;
