import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { Spinner } from "reactstrap";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import TicketReceipt from "../../Website/MyTickets/TicketReceipt";
import TicketContainer from "../TravelAgency/TicketContainer";
import { getRolesFromStorage } from "../../../utils/Helper";

const ListCustomer = () => {
  const [customers, setCustomers] = useState([]);

  const [editPermission, setEditPErmission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [dataSpinner, setDataSpinner] = useState(false);

  const [ticketsList, setTicketsList] = useState(false);
  const [ticketsListData, setTicketsListData] = useState([]);

  const [ativeCustomerID, setActiveCustomerID] = useState("");

  useEffect(() => {
    getAllCustomers();
  }, []);

  const getAllCustomers = (pageNumber = 1) => {
    setCustomers([]);
    setDataSpinner(true);
    //required to change the api endPoint
    axiosPost(
      URL.getAllCustomers + "?" + "page=" + pageNumber,
      {},
      (response) => {
        if (response.status === 200) {
          setCustomers(response.data.data);
          setDataSpinner(false);
        }
      },
      (err) => {
        displayErrorAlert(err);
        setDataSpinner(false);
      }
    );
  };

  const handleViewDetails = (item) => {
    axiosPost(
      URL.viewAllTickets,
      { customer_id: item ? item.id : ativeCustomerID },
      (response) => {
        setTicketsListData(response.data.data.items);
        setTicketsList(true);

        setActiveCustomerID(item ? item.id : ativeCustomerID);
      }
    );
  };
  const { current_page, per_page, total } = customers;
  const roles = getRolesFromStorage();
  return (
    <div>
      <div className="dashboardHeader">
        <strong>Customers</strong>
      </div>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>S.N</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact No.</th>
                    <th>Address</th>
                    <th>Options</th>
                    {/* {editPermission ||
                    deletePermission ? (
                      <th>Options</th>
                    ) : null} */}
                  </tr>
                </thead>
                {/* <tbody>
                  <tr>
                  <td>let s</td>
                  <td>let s</td>
                    <td>let s</td>
                    <td>let s</td>
                    <td>let s</td>
                    <td>let s</td>
                    </tr>
                </tbody> */}

                <tbody>
                  {customers?.data?.length > 0 ? (
                    customers?.data?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>

                          <td>
                            {item.first_name + " " + item.last_name}
                            <br />
                            {roles[0] === "Network Admin" && (
                              <span className="badge rounded-pill bg-info text-dark text-white">
                                {item.user?.travel_agency.name}
                              </span>
                            )}
                          </td>

                          <td>{item.email}</td>
                          <td>{item.phone_number}</td>
                          <td>{item.address}</td>
                          <td>
                            <button
                              className="btn btn-info ms-3"
                              onClick={() => handleViewDetails(item)}
                            >
                              View Tickets
                            </button>
                          </td>
                          {/* {editPermission ||
                          deletePermission ? (
                              <td>
                              {editPermission ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => this.handleEdit(item)}
                                  >
                                  Edit
                                  </button>
                                  ) : null}
                                  {deletePermission ? (
                                      <button
                                      className="btn btn-danger ms-3"
                                      onClick={() =>
                                        this.handleDeleteBtnClick(item.id)
                                    }
                                    >
                                    Delete
                                    </button>
                                    ) : null}
                                    </td>
                                ) : null} */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={editPermission || deletePermission ? 7 : 6}
                        className="text-center p-3"
                      >
                        {dataSpinner ? (
                          <>
                            <Spinner color="primary"></Spinner>
                            <br />
                            Loading Data...
                          </>
                        ) : (
                          "No Customers found. Create one first"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {customers?.total <= 10 ? (
              <></>
            ) : (
              <div className="paginationContainer">
                <Pagination
                  activePage={current_page}
                  itemsCountPerPage={per_page}
                  totalItemsCount={total}
                  onChange={(pageNumber) => getAllCustomers(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        fullWidth={true}
        modal={ticketsList}
        modalBody={
          <TicketContainer
            ticketsListData={ticketsListData}
            handleViewDetails={handleViewDetails}
          />
        }
        modalHeader="Details"
        size="lg"
        toggleModal={() => setTicketsList(!ticketsList)}
      ></CustomModal>
    </div>
  );
};

export default ListCustomer;
