import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
// import RegisterPageUI from "./RegisterPageUI";
import Signup from "../../Website/Register/Signup";

function LoginPageNewUI(props) {
  const [capsOn, setCapsOn] = useState(false);
  const [inputType, setInputType] = useState("password");
  // const [toggleSwitch, setToggleSwitch] = useState(false);

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      props.handleLogin();
    }
    if (e.getModifierState("CapsLock")) {
      setCapsOn(true);
    } else {
      setCapsOn(false);
    }
  };
  const handleInputTypeChange = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };
  // const handlePageChange = (e) => {
  //   let value = e.target.checked;
  //   // setToggleSwitch(value);
  // };

  const handleLogoClick = () => {
    props.goToHomePage();
  };

  return (
    <div className="login-ui">
      {props.loader ? (
        <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
      ) : null}
      <div className="row row1">
        <div className="new_login_box">
          <img src={logo} alt="" onClick={handleLogoClick} />
          <div id="formContent">
            <div className="toggle-row">
              {props.toggleSwitch ? (
                <div>
                  <h2>
                    Create an account
                    {/* <br /> */}
                    {/* <h6>to continue booking</h6> */}
                  </h2>
                </div>
              ) : (
                <h2>
                  Sign in
                  {/* <br />
                  <h6>to continue booking</h6> */}
                </h2>
              )}
              <div className="toggle-container text-end">
                <input
                  type="checkbox"
                  id="toggle"
                  name="toggleBtn"
                  checked={props.toggleSwitch}
                  onChange={props.toggleSwitchChange}
                />
                <label htmlFor="toggle"></label>
              </div>
            </div>
            {props.toggleSwitch ? (
              <Signup
                registerProcessStart={props.registerProcessStart}
                registerProcessComplete={props.registerProcessComplete}
                goToHomePage={props.goToHomePage}
                goToLogin={props.goToLogin}
              />
            ) : (
              <>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={props.email}
                  onChange={props.handleEmailChange}
                  placeholder="Enter your email"
                />
                <div style={{ position: "relative" }}>
                  <input
                    type={inputType}
                    className="form-control"
                    name="password"
                    value={props.password}
                    onChange={props.handlePasswordChange}
                    onKeyDown={handleKeydown}
                    placeholder="Enter your password"
                  />
                  <FontAwesomeIcon
                    icon={inputType === "password" ? faEye : faEyeSlash}
                    style={{
                      position: "absolute",
                      right: "55px",
                      bottom: "26%",
                      cursor: "pointer",
                    }}
                    onClick={handleInputTypeChange}
                  />
                </div>
                {capsOn ? (
                  <div className="row">
                    <div className="col text-center">
                      <strong>Caps Lock is On</strong>
                    </div>
                  </div>
                ) : null}
                <div className="m-4">
                  <button className="login-btn" onClick={props.handleLogin}>
                    Login
                  </button>
                  <a href="" onClick={props.goToHomePage}>
                    Return to Home Page
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPageNewUI;
