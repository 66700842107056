import React, { Component } from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import DashboardTemplate from "../Pages/Dashboard/DashboardTemplate";
import LoginPage from "../Pages/Dashboard/LoginPage/LoginPage";
import PrivateRoute from "./PrivateRoute";

class Admin extends Component {
  render() {
    return (
      //Routes for Dashboard will be here
      <div>
        <Switch>
          <Route
            path="/"
            element={
              <LoginPath>
                <LoginPage />
              </LoginPath>
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <DashboardTemplate />
              </PrivateRoute>
            }
          />
        </Switch>
      </div>
    );
  }
}

function LoginPath({ children }) {
  let token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/admin/dashboard" replace />;
  } else {
    return children;
  }
}

export default Admin;
