import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";

class CustomCollapse extends Component {
  render() {
    return (
      <Collapse isOpen={this.props.isOpen} className={this.props.collapseClass}>
        <Card>
          <CardBody>{this.props.collapsibleBody}</CardBody>
        </Card>
      </Collapse>
    );
  }
}

export default CustomCollapse;
