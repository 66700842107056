import React, { useEffect, useState } from "react";
import "./contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLocationArrow,
  faMailBulk,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { axiosGetWeb, axiosPostWeb } from "../../../utils/AxiosApi";
import swal from "sweetalert";
import { URL } from "../../../utils/Constants";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { emailValidate } from "../../../utils/validation";
import { displayErrorAlert } from "../../../utils/DisplayMessage";

const Contact = () => {
  const [contactData, setContactData] = useState({});

  const [spinner, setSpinner] = useState(false);
  const [travelAgencyData, setTravelAgencyData] = useState(null);

  const handleChangeInput = (e) => {
    setContactData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSendMessage = () => {
    let postData = {
      firstName: contactData.fname,
      lastName: contactData.lname,
      email: contactData.email,
      phone: contactData.phone,
      message: contactData.message,
    };
    if (contactData.email !== "") {
      if (emailValidate(contactData.email)) {
        setSpinner(true);
        axiosPostWeb(
          URL.postContactData,
          postData,
          (response) => {
            if (response.status === 200) {
              setSpinner(false);
              setContactData({
                fname: "",
                lname: "",
                email: "",
                message: "",
                phone: "",
              });
              swal(
                "Success",
                "Your message is successfully sent. We will get back to you soon.",
                "success"
              );
            }
          },
          (error) => {
            displayErrorAlert(error);
            setSpinner(false);
          }
        );
      }
    } else {
      swal("Warning", "Email field cannot be empty.", "warning");
      return;
    }
  };

  useEffect(() => {
    axiosGetWeb(URL.getWebsiteTravelAgency, (response) => {
      if (response.status === 200) {
        setTravelAgencyData(response.data?.data?.item);
      }
    });
  }, []);

  return (
    <div>
      {spinner ? (
        <CustomFullSpinner text="Please wait while your request is processing...." />
      ) : (
        ""
      )}
      <div className="my-tickets-title-box text-center">
        <div className="banner-text">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className="contact-container">
        <div className="contact-head text-center m-4">
          <h5>Any queries or remarks? Just write us a message</h5>
        </div>
        <div className="row contact-box">
          <div className="col-md-4 contact-info">
            <h3>Contact Information</h3>
            <p>Fill up the form and our team will get back to you soon</p>
            <div>
              <div className="contact-icon-text">
                <span>
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <span>{travelAgencyData?.contact_details}</span>
              </div>
              <div className="contact-icon-text">
                <span>
                  <FontAwesomeIcon icon={faMailBulk} />
                </span>
                <span>{travelAgencyData?.email}</span>
              </div>
              <div className="contact-icon-text">
                <span>
                  <FontAwesomeIcon icon={faLocationArrow} />
                </span>
                <span>{travelAgencyData?.address}</span>
              </div>
            </div>

            <div className="contact-social-icons">
              {travelAgencyData?.social_link?.length > 0 &&
                travelAgencyData?.social_link?.map((i) => (
                  <span>
                    <a href={i.link} target="_blank" rel="noreferrer">
                      <i className={i.icon}></i>
                    </a>
                  </span>
                ))}
            </div>
          </div>
          <div className="col-md-8 contact-inputs mt-4">
            <div className="contact-inputs-box">
              <div className="flex-column">
                <label for="fname">First Name</label>
                <input
                  name="fname"
                  type="text"
                  placeholder="First Name"
                  className="form-control"
                  value={contactData.fname}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="flex-column">
                <label for="lname">Last Name</label>
                <input
                  name="lname"
                  type="text"
                  placeholder="Last Name"
                  className="form-control"
                  value={contactData.lname}
                  onChange={handleChangeInput}
                />
              </div>
            </div>
            <div className="contact-inputs-box">
              <div className="flex-column">
                <label for="fname">E-mail</label>
                <input
                  name="email"
                  type="text"
                  placeholder="Email"
                  className="form-control"
                  value={contactData.email}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="flex-column">
                <label for="lname">Phone</label>
                <input
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  className="form-control"
                  value={contactData.phone}
                  onChange={handleChangeInput}
                />
              </div>
            </div>
            <div className="contact-msg">
              <label for="fname">Message</label>

              <textarea
                name="message"
                className="form-control"
                value={contactData.message}
                onChange={handleChangeInput}
                placeholder="Message"
              ></textarea>
            </div>
            <div className="contact-btn text-end">
              <button onClick={handleSendMessage}>Send Message</button>
            </div>
          </div>
        </div>
      </div>
      {travelAgencyData?.map !== null && (
        <div
          className="map-container"
          // dangerouslySetInnerHTML={{ __html: travelAgencyData?.map }}
        >
          <iframe
            src={travelAgencyData?.map}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Contact;
