import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";

class CreateRoutePoints extends Component {
  state = {
    routePoints: [
      {
        route_id: this.props.routeId,
        name: "",
        is_boarding_point: false,
        is_dropping_point: false,
      },
    ],
    spinner: false,
  };

  addRow = () => {
    this.setState({
      routePoints: [
        ...this.state.routePoints,
        {
          route_id: this.props.routeId,
          name: "",
          is_boarding_point: false,
          is_dropping_point: false,
        },
      ],
    });
  };

  createRoutePoints = () => {
    let checkData = this.state.routePoints;
    var error = 0;
    for (const chk of checkData) {
      if (chk.name === "") {
        error++;
      }
    }

    let params = this.state.routePoints;
    params.forEach((rp) => {
      rp.is_boarding_point = rp.is_boarding_point ? 1 : 0;
      rp.is_dropping_point = rp.is_dropping_point ? 1 : 0;
    });

    if (error == 0) {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.insertRoutePoints,
          params,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.setState({ spinner: false });
              this.props.getRoutes();
              this.props.closeRoutePointModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ spinner: false });
          }
        );
      });
    } else {
      swal("Warning", "Route point cannot be empty.", "warning");
    }
  };

  updateRoutePoints = () => {
    // let params = this.state.routePoints;

    // var error = 0;
    // for (const chk of params) {
    //   if (chk.name === "") {
    //     error++;
    //   }
    // }
    // if (error == 0) {
    //   this.setState(
    //     {
    //       spinner: true,
    //     },
    //     () => {
    //       axiosPost(
    //         URL.updateRoutePoints,
    //         params,
    //         (response) => {
    //           if (response.status === 200) {
    //             swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
    //             this.setState({ spinner: false });
    //             this.props.getRoutes();
    //             this.props.closeRoutePointModal();
    //           }
    //         },
    //         (err) => {
    //           displayErrorAlert(err);
    //           this.setState({ spinner: false });
    //         }
    //       );
    //     }
    //   );
    // } else {
    //   swal("Warning", "Route point cannot be empty.", "warning");
    // }

    let params = this.state.routePoints;
    params.forEach((rp) => {
      rp.is_boarding_point = rp.is_boarding_point ? 1 : 0;
      rp.is_dropping_point = rp.is_dropping_point ? 1 : 0;
    });
    const invalidRoutePoints = params.filter((point) => point.name === "");

    if (invalidRoutePoints.length > 0) {
      swal("Warning", "Route point name cannot be empty.", "warning");
      return;
    }

    this.setState({ spinner: true });
    axiosPost(
      URL.updateRoutePoints,
      params,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
          this.setState({ spinner: false });
          this.props.getRoutes();
          this.props.closeRoutePointModal();
        }
      },
      (err) => {
        displayErrorAlert(err);
        this.setState({ spinner: false });
      }
    );
  };

  deleteRow = (idx) => {
    let routePoints = [...this.state.routePoints];
    routePoints.splice(idx, 1);
    this.setState({ routePoints });
  };

  handleCancel = () => {
    this.setState({
      routePoints: [
        {
          route_id: this.props.routeId,
          name: "",
          is_boarding_point: false,
          is_dropping_point: false,
        },
      ],
    });
    this.props.closeRoutePointModal();
  };

  handleChange = (e, idx) => {
    let { name } = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let routePoints = [...this.state.routePoints];
    routePoints[idx][name] = value;
    this.setState({ routePoints });
  };

  updateConfig = () => {
    const { updateReqData, routeId } = this.props;
    const configRoutePoints = updateReqData?.route_points;
    const newState = configRoutePoints.map((item) => ({
      route_id: routeId,
      name: item.name,
      is_boarding_point: item.pivot
        ? item.pivot.is_boarding_point
          ? true
          : false
        : item.is_boarding_point
        ? true
        : false,
      is_dropping_point: item.pvot
        ? item.pivot.is_dropping_point
          ? true
          : false
        : item.is_dropping_point
        ? true
        : false,
    }));

    this.setState({
      configRoutePoints,
      routePoints: newState,
    });
  };

  componentDidMount = () => {
    if (this.props.updateReqData !== "") {
      this.updateConfig();
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ...."></CustomFullSpinner>
        ) : null}
        <div className="routeMainHolder">
          <div className="container-fluid">
            {this.state.routePoints.map((rp, idx) => {
              return (
                <div className="row createRouteHolder" key={idx}>
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-2 mt-1">
                        <strong>Name</strong>
                      </div>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={rp.name}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-2"></div>
                      <div className="col">
                        <div className="row">
                          <div className="col-md-auto">
                            <input
                              type="checkbox"
                              id={"bp" + idx}
                              className="form-check-input"
                              name="is_boarding_point"
                              checked={rp.is_boarding_point}
                              onChange={(e) => this.handleChange(e, idx)}
                            />
                          </div>
                          <div className="col">
                            <label
                              htmlFor={"bp" + idx}
                              className="form-check-label"
                            >
                              <strong>Boarding Point</strong>
                            </label>
                          </div>
                          <div className="col-md-auto">
                            <input
                              type="checkbox"
                              id={"dp" + idx}
                              name="is_dropping_point"
                              className="form-check-input"
                              checked={rp.is_dropping_point}
                              onChange={(e) => this.handleChange(e, idx)}
                            />
                          </div>
                          <div className="col">
                            <label
                              htmlFor={"dp" + idx}
                              className="form-check-label"
                            >
                              <strong>Dropping Point</strong>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 pe-0">
                    {idx === this.state.routePoints.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        onClick={this.addRow}
                        style={{ marginRight: "5px", color: "#0b5ed7" }}
                      />
                    ) : null}
                    {idx == 0 ? null : (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        onClick={() => this.deleteRow(idx)}
                        style={{ color: "#bb2d3b" }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.editMode ? (
              <button
                className="btn btn-primary"
                onClick={this.updateRoutePoints}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={this.createRoutePoints}
              >
                Create
              </button>
            )}
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateRoutePoints;
