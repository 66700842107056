import React, { Component } from "react";
import swal from "sweetalert";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { validateGoogleMapsEmbedURL } from "../../../utils/Helper";

export class MapData extends Component {
  state = {
    mapsIframe: "",
  };

  handleChange = (e, idx) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.getMapData();
  }
  getMapData = () => {
    axiosGet(URL.getMap, (response) => {
      let resData = response.data?.data?.item;

      if (resData && resData?.map !== null) {
        this.setState({
          mapsIframe: resData.map,
        });
      }
    });
  };

  handleSubmit = () => {
    if (validateGoogleMapsEmbedURL(this.state.mapsIframe)) {
      let formData = new FormData();

      let mapData = {
        map: this.state.mapsIframe,
      };

      formData.append("travel_agency", JSON.stringify(mapData));
      axiosPost(URL.updateTravelAgency, formData, (response) => {
        if (response.status === 200) {
          swal("Success", "Map data added successfully");
        }
        this.getMapData();
      });
    } else {
      swal("Error", "Map src is not valid");
    }
  };

  render() {
    return (
      <div className="contact-dashboard">
        {" "}
        <div className="dashboardHeader">
          <strong>Maps</strong>
        </div>
        <div className="container mt-4">
          <div className="row">
            <label htmlFor="socialIcons">Maps</label>

            <div className="col-md-6">
              <input
                type="text"
                name="mapsIframe"
                className="form-control"
                placeholder="Input map iframe"
                onChange={(e) => this.handleChange(e)}
                value={this.state.mapsIframe}
              />
              <small>Note: Please add only the src of the map</small>
            </div>
          </div>
          <button
            disabled={this.state.mapsIframe === ""}
            className="btn btn-primary mt-4 mb-4"
            onClick={this.handleSubmit}
          >
            Save
          </button>
          {this.state.mapsIframe !== "" &&
          validateGoogleMapsEmbedURL(this.state.mapsIframe) ? (
            <div className="map-container">
              <iframe
                src={this.state.mapsIframe}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          ) : (
            <div className="container">
              {this.state.mapsIframe !== "" && "Invalid maps data"}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MapData;
