import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { getRolesFromStorage } from "../../../utils/Helper";

const CanceledTickets = () => {
  const [canceledTickets, setCanceledTickets] = useState([]);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    axiosPost(URL.viewAllTickets, {}, (response) => {
      if (response) {
        setCanceledTickets(
          response.data.data.items.filter((d) => d.cancellation_status)
        );
      }
    });
  }, []);

  const handleClickTicket = () => {};

  const roles = getRolesFromStorage();

  return (
    <div>
      <div className="dashboardHeader">
        <strong>Canceled Tickets</strong>
      </div>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Bus Info</th>
                    <th>Boarding Points</th>

                    <th>Booked Seats</th>
                    <th>Total Fare</th>
                    <th>Customer Detail</th>
                    <th>Invoice Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {canceledTickets && canceledTickets?.length > 0 ? (
                    canceledTickets?.map((ticket, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="text-center">{idx + 1}</td>
                          <td>
                            <strong>{ticket.bus?.name}</strong>
                            <br />
                            {roles[0] === "Network Admin" && (
                              <span className="badge rounded-pill bg-info text-dark text-white">
                                {ticket.travel_agency.name}
                              </span>
                            )}
                            <br />
                            <strong>No. Plate: </strong>
                            {ticket.bus?.number_plate}
                            <br />
                            <strong>Route: </strong>{" "}
                            {ticket?.origin[0]?.country_code} -{" "}
                            {ticket?.destination[0]?.country_code}
                          </td>
                          <td>
                            <strong> Pickup point:</strong>
                            <br />
                            {ticket?.pick_up_point?.name != null
                              ? ticket?.pick_up_point?.name
                              : "-"}
                            <br />
                            <strong>Dropping point:</strong>
                            <br />
                            {ticket?.dropping_point?.name != null
                              ? ticket?.dropping_point?.name
                              : "-"}
                          </td>
                          <td>
                            {ticket.bus_seat_booking.map((datum, idx) => {
                              return (
                                <div key={idx}>
                                  {datum.seatName}
                                  {(datum.seat_booked_by || datum.remark) &&
                                    (datum.seat_booked_by || datum.remark)}
                                </div>
                              );
                            })}
                            {ticket.remark && (
                              <div className="mt-2 border p-2">
                                <b>Remarks</b>
                                <p>{ticket.remark}</p>
                              </div>
                            )}
                          </td>
                          <td>{ticket?.fare}</td>
                          <td>
                            <strong>Name: </strong>
                            {ticket.bus_booking_customer?.full_name}
                            <br />
                            <strong>Email: </strong>
                            {ticket.bus_booking_customer?.email}
                            <br />
                            <strong>Contact: </strong>
                            {ticket.bus_booking_customer?.phone_number}
                            <br />
                          </td>
                          <td>{ticket?.invoice_number}</td>
                          <td>
                            <button
                              className="btn btn-info"
                              onClick={() => handleClickTicket(ticket.media)}
                            >
                              View Receipt
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={9}>
                        {spinner ? (
                          <Spinner></Spinner>
                        ) : (
                          "You have not booked any tickets yet"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {canceledTickets?.total <= 10 ? (
              <></>
            ) : (
              <div className="paginationContainer">
                {/* <Pagination
                  activePage={current_page}
                  itemsCountPerPage={per_page}
                  totalItemsCount={total}
                  onChange={(pageNumber) => getAllcanceledTickets(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledTickets;
