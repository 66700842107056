import React, { Component } from "react";
import Routes from "./Routes/Routes";
import { WithRouter } from "./utils/WithRouter";
import "react-whatsapp-widget/dist/index.css";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Routes />
      </React.Fragment>
    );
  }
}

export default WithRouter(App);
