import {
  faBus,
  faBusAlt,
  faLocationArrow,
  faSearchLocation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { axiosGet, axiosGetWeb, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { Link } from "react-router-dom";

const ReusableComponent = ({
  header,
  flag,
  step,
  handleStepChangeByOne,
  handleStepChangeFromCD,
  formData1,
  selectedRoute,
  selectedSeats,
  chosenDroppingPointId,
  chosenBoardingPointId,
  handleChangeManualDetails,
  handleBusBooking,
}) => {
  const [formData, setFormData] = useState(formData1);
  const [isFormData, setIsFormData] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [seatDetailErr, setSeatDetailErr] = useState(0);
  const [selectedSeat, setSelectedSeat] = useState(
    JSON.parse(localStorage.getItem("selectedSeat") || [])
  );

  const [legalDocs, setLegalDocs] = useState([]);
  // const handleChange= (e) => {
  //   const { name, value, type, checked } = e.target;

  //   setFormData((formData) => ({
  //     ...formData,
  //     [name]: type === "checkbox" ? checked : value,
  //   }));
  // };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  useEffect(() => {
    setFormData(formData1);
  }, [formData1]);

  const handleSubmit = (e) => {
    e.preventDefault();

    //validate the form data before submitting

    if (
      !formData ||
      formData.first_name === "" ||
      formData.last_name === "" ||
      formData.address === "" ||
      formData.phone_number === "" ||
      formData.email === "" ||
      formData.proccedToPayment === undefined ||
      !formData.proccedToPayment
    ) {
      swal({
        title: "Warning",
        text: "Please add a required field ",
        icon: "warning",
      });
      return;
    } else {
      let seat_config = [];
      JSON.parse(localStorage.getItem("selectedSeat"))?.map((item) => {
        return (seat_config = [
          ...seat_config,
          {
            bus_row_id: item.row_id,
            seat_booked_by: item.passengerName,
            is_child: item.isChildren ? true : false,
            bus_column_id: item.column_id,
            document_type: item.documentType,
            document_value: item.documentValue,
          },
        ]);
      });
      let bookingData = {
        daily_schedule_id: selectedRoute.id,
        pick_up_point: chosenBoardingPointId,
        dropping_point: chosenDroppingPointId,
        fare: totalFareCalculate(),
        bus_seat_configuration: seat_config,
        bus_booking_customer: {
          full_name: formData.first_name + " " + formData.last_name,
          address: formData.address,
          email: formData.email,
          phone_number: formData.phone_number,
        },
      };
      localStorage.setItem("bookingDetails", JSON.stringify(bookingData));
      // handleBusBooking();
      handleStepChangeFromCD();
    }

    // axiosPost(URL.busBooking, params, (response) => {
    //   if (response.status === 200) {
    //

    //     swal(
    //       "Success",
    //       "Your tickets has been booked. Please complete the payment.",
    //       "success"
    //     );
    //   }
    // });

    //Render or push to next step
  };

  const totalFareCalculate = () => {
    if (JSON.parse(localStorage.getItem("selectedSeat")) !== null) {
      return (
        JSON.parse(localStorage.getItem("selectedSeat")).length *
        selectedRoute.fare
      );
    } else {
      return 0;
    }
  };

  const chkSeatDetails = () => {
    let chkData = [...selectedSeat];
    let errChk = 0;

    if (selectedRoute.is_legal_document_required == 1) {
      chkData.forEach((data) => {
        if (
          data.passengerName === "" ||
          data.passengerName === undefined ||
          data.documentType === undefined ||
          data.documentValue === undefined ||
          data.documentType === "" ||
          data.documentValue === ""
        ) {
          errChk++;
          setSeatDetailErr(errChk);
        } else {
          setSeatDetailErr(errChk);
        }
      });
    } else if (selectedRoute.is_legal_document_required == 0) {
      chkData.forEach((data) => {
        if (data.passengerName === "" || data.passengerName === undefined) {
          errChk++;
          setSeatDetailErr(errChk);
        } else {
          setSeatDetailErr(errChk);
        }
      });
    }

    chkData.forEach((data) => {
      if (data.passengerName === "" || data.passengerName === undefined) {
        errChk++;
        setSeatDetailErr(errChk);
      } else {
        setSeatDetailErr(errChk);
      }
    });
    if (errChk > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handlePassengerDetailsCheck = () => {
    if (formData) {
      if (
        (formData.first_name !== "" ||
          formData.last_name !== "" ||
          formData.address !== "" ||
          formData.phone_number !== "" ||
          formData.email !== "") &&
        (formData.first_name !== undefined ||
          formData.last_name !== undefined ||
          formData.address !== undefined ||
          formData.phone_number !== undefined ||
          formData.email !== undefined)
      ) {
        if (chkSeatDetails()) {
          // if (
          //   formData.acceptTermsAndConditions === undefined ||
          //   !formData.acceptTermsAndConditions
          // ) {
          //   swal({
          //     title: "Warning",
          //     text: "Please accept the terms and conditions to proceed",
          //     icon: "warning",
          //   });
          //   return;
          // } else {
          //   handleStepChangeByOne(formData);
          // }
          handleStepChangeByOne(formData);
        } else {
          swal({
            title: "Error",
            text: "No Seat details found",
            icon: "error",
          });
          return;
        }
      } else {
        swal({
          title: "Error",
          text: "No Passenger details found",
          icon: "error",
        });
        return;
      }
    } else {
      swal({
        title: "Error",
        text: "Please fill the form to proceed",
        icon: "error",
      });
      return;
    }
  };

  const handleChangeSeatDetails = (e, idx) => {
    const { name, value, type, checked } = e.target;
    let seatDetails = [...selectedSeat];
    seatDetails[idx][name] = type === "checkbox" ? checked : value;
    setSelectedSeat(seatDetails);
    localStorage.setItem("selectedSeat", JSON.stringify(seatDetails));
  };

  const getDocumentTypes = () => {
    axiosGetWeb(URL.getLegalDocs, (response) => {
      if (response.status === 200) {
        setLegalDocs(response.data.data.data);
      }
    });
  };

  return (
    <>
      <div className="container">
        <div className="text-center">{header}</div>

        <div className="row mt-3 justify-content-center">
          <div className="col-lg-7 ">
            <div id="main_layer">
              <div>
                <h5>
                  <strong>Passenger Details</strong>
                </h5>
              </div>

              <div className="form-group booking-modal-form">
                {flag ? (
                  // <div>
                  //   <strong> Full Name : </strong>
                  //   {formData.first_name + " " + formData.last_name}
                  // </div>
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Full Name</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5">
                      {" "}
                      {formData.first_name + " " + formData.last_name}{" "}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                ) : (
                  // passengerName ? (
                  //   passengerName
                  // ) : (
                  //   "No Passenger at all"
                  // )

                  <input
                    type="text"
                    value={formData?.first_name}
                    className="form-control mb-2"
                    aria-describedby="emailHelp"
                    placeholder="First Name"
                    onChange={(e) => handleChangeManualDetails(e)}
                    name="first_name"
                  />
                )}
              </div>
              <div className="form-group booking-modal-form">
                {flag ? (
                  // <div>
                  //   <strong>Contact no. : </strong>
                  //   {formData1.phone_number}
                  // </div>
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Contact no.</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5"> {formData1.phone_number}</div>
                    <div className="col-md-2"></div>
                  </div>
                ) : (
                  <input
                    name="middle_name"
                    value={formData?.middle_name}
                    type="text"
                    className="form-control mb-2"
                    placeholder="Middle Name"
                    onChange={(e) => handleChangeManualDetails(e)}
                  />
                )}
              </div>

              <div className="form-group booking-modal-form">
                {flag ? (
                  <div>
                    {/* <strong>Email :</strong> {formData1.email} */}
                    <div className="row">
                      <div className="col-md-4">
                        <strong>Email</strong>
                      </div>
                      <div className="col-md-1"> </div>

                      <div className="col-md-5">{formData1.email}</div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                ) : (
                  <input
                    name="last_name"
                    value={formData?.last_name}
                    type="text"
                    className="form-control mb-2"
                    placeholder="Last Name"
                    onChange={(e) => handleChangeManualDetails(e)}
                  />
                )}
              </div>

              {/* <div className="form-group booking-modal-form">
                  {flag ? null : (
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="boardingPoint"
                      onChange={handleChangeManualDetails}
                      value={
                        !formData1
                          ? formData?.boardingPoint
                          : formData1.boardingPoint
                      }
                    >
                      <option selected>Boarding Point</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  )}
                </div> */}

              {flag ? (
                <div>
                  {/* <strong>Address :</strong> {formData.address} */}
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Address</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5">{formData.address} </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              ) : null}

              <hr />

              <div>
                <h5>
                  <strong>
                    {flag ? "Vehicle Details" : "Contact Details"}
                  </strong>
                </h5>
              </div>

              <div className="form-group booking-modal-form">
                {flag ? (
                  <div>
                    {/* <strong>Vehicle Name :</strong> {selectedRoute.bus_name} */}
                    <div className="row">
                      <div className="col-md-4">
                        <strong>Vehicle Name</strong>
                      </div>
                      <div className="col-md-1"> </div>

                      <div className="col-md-5">{selectedRoute.bus_name} </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                ) : (
                  <input
                    type="text"
                    name="address"
                    value={formData?.address}
                    className="form-control mb-2"
                    placeholder="Full Address"
                    onChange={(e) => handleChangeManualDetails(e)}
                  />
                )}
              </div>

              <div className="form-group booking-modal-form">
                {flag ? (
                  <div>
                    {/* <strong>Vehicle Type :</strong> {selectedRoute.type} */}
                    <div className="row">
                      <div className="col-md-4">
                        <strong>Vehicle Type</strong>
                      </div>
                      <div className="col-md-1"> </div>

                      <div className="col-md-5">{selectedRoute.type} </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                ) : (
                  <input
                    type="email"
                    name="email"
                    value={formData?.email}
                    className="form-control mb-2"
                    placeholder="Email Address"
                    onChange={(e) => handleChangeManualDetails(e)}
                  />
                )}
              </div>

              <div className="form-group booking-modal-form">
                {flag ? (
                  // <div>
                  //   <strong>Vehicle Number :</strong>{" "}
                  //   {selectedRoute.number_plate}
                  // </div>
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Vehicle Number</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5">{selectedRoute.number_plate}</div>
                    <div className="col-md-2"></div>
                  </div>
                ) : (
                  <input
                    type="text"
                    name="phone_number"
                    className="form-control mb-2"
                    placeholder="Mobile Number"
                    onChange={(e) => handleChangeManualDetails(e)}
                    value={formData?.phone_number}
                  />
                )}
              </div>
              {flag ? (
                <div>
                  <hr />
                  <div>
                    <h5>
                      <strong>Seat Details</strong>
                    </h5>
                  </div>
                  {/* <div>
                  
                    <strong>No. of Seats :</strong> {selectedSeats.length}
                  </div> */}

                  <div className="row">
                    <div className="col-md-4">
                      <strong>No. of Seats</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5">
                      {JSON.parse(localStorage.getItem("selectedSeat")).length}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Seats</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5">
                      {" "}
                      {JSON.parse(localStorage.getItem("selectedSeat")).map(
                        (seat) => {
                          return (
                            <div className="mb-1">
                              {seat.rowName +
                                "-" +
                                seat.columnName +
                                "(" +
                                seat.passengerName +
                                ")"}
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Adults</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5">
                      {" "}
                      {
                        JSON.parse(localStorage.getItem("selectedSeat")).filter(
                          (seat) => seat.isChildren !== true
                        ).length
                      }
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Childrens</strong>
                    </div>
                    <div className="col-md-1"> </div>

                    <div className="col-md-5">
                      {" "}
                      {
                        JSON.parse(localStorage.getItem("selectedSeat")).filter(
                          (seat) => seat.isChildren === true
                        ).length
                      }
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-lg-5 justify-content-end  ">
            <div className="pt-2">
              <strong>Travel Detail</strong>
            </div>
            <hr />
            <div
              className="card"
              style={{ width: "18rem", border: " 2px solid whitesmoke" }}
            >
              <div className="card-body">
                <h5 className="card-title pb-2">
                  {moment(selectedRoute.departure_time).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  Taj Mahal Travels
                </h6>
                <p className="card-text pt-2">
                  <div>
                    <div>
                      <FontAwesomeIcon icon={faBusAlt} />
                      <span
                        className="m-3"
                        style={{ fontSize: 18, fontWeight: 600 }}
                      >
                        {selectedRoute.origin}
                      </span>
                    </div>
                    <div
                      // className="border-start border-dark border-2 border-stripped"
                      style={{
                        borderStyle: "dotted",
                        borderRight: 0,
                        borderTop: 0,
                        borderBottom: 0,
                        padding: 25,
                        margin: 5,
                      }}
                    ></div>

                    <div>
                      <FontAwesomeIcon icon={faBusAlt} />

                      <span
                        className="m-3"
                        style={{ fontSize: 18, fontWeight: 600 }}
                      >
                        {selectedRoute.destination}
                      </span>
                    </div>
                  </div>
                </p>
              </div>
            </div>
            {flag ? (
              <>
                {/* <form onSubmit={handleSubmit}> */}
                <div class="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="proccedToPayment"
                    onChange={(e) => handleChangeManualDetails(e)}
                    checked={formData?.proccedToPayment}
                  />
                  <label class="form-check-label" htmlFor="exampleCheck1">
                    I have read and hearby accept the{" "}
                    <span style={{ color: "blue" }}>
                      <Link to="/terms-and-conditions" target="_blank">
                        terms and conditions
                      </Link>
                    </span>{" "}
                    of Taj Mahal Travels & declare that the information provided
                    is true and correct.
                  </label>
                </div>
                <button
                  style={{ backgroundColor: "#6C63FF", border: "none" }}
                  type="submit"
                  className="btn btn-md btn-primary p-2"
                  onClick={(e) => {
                    if (
                      formData &&
                      formData.proccedToPayment !== undefined &&
                      formData.proccedToPayment === true
                    ) {
                      handleSubmit(e);
                    } else {
                      swal(
                        "Error",
                        "Please accept the confirmation conditions to proceed.",
                        "error"
                      );
                      return;
                    }
                  }}
                >
                  Proceed to Payment
                </button>
                {/* </form> */}
              </>
            ) : null}
          </div>
        </div>

        {flag ? (
          ""
        ) : (
          <>
            <hr />
            <div>
              <h5>
                <strong>Seat Details</strong>
              </h5>
            </div>
            <div
            //  className="d-flex align-items-center justify-content-around"
            >
              <table className="table table-sm table-borderless text-center">
                <thead>
                  <tr>
                    <th>Booked Seat</th>
                    <th>Name</th>
                    <th>Is children ?</th>
                    {selectedRoute.is_legal_document_required ? (
                      <>
                        {" "}
                        <th>Document Type</th>
                        <th>Document No.</th>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {selectedSeat.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.columnName}</td>
                      {/* <td>{item.rowName + "-" + item.columnName}</td> */}
                      <td>
                        {" "}
                        <input
                          className="form-control mb-2"
                          type="text"
                          name="passengerName"
                          value={item.passengerName}
                          onChange={(e) => handleChangeSeatDetails(e, idx)}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="isChildren"
                          checked={item.isChildren}
                          onChange={(e) => handleChangeSeatDetails(e, idx)}
                          // checked={formData?.acceptTermsAndConditions}
                        />
                      </td>
                      {selectedRoute.is_legal_document_required ? (
                        <>
                          <td>
                            <select
                              name="documentType"
                              className="form-select"
                              value={item.documentType}
                              onChange={(e) => handleChangeSeatDetails(e, idx)}
                            >
                              <option value="" selected disabled>
                                Choose document type
                              </option>
                              {legalDocs.map((ld) => (
                                <option value={ld.document_type}>
                                  {ld.document_type}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              className="form-control mb-2"
                              type="text"
                              name="documentValue"
                              value={item.documentValue}
                              onChange={(e) => handleChangeSeatDetails(e, idx)}
                            />
                          </td>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {flag ? (
          <>
            <hr />
            <h3 className="lead">
              <strong> Total Amount :</strong> {totalFareCalculate()}
            </h3>
          </>
        ) : null}

        {flag ? null : (
          <div className="mb-2">
            <small>*Ticket shall be forwarded to provided email address </small>
          </div>
        )}

        {flag ? null : (
          <>
            {/* <div class="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="acceptTermsAndConditions"
                onChange={(e) => handleChangeManualDetails(e)}
                checked={formData?.acceptTermsAndConditions}
              />
              <label class="form-check-label" for="exampleCheck1">
                I hereby accept and all other stuff goes here..
              </label>
            </div> */}
            <button
              style={{ backgroundColor: "#6C63FF", border: "none" }}
              // type="submit"
              className="btn btn-md btn-primary p-2"
              onClick={() => handlePassengerDetailsCheck()}
              // onClick={() => {
              //   if (!formData) {
              //     handleStepChangeByOne(formData);
              //   } else {
              //     handlePassengerDetailsCheck();
              //   }
              // }}
            >
              Continue
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ReusableComponent;
