import React, { Component } from "react";
import { Button } from "reactstrap";
import "../../../../assets/scss/searchfilter.scss";
import DatePicker from "react-datepicker";

class BusRouteFilter extends Component {
  render() {
    return (
      <>
        <div className="filter-wrapper">
          <div className="filter-container">
            <div className="search-input-div">
              <div className="row">
                <div className="col-md-4">
                  <div className="input-boxes">
                    <select
                      name="origin"
                      className="form-select"
                      //   value={this.props.origin}
                      //   onChange={this.props.handleChange}
                    >
                      <option value="" disabled selected>
                        Choose Route
                      </option>
                      {/* {this.props.cities && this.props.cities.length > 0
                        ? this.props.cities.map((city, idx) => {
                            return (
                              <option value={city.id} key={idx}>
                                {city.name}
                              </option>
                            );
                          })
                        : null} */}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-boxes">
                    <select
                      name="destination"
                      className="form-select"
                      //   value={this.props.destination}
                      //   onChange={this.props.handleChange}
                    >
                      <option value="" disabled selected>
                        Choose Route Points
                      </option>
                      {/* {this.props.cities && this.props.cities.length > 0
                        ? this.props.cities.map((city, idx) => {
                            return (
                              <option value={city.id} key={idx}>
                                {city.name}
                              </option>
                            );
                          })
                        : null} */}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-boxes">
                    <select
                      name="order"
                      className="form-select"
                      //   onChange={this.props.handleChange}
                      //   value={this.props.order}
                    >
                      <option value="" disabled selected>
                        Choose Price Range
                      </option>
                      <option value="low">Low To High</option>
                      <option value="high">High To Low</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-btn-div">
              <Button
                className="busTicketForm-btn m-2"
                onClick={this.props.resetState}
              >
                Reset
              </Button>
              <Button
                className="busTicketForm-btn"
                onClick={this.props.handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BusRouteFilter;
