import React, { Component } from "react";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet } from "../../../../utils/AxiosApi";
import { moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { checkRolePermissions } from "../../../../utils/Helper";
import CreateTermsAndConditions from "./CreateTermsAndConditions";
import parse from "html-react-parser";
import { Spinner } from "reactstrap";
import swal from "sweetalert";

class CmsTandC extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    edit: false,
    tandCs: null,
    selectedData: null,
    spinner: false,
    updatePermission: false,
  };

  closeUpdateModal = () => {
    this.setState({
      selectedData: null,
      edit: false,
      createModal: !this.state.createModal,
    });
  };

  componentDidMount() {
    this.permissions();
  }

  deleteData = (params) => {
    axiosGet(
      URL.deleteTermsAndConditions + "/" + params.id,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getTermsAndConditions();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getTermsAndConditions = () => {
    this.setState({ spinner: true, tandCs: null }, function () {
      axiosGet(
        URL.getTermsAndCondition,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            if (response.data.data) {
              this.setState({ tandCs: response.data.data.item });
            }
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      edit: false,
    });
  };

  handleDelete = (id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.deleteData);
  };

  handleEdit = () => {
    this.setState({
      selectedData: JSON.parse(JSON.stringify(this.state.tandCs)),
      edit: true,
      createModal: !this.state.createModal,
    });
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions(
          "delete",
          moduleName.termsAndConditions
        ),
        updatePermission: checkRolePermissions(
          "update",
          moduleName.termsAndConditions
        ),
      },
      function () {
        this.getTermsAndConditions();
      }
    );
  };

  render() {
    return (
      <>
        <div className="dashboardHeader">
          <strong>TERMS AND CONDITIONS</strong>
        </div>
        <div className="container">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.termsAndConditions) &&
              this.state.tandCs === null ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Terms and Conditions
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              {this.state.tandCs ? (
                <>
                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <h3>
                        <strong>Act: </strong>
                        {this.state.tandCs.act}
                      </h3>
                    </div>
                    <div className="col-md-6 text-end">
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => this.handleEdit()}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => this.handleDelete(this.state.tandCs.id)}
                      >
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <div className="tandCBox">
                    {parse(`${this.state.tandCs.term_and_conditions}`)}
                  </div>
                </>
              ) : (
                <div className="col text-center p-3">
                  {this.state.spinner ? (
                    <>
                      <Spinner></Spinner>
                      <br />
                      Loading Data...
                    </>
                  ) : (
                    "No Terms and Conditions Added"
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <CreateTermsAndConditions
              edit={this.state.edit}
              getTermsAndConditions={this.getTermsAndConditions}
              handleCreateModal={this.handleCreateModal}
              selectedData={this.state.selectedData}
            ></CreateTermsAndConditions>
          }
          modalHeader="Add Terms and Conditions"
          size="xl"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default CmsTandC;
