import React, { Component } from "react";
import { checkRolePermissions } from "../../../utils/Helper";
import { moduleName, URL } from "../../../utils/Constants";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import AddDocumentTypes from "./AddDocumentTypes";
import { axiosGet } from "../../../utils/AxiosApi";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import Pagination from "react-js-pagination";

class Documents extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    documentTypes: [],
    edit: false,
    selectedData: {},
    spinner: false,
    updatePermission: false,
    fullSpinner: false,
  };

  componentDidMount() {
    this.permissions();
  }

  deleteData = (params) => {
    this.setState({ spinner: true }, () => {
      axiosGet(
        URL.deleteDocumentTypes + "/" + params.id,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
            this.setState({ spinner: false });
            this.getDocumentTypes();
          }
        },
        (err) => {
          this.setState({ spinner: false });

          displayErrorAlert(err);
        }
      );
    });
  };

  getDocumentTypes = (pageNumber = 1) => {
    this.setState({ fullSpinner: true }, function () {
      axiosGet(
        URL.documentTypes + "?" + "page=" + pageNumber,
        (response) => {
          this.setState({ fullSpinner: false });
          if (response.status === 200) {
            this.setState({ documentTypes: response.data.data });
          }
        },
        (err) => {
          this.setState({ fullSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      edit: false,
    });
  };

  handleDelete = (id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.deleteData);
  };

  handleEdit = (data) => {
    this.setState({
      selectedData: data,
      edit: true,
      createModal: !this.state.createModal,
    });
  };

  permissions = () => {
    this.setState(
      {
        updatePermission: checkRolePermissions("update", moduleName.documents),
        deletePermission: checkRolePermissions("delete", moduleName.documents),
      },
      function () {
        this.getDocumentTypes();
      }
    );
  };

  render() {
    const { current_page, per_page, total } = this.state.documentTypes;

    return (
      <>
        {this.state.spinner ? <CustomFullSpinner text="Processing...." /> : ""}

        <div className="dashboardHeader">
          <strong>DOCUMENTS</strong>
        </div>
        <div className="container">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.documents) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Document Types
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center">S.N.</th>
                      <th>Document Type</th>
                      {this.state.deletePermission ||
                      this.state.updatePermission ? (
                        <th>Actions</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.documentTypes?.data?.length > 0 ? (
                      this.state.documentTypes?.data?.map((type, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">{idx + 1}</td>
                            <td>{type.document_type}</td>
                            {this.state.deletePermission ||
                            this.state.updatePermission ? (
                              <td>
                                {this.state.updatePermission ? (
                                  <button
                                    className="btn btn-primary me-2"
                                    onClick={() => this.handleEdit(type)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}
                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => this.handleDelete(type.id)}
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="text-center p-3"
                          colSpan={
                            this.state.updatePermission ||
                            this.state.deletePermission
                              ? 3
                              : 2
                          }
                        >
                          {this.state.spinner ? (
                            <>
                              <Spinner></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Document Types added yet"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.documentTypes.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) => this.getDocumentTypes(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <AddDocumentTypes
              edit={this.state.edit}
              getDocumentTypes={this.getDocumentTypes}
              handleCreateModal={this.handleCreateModal}
              selectedData={this.state.selectedData}
            />
          }
          modalHeader={
            this.state.edit ? "Update Document Type" : "Add Document Types"
          }
          toggleModal={this.handleCreateModal}
        ></CustomModal>
      </>
    );
  }
}

export default Documents;
